.user-header {
	padding: 2rem;
    height: 100%;
	@extend .bg-white;
}

.list-user-items {
	.item {
		padding: 20px;
		border-bottom: 1px solid $table-border-color;
		@extend .clearfix;
		.skill-item, .category-item{
			display: inline-block;
			background: #EBFBF8;
			color: $gray-dark;
			margin-right: 5px;
			padding: 2px 5px;
			margin-bottom: 10px;
			a{
				color: $gray-dark;
			}
		}
	}
}

.contractor-avatar {
    object-fit: cover;
    object-position: top;
}

.button-custom {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	border: 1px solid $table-border-color;
	@include size(30px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
	&:hover {
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		border: 1px solid $table-border-color;
		@include size(30px);
	}

    &.add-custom {
        i {
            &:before {
                @extend .text-warning;
            }
        }
    }
	&.edit-custom {
		i {
			&:hover {
				@extend .text-warning;
			}
		}
	}
	&.delete-custom {
		i {

			&:hover {
				@extend .text-danger;
			}
		}
	}
}
.languages{
	.language-item{
		position: relative;
		padding: 0 0 20px;
		margin: 0 0 20px;
		border-bottom: 1px solid $table-border-color;
		@extend .clearfix;
		&:last-child {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}
		.item{
			width: 115px;
			display: inline-block;
		}
	}
}
.educations, .experiences {
	.education-item, .experience-item {
		position: relative;
		padding: 0 0 30px;
		margin: 0 0 33px;
		border-bottom: 1px solid $table-border-color;
		@extend .clearfix;
		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
		h6 {
			margin-bottom: 12px;
			font-size: 1.125rem;
			line-height: 26px;
			@extend .black;
			//@extend .mb-3;
		}
		p {
			line-height: 26px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.actions {
			//position: absolute;
			//right: 0;
			//top: 20px;
		}

		.education-label {
			font-weight: normal;
			margin-bottom: 20px;
			color: rgba(52, 51, 51, 0.8) !important;
		}
	}
}

.orders {
	.orders-item {
		padding: 0 0 30px;
		margin: 0 0 23px;
		border-bottom: 1px solid $table-border-color;
		@extend .clearfix;
		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}
		h6 {
			margin-top: 10px;
			font-size: 1.125rem;
			line-height: 26px;
			@extend .black;
			@extend .mb-3;
		}
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
		.user-rate {
			float: right;
			@include media-breakpoint-down(md) {
				float: left;
				margin-bottom: 10px;
			}
		}
	}
}
