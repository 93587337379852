.massages {
	height: calc(100vh - 160px);
	overflow: auto;
	overflow-x: hidden;
	.row {
		height: 100%;
	}

	.inbox {
		border-bottom: 1px solid $table-border-color;
		border-right: 1px solid $table-border-color;
		@include transition($transition-base);
	}

	.conversations {
		border-right: 1px solid $table-border-color;
		height: calc(100% - 55px);
		overflow: auto;
		@include transition($transition-base);

	}

	.aktive {
		background-color: $body-bg;
		border-left: 3px solid $brand-primary;
		@include transition($transition-base);
	}

	.conversation-items{}
}
