//label{
//	font-size: 80%;
//}
.custom-control-input:disabled ~ .custom-control-indicator {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

select.form-control {
	&:not([size]):not([multiple]) {
		height: inherit;
		line-height: 25px;
	}
}

.input-group-custom {
	position: relative;
	.input-group-addon {
		border: none;
		position: absolute;
		z-index: 6;
		background: transparent;
	}
	.input-query {
		padding-left: 75px;

	}
}

.form-control, .custom-select {
	&:disabled,
	&[readonly] {
		color: $white;
	}
}

.custom-select {

}
