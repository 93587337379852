.__messages__conversation-item__89117 {
	border-bottom: 1px solid $table-border-color;
	padding: 1rem 0rem;
	cursor: pointer;
	&.unread {
		background-color: rgba(87, 87, 87, 0.04);
	}
	&:last-child {
		border-bottom: none;
	}
	&:hover {
		background-color: $body-bg;
		border-left: 3px solid $brand-primary;
		@include transition($transition-base);
	}
}