.btn-register{
    &:hover{
        color: $white;
    }
    &:active{
        color: $white;
    }
}

.fit-content{
	width: fit-content;
}

.btn-decline {
	background: none;
	color: $gray;
	border: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	&:hover {
		cursor: pointer;
	}

}

.btn-gray {
	background: #ebf0f4;
	color: $gray;
	border: none;
	font-weight: 500;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	&:hover {
		color: $gray-dark;
		cursor: pointer;
		background: #e0e7ee;
	}
}

.btn-gray-dark {
	background: #e0e7ee;
	color: $gray-dark;
	border: none;
	font-weight: 500;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	&:hover {
		color: $gray;
		cursor: pointer;
		background: #ebf0f4;
	}

	&.border-bg-danger {
		border: 1px solid #f8c1b7;
	}

	.mdi {
		color: $gray-dark;

		&:hover {
			color: $gray;
		}
	}
}

.btn {
	//-ms-text-size-adjust: 100%; /* 2 */
	//-webkit-text-size-adjust: 100%; /* 2 */
	//-webkit-font-smoothing: antialiased;
	//-moz-osx-font-smoothing: grayscale;
	&:hover {
		cursor: pointer;
	}
	i {
		color: $white;
	}
}

.btn-link {
	i {
		color: $app-icon-color;
	}
	&:hover {
		cursor: pointer;
	}
}

.btn-link, .btn {
	outline: none;
	@include hover-focus {
		outline: none;
	}
}

.btn-readall {
	position: relative;
	width: 160px;
	&:hover {
		i {
			right: 7px;
			@include transition($transition-base);
		}
	}
	i {
		right: 13px;
		position: absolute;
		@include transition($transition-base);
	}
}

.reset-mdi-lineh::before {
	line-height: 18px;
}

.buttons-container{

	@include media-breakpoint-up(md) {
		flex-direction: row;
	}
	@include media-breakpoint-down(md) {

		flex-direction: column;
	}
}
$shadow-action-length : 3px 3px 10px 0px;
$shadow-action-length2 : -3px -3px 10px 0px;

.button-container {
	flex: 1;
	background-color: transparent;
	margin: 0 15px 2rem;
    box-sizing: border-box;
    border: 1px solid transparent;
	@include transition($transition-base);

	@include media-breakpoint-up(lg) {
        max-width: 50%;
        background: transparent;
        &.full{
            max-width: 100%;
            background: transparent;
            flex: 1;
            height: 100%;
            margin: 0;
        }
    }
	@include media-breakpoint-down(md) {
		margin-left: 0;
		margin-right: 0;
	}

	&:hover {
        &.btn-hover-secondary{
            box-sizing: border-box;
            -webkit-box-shadow: $shadow-action-length rgba($orange ,0.34), $shadow-action-length2 rgba($orange ,0.34);
            box-shadow: $shadow-action-length rgba($orange ,0.34), $shadow-action-length2 rgba($orange ,0.34);
        }
        &.btn-hover-primary{
            box-sizing: border-box;
            //border: 1px solid $brand-primary;

            -webkit-box-shadow: $shadow-action-length rgba($brand-primary ,0.34), $shadow-action-length2 rgba($brand-primary ,0.34);
            box-shadow: $shadow-action-length rgba($brand-primary ,0.34), $shadow-action-length2 rgba($brand-primary ,0.34);
        }
		//cursor: pointer;
		.button-wrapper {
			@include transition($transition-base);

			.button-icon {
				@include transition($transition-base);
				margin-left: -5px;
			}
            &:hover{
                &.btn-hover-secondary{
                    box-sizing: border-box;
                    -webkit-box-shadow: $shadow-action-length rgba($orange ,0.34), $shadow-action-length2 rgba($orange ,0.34);
                    box-shadow: $shadow-action-length rgba($orange ,0.34), $shadow-action-length2 rgba($orange ,0.34);
                }
                &.btn-hover-primary{
                    box-sizing: border-box;
                    -webkit-box-shadow: $shadow-action-length rgba($brand-primary ,0.34), $shadow-action-length2 rgba($brand-primary ,0.34);
                    box-shadow: $shadow-action-length rgba($brand-primary ,0.34), $shadow-action-length2 rgba($brand-primary ,0.34);
                }
            }
		}
	}
    &.full{
        height: 100%;
        flex: 1;
        .button-wrapper{
            margin-bottom: 10px;

            height: 100%;
            flex: 1;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
	.button-wrapper {
		padding: 21px 26px;
        background: $white;
        height: 100%;
		.button-actions-link {
			font-size: 20px;
			border: 0;
			background-color: transparent;
			margin: 0 0 5px;
			padding: 0;
			font-weight: 500;
			display: block;
			&:hover{
				cursor: pointer;
			}
		}
		.button-actions-text {
			margin: 0 0 20px;
		}
        .button-image{
            width: 70%;
            height: auto;
            float: right;
            svg{
                display: block;
            }
        }
		.button-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -17px;
			margin-left: -17px;

			&:hover{
				cursor: pointer;
			}
			@include transition($transition-base);
			i {
				@include transition($transition-base);
				color: $white;
				width: 36px;
				height: 36px;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
			}

		}
	}
}
