.__bs__search-form__f6467 {
	position: relative;
	.btn {
		height: 100%;
		&:hover {
			cursor: pointer;
		}
	}
	@include media-breakpoint-down(sm) {
		max-width: auto;
	}
	@include media-breakpoint-up(sm) {
		max-width: 185px;
	}
	.form-inline .form-control{
		width: 100px;
	}
}