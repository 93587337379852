.__contractor__sidebar__nav-settings__2ece1 {
	@include media-breakpoint-up(md) {
		border-right: 1px solid $table-border-color;
	}
	li {
		display: block;
		position: relative;
		@extend .d-flex;
		@extend .align-items-center;
		a {
			color: $gray-dark;
			display: block;
			padding: 1rem 60px;
			width: 100%;
			&:hover {
				color: $brand-primary;
			}
			&:hover {
				a {
					color: $brand-primary;
				}
			}
			&.active{
				&:before {
					content: ' ';
					width: 3px;
					background: $brand-primary;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
				}
				a {
					color: $brand-primary;
				}
			}

		}

	}
}