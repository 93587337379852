@include text-emphasis-variant('.text-gray-light', $gray);
@include text-emphasis-variant('.text-gray-lighter', $gray-lighter);
@include text-emphasis-variant('.text-gray-dark', $gray-dark);
@include text-emphasis-variant('.text-nav', $sidenav-icon-color);
@include text-emphasis-variant('.black', $black);
@include text-emphasis-variant('.text-gray', $body-color);
@include bg-variant('.bg-white', $white);
@include bg-variant('.bg-yellow', $yellow-light);
@include bg-variant('.no-bg-white', transparent);
@include bg-variant('.bg-light-green', $green-light);
@include bg-variant('.bg-white-clean', $white);
@include text-emphasis-variant('.text-muted', $mute);

.rounded-status {
	@include size(10px);
	display: inline-block;
}

.bg-white {
	-webkit-box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.05);
}

.no-bg-white {
	.bg-white {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
}

.bg-light-green {
	p {
		@extend .text-gray-dark;
		&:last-child{
			margin-bottom: 0;
		}
	}
	a {
		color: #0d8466;
		text-decoration: none;
		&:hover {
			color: #14CA9C;
			text-decoration: none;
		}
	}
}
