.__cp__city-by-zipcode__45d0e{
    .ember-power-select-trigger {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        width: 100%;
    }

    .text-form-city { margin-top: 6px;margin-bottom: 0}


}
