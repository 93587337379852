//Full Calendar - events bg colors

$fc-light-bg-red: #F8D9D9 !default;
$fc-light-color-red: #F86E5E !default;

$fc-light-bg-green: #9DF7E1 !default;
$fc-light-color-green: #00CA9E !default;

$fc-light-bg-yellow: #FCE0B5 !default;
$fc-light-color-yellow: #F6B046 !default;

$fc-light-bg-gray: #DADCDD !default;
$fc-light-color-gray: #AAA8A8 !default;

@include text-emphasis-variant('.fc-text-gray', $fc-light-color-gray);
@include bg-variant('.fc-bg-gray', $fc-light-bg-gray);
@include bg-variant('.fc-bg-dark-gray', $fc-light-color-gray);

@include text-emphasis-variant('.fc-text-red', $fc-light-color-red);
@include bg-variant('.fc-bg-red', $fc-light-bg-red);
@include bg-variant('.fc-bg-dark-red', $fc-light-color-red);

@include text-emphasis-variant('.fc-text-green', $fc-light-color-green);
@include bg-variant('.fc-bg-green', $fc-light-bg-green);
@include bg-variant('.fc-bg-dark-green', $fc-light-color-green);

@include text-emphasis-variant('.fc-text-yellow', $fc-light-color-yellow);
@include bg-variant('.fc-bg-yellow', $fc-light-bg-yellow);
@include bg-variant('.fc-bg-dark-yellow', $fc-light-color-yellow);

.calendar-custom-button {
	height: 102px;
	width: 100%;
	border-top: 1px solid $fc-background-border !important;
	border-bottom: 1px solid $fc-background-border !important;
	margin-top: 58px;
	@include media-breakpoint-down(sm) { margin-bottom: 30px; }
}

.table-legend{
	margin-top: 1rem;
	.legend-item{
		margin-left: 2rem;
		&:first-child{
			margin-left: 0;
		}
	}
	.pointer{
		@include size(10px);
		border-radius: 50%;
		margin-right: 1rem;
	}
}

.fc {
	font-family: $font-family-sans-serif;
	font-weight: 400;
	.fc-content {
		background-color: $fc-background-content;
		color: $fc-background-text;
		border: none;
		padding: 0.5rem;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		&.fc-bg-gray {
			@extend .fc-text-gray;
		}
		&.fc-bg-red {
			@extend .fc-text-red;
		}
		&.fc-bg-yellow {
			@extend .fc-text-yellow;
		}
		&.fc-bg-green {
			@extend .fc-text-green;
		}
	}
	.fc-event {
		background-color: $fc-background-content;
	}

	.fc-event-container {
		border: none;
		padding: 0 0.5rem;
		&[colspan]:not([colspan="1"]) {
			.fc-content {
				background-color: $fc-background-content-long;
				color: $fc-background-text-long;
			}
		}
		.fc-day-grid-event {
			margin: 0.5rem 0.5rem;
			padding: 0rem;
			border: none;
			&:hover {
				cursor: pointer;
			}
		}
	}
	.fc-widget-header {
		vertical-align: middle;
		height: 100px;

		span {
			display: block;
			font-family: $font-family-sans-serif;
			font-weight: 400;
			text-transform: uppercase;
			vertical-align: middle;
			color: $gray-dark;
		}
	}
}

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
	border-color: $fc-background-border;
}

.fc-unthemed td.fc-today {
	background: transparent;
	border: none;
}
