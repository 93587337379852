@mixin transition($transition...) {
	-moz-transition:    $transition;
	-o-transition:      $transition;
	-webkit-transition: $transition;
	transition:         $transition;
}

@mixin app-box-shadow($top, $left, $blur, $color, $inset:"") {
	@if $inset != "" {
		-webkit-box-shadow:$top $left $blur $color $inset;
		-moz-box-shadow:$top $left $blur $color $inset;
		box-shadow:$top $left $blur $color $inset;
	}
	@if $inset == "" {
		-webkit-box-shadow:$top $left $blur $color;
		-moz-box-shadow:$top $left $blur $color;
		box-shadow:$top $left $blur $color;
	}
}