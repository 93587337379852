.app-user-profile {
	.view-order-contractee{
		width: 340px;
		position: absolute;
		left: 50%;
		top: 50%;

		transform: translate(-50%, -50%);
	}
	//
	.profile-logo {
	    width: 100px;
	    height:100px;
		margin: auto auto;
	}
	//
	.user-name {
	    color: #fff;
	    text-shadow: 1px 1px #333;
		font-size: 22px;
		text-align: center;
	}
	.g-map {
		opacity: 0.65;
	}

	.user-settings-icon {
		color:#F2B134;
	}
}

.user-reply {
	border: 1px solid $table-border-color;
	height: 100px;
	padding: 10px;
}
.user-reply-bottom{
	border-bottom: 1px solid $table-border-color;
	//height: 100px;
	//padding: 10px ;
}

.user-selection-modal {



	.body {
		background-color: #F6F8FA;
	}

	.modal-header, .rating-stars {
		border-bottom: 1px solid $table-border-color;
	}
}