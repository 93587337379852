.__admin__order__list-items__9a328{
  margin-bottom: 1rem;
  border-bottom: 1px solid #EBECEE;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
  &:after {
    @extend .clearfix;
  }
}