@import "components/admin/agreement-item/style.scss";
@import "components/admin/approval-item/style.scss";
@import "components/admin/badge-item/style.scss";
@import "components/admin/category-item/style.scss";
@import "components/admin/contractor-item/style.scss";
@import "components/admin/course-item/style.scss";
@import "components/admin/faq-item/style.scss";
@import "components/admin/order-desc-template-item/style.scss";
@import "components/admin/role-item/style.scss";
@import "components/admin/skill-item/style.scss";
@import "components/admin/tag-item/style.scss";
@import "components/admin/title-item/style.scss";
@import "components/app/card-item/style.scss";
@import "components/app/file-placeholder/style.scss";
@import "components/app/video-player/style.scss";
@import "components/assignment/select-worker-item/style.scss";
@import "components/bs/accordion-card-header/style.scss";
@import "components/bs/accordion-item/style.scss";
@import "components/bs/bs-badge/style.scss";
@import "components/bs/bs-progress/style.scss";
@import "components/bs/city-by-zipcode/style.scss";
@import "components/bs/container-wrapper/style.scss";
@import "components/bs/main-container/style.scss";
@import "components/bs/search-form/style.scss";
@import "components/bs/text-lenght-counter/style.scss";
@import "components/bs/tooltip-button/style.scss";
@import "components/cal/month-view/style.scss";
@import "components/contractor/user-confirmation/style.scss";
@import "components/conversations/conversations-item/style.scss";
@import "components/cp/city-by-zipcode/style.scss";
@import "components/custom/edit-form/style.scss";
@import "components/custom/user-statistic/style.scss";
@import "components/header/conversation-item/style.scss";
@import "components/map/contractee-list/style.scss";
@import "components/map/contractor-list/style.scss";
@import "components/messages/conversation-item/style.scss";
@import "components/messages/message-item/style.scss";
@import "components/notifications/notification-item/style.scss";
@import "components/order-list/container-item/style.scss";
@import "components/order-list/contractee-item/style.scss";
@import "components/order-list/contractor-order-item/style.scss";
@import "components/order-list/contractor-order-request/style.scss";
@import "components/order-list/order-accept-action/style.scss";
@import "components/order-list/order-shift-hours/style.scss";
@import "components/order-list/user-assigned/style.scss";
@import "components/admin/assignment/all-requests/style.scss";
@import "components/admin/assignment/assignment-details/style.scss";
@import "components/admin/assignment/display-shifts/style.scss";
@import "components/admin/assignment/shift-item/style.scss";
@import "components/admin/contractee/list-item/style.scss";
@import "components/admin/contractor/display-map/style.scss";
@import "components/admin/contractor/edit-sidenav/style.scss";
@import "components/admin/contractor/list-item/style.scss";
@import "components/admin/contractor/skills-display-item/style.scss";
@import "components/admin/order/list-items/style.scss";
@import "components/admin/order/user-assigned/style.scss";
@import "components/admin/vacancy/display-form-new/style.scss";
@import "components/contractee/assignment/assignment-details/style.scss";
@import "components/contractee/assignment/contractor-info/style.scss";
@import "components/contractee/assignment/shift-item/style.scss";
@import "components/contractee/card/display-main/style.scss";
@import "components/contractee/card/main-vacancy-select/style.scss";
@import "components/contractee/dashboard/order-list/style.scss";
@import "components/contractee/profile/body-content/style.scss";
@import "components/contractee/profile/content-sidebar-small/style.scss";
@import "components/contractee/settings/contractee-avatar/style.scss";
@import "components/contractee/settings/main-category/style.scss";
@import "components/contractee/settings/main-company-details/style.scss";
@import "components/contractee/sidebar/nav-settings/style.scss";
@import "components/contractee/vacancy/conversation-item/style.scss";
@import "components/contractee/vacancy/display-form-edit/style.scss";
@import "components/contractee/vacancy/display-form-new/style.scss";
@import "components/contractor/assignment/assignment-details/style.scss";
@import "components/contractor/assignment/shift-item/style.scss";
@import "components/contractor/card/card-header/style.scss";
@import "components/contractor/card/display-skills-inline/style.scss";
@import "components/contractor/card/main-default/style.scss";
@import "components/contractor/card/main-vacancy-select/style.scss";
@import "components/contractor/card/main-vacancy-selected/style.scss";
@import "components/contractor/card/main-vacancy/style.scss";
@import "components/contractor/dasboard/header-content/style.scss";
@import "components/contractor/order-list/order-shift-hours/style.scss";
@import "components/contractor/profile/content-main-default/style.scss";
@import "components/contractor/profile/content-main-public/style.scss";
@import "components/contractor/profile/content-sidebar-default/style.scss";
@import "components/contractor/profile/content-sidebar-public/style.scss";
@import "components/contractor/profile/content-sidebar-small/style.scss";
@import "components/contractor/profile/display-car-driving-license-info/style.scss";
@import "components/contractor/profile/display-courses/style.scss";
@import "components/contractor/profile/display-describe-your-self/style.scss";
@import "components/contractor/profile/display-map/style.scss";
@import "components/contractor/profile/display-not-completed-notification/style.scss";
@import "components/contractor/profile/display-shift-types/style.scss";
@import "components/contractor/profile/display-skills/style.scss";
@import "components/contractor/profile/display-video/style.scss";
@import "components/contractor/profile/header-content/style.scss";
@import "components/contractor/request/list-item/style.scss";
@import "components/contractor/request/user-avatar/style.scss";
@import "components/contractor/settings/video-player/style.scss";
@import "components/contractor/sidebar/nav-settings/style.scss";
@import "components/contractor/upload/profile-media-change/style.scss";
@import "components/contractor/upload/profile-media/style.scss";
@import "components/contractor/upload/user-avatar/style.scss";
@import "components/contractor/upload/user-video/style.scss";
@import "components/admin/assignments/new/order-shift-turn-items/style.scss";
@import "components/admin/order/contractor/select-contractor/style.scss";
@import "components/admin/order/edit/order-shift/style.scss";
@import "components/admin/vacancy/plan/list-item/style.scss";
@import "components/admin/vacancy/view/display-vacancy-toolbar/style.scss";
@import "components/contractee/assignment/new/order-shift-items/style.scss";
@import "components/contractee/settings/nda/display-items/style.scss";
@import "components/contractee/settings/order-desc/display-form-new/style.scss";
@import "components/contractee/settings/order-desc/display-item/style.scss";
@import "components/contractee/vacancy/view/display-vacancy-toolbar/style.scss";
@import "components/contractor/assignment/view/main-contratee/style.scss";
@import "components/contractor/assignment/view/main-order/style.scss";
@import "components/contractor/edit/profile/user-avatar/style.scss";
@import "components/contractor/settings/education/display-items/style.scss";
@import "components/contractor/settings/gcc/display-item/style.scss";
@import "components/contractor/settings/shift-travel/display-main/style.scss";
