$mdi-filename:         "materialdesignicons";
$mdi-font-name:        "Material Design Icons";
$mdi-font-family:      "materialdesignicons";
$mdi-font-weight:      "regular";
$mdi-font-path:        "../fonts" !default;
$mdi-font-size-base:   24px !default;
$mdi-css-prefix:       mdi !default;
$mdi-version:          "1.9.32" !default;

$mdi-icons: (
    "access-point": F002,
    "access-point-network": F003,
    "account": F004,
    "account-alert": F005,
    "account-box": F006,
    "account-box-outline": F007,
    "account-card-details": F5D2,
    "account-check": F008,
    "account-circle": F009,
    "account-convert": F00A,
    "account-edit": F6BB,
    "account-key": F00B,
    "account-location": F00C,
    "account-minus": F00D,
    "account-multiple": F00E,
    "account-multiple-minus": F5D3,
    "account-multiple-outline": F00F,
    "account-multiple-plus": F010,
    "account-network": F011,
    "account-off": F012,
    "account-outline": F013,
    "account-plus": F014,
    "account-remove": F015,
    "account-search": F016,
    "account-settings": F630,
    "account-settings-variant": F631,
    "account-star": F017,
    "account-switch": F019,
    "adjust": F01A,
    "air-conditioner": F01B,
    "airballoon": F01C,
    "airplane": F01D,
    "airplane-landing": F5D4,
    "airplane-off": F01E,
    "airplane-takeoff": F5D5,
    "airplay": F01F,
    "alarm": F020,
    "alarm-check": F021,
    "alarm-multiple": F022,
    "alarm-off": F023,
    "alarm-plus": F024,
    "alarm-snooze": F68D,
    "album": F025,
    "alert": F026,
    "alert-box": F027,
    "alert-circle": F028,
    "alert-circle-outline": F5D6,
    "alert-decagram": F6BC,
    "alert-octagon": F029,
    "alert-octagram": F766,
    "alert-outline": F02A,
    "all-inclusive": F6BD,
    "alpha": F02B,
    "alphabetical": F02C,
    "altimeter": F5D7,
    "amazon": F02D,
    "amazon-clouddrive": F02E,
    "ambulance": F02F,
    "amplifier": F030,
    "anchor": F031,
    "android": F032,
    "android-debug-bridge": F033,
    "android-studio": F034,
    "angular": F6B1,
    "angularjs": F6BE,
    "animation": F5D8,
    "apple": F035,
    "apple-finder": F036,
    "apple-ios": F037,
    "apple-keyboard-caps": F632,
    "apple-keyboard-command": F633,
    "apple-keyboard-control": F634,
    "apple-keyboard-option": F635,
    "apple-keyboard-shift": F636,
    "apple-mobileme": F038,
    "apple-safari": F039,
    "application": F614,
    "apps": F03B,
    "archive": F03C,
    "arrange-bring-forward": F03D,
    "arrange-bring-to-front": F03E,
    "arrange-send-backward": F03F,
    "arrange-send-to-back": F040,
    "arrow-all": F041,
    "arrow-bottom-left": F042,
    "arrow-bottom-right": F043,
    "arrow-compress": F615,
    "arrow-compress-all": F044,
    "arrow-down": F045,
    "arrow-down-bold": F72D,
    "arrow-down-bold-box": F72E,
    "arrow-down-bold-box-outline": F72F,
    "arrow-down-bold-circle": F047,
    "arrow-down-bold-circle-outline": F048,
    "arrow-down-bold-hexagon-outline": F049,
    "arrow-down-box": F6BF,
    "arrow-down-drop-circle": F04A,
    "arrow-down-drop-circle-outline": F04B,
    "arrow-down-thick": F046,
    "arrow-expand": F616,
    "arrow-expand-all": F04C,
    "arrow-left": F04D,
    "arrow-left-bold": F730,
    "arrow-left-bold-box": F731,
    "arrow-left-bold-box-outline": F732,
    "arrow-left-bold-circle": F04F,
    "arrow-left-bold-circle-outline": F050,
    "arrow-left-bold-hexagon-outline": F051,
    "arrow-left-box": F6C0,
    "arrow-left-drop-circle": F052,
    "arrow-left-drop-circle-outline": F053,
    "arrow-left-thick": F04E,
    "arrow-right": F054,
    "arrow-right-bold": F733,
    "arrow-right-bold-box": F734,
    "arrow-right-bold-box-outline": F735,
    "arrow-right-bold-circle": F056,
    "arrow-right-bold-circle-outline": F057,
    "arrow-right-bold-hexagon-outline": F058,
    "arrow-right-box": F6C1,
    "arrow-right-drop-circle": F059,
    "arrow-right-drop-circle-outline": F05A,
    "arrow-right-thick": F055,
    "arrow-top-left": F05B,
    "arrow-top-right": F05C,
    "arrow-up": F05D,
    "arrow-up-bold": F736,
    "arrow-up-bold-box": F737,
    "arrow-up-bold-box-outline": F738,
    "arrow-up-bold-circle": F05F,
    "arrow-up-bold-circle-outline": F060,
    "arrow-up-bold-hexagon-outline": F061,
    "arrow-up-box": F6C2,
    "arrow-up-drop-circle": F062,
    "arrow-up-drop-circle-outline": F063,
    "arrow-up-thick": F05E,
    "assistant": F064,
    "asterisk": F6C3,
    "at": F065,
    "atom": F767,
    "attachment": F066,
    "audiobook": F067,
    "auto-fix": F068,
    "auto-upload": F069,
    "autorenew": F06A,
    "av-timer": F06B,
    "baby": F06C,
    "baby-buggy": F68E,
    "backburger": F06D,
    "backspace": F06E,
    "backup-restore": F06F,
    "bandcamp": F674,
    "bank": F070,
    "barcode": F071,
    "barcode-scan": F072,
    "barley": F073,
    "barrel": F074,
    "basecamp": F075,
    "basket": F076,
    "basket-fill": F077,
    "basket-unfill": F078,
    "battery": F079,
    "battery-10": F07A,
    "battery-20": F07B,
    "battery-30": F07C,
    "battery-40": F07D,
    "battery-50": F07E,
    "battery-60": F07F,
    "battery-70": F080,
    "battery-80": F081,
    "battery-90": F082,
    "battery-alert": F083,
    "battery-charging": F084,
    "battery-charging-100": F085,
    "battery-charging-20": F086,
    "battery-charging-30": F087,
    "battery-charging-40": F088,
    "battery-charging-60": F089,
    "battery-charging-80": F08A,
    "battery-charging-90": F08B,
    "battery-minus": F08C,
    "battery-negative": F08D,
    "battery-outline": F08E,
    "battery-plus": F08F,
    "battery-positive": F090,
    "battery-unknown": F091,
    "beach": F092,
    "beaker": F68F,
    "beats": F097,
    "beer": F098,
    "behance": F099,
    "bell": F09A,
    "bell-off": F09B,
    "bell-outline": F09C,
    "bell-plus": F09D,
    "bell-ring": F09E,
    "bell-ring-outline": F09F,
    "bell-sleep": F0A0,
    "beta": F0A1,
    "bible": F0A2,
    "bike": F0A3,
    "bing": F0A4,
    "binoculars": F0A5,
    "bio": F0A6,
    "biohazard": F0A7,
    "bitbucket": F0A8,
    "black-mesa": F0A9,
    "blackberry": F0AA,
    "blender": F0AB,
    "blinds": F0AC,
    "block-helper": F0AD,
    "blogger": F0AE,
    "bluetooth": F0AF,
    "bluetooth-audio": F0B0,
    "bluetooth-connect": F0B1,
    "bluetooth-off": F0B2,
    "bluetooth-settings": F0B3,
    "bluetooth-transfer": F0B4,
    "blur": F0B5,
    "blur-linear": F0B6,
    "blur-off": F0B7,
    "blur-radial": F0B8,
    "bomb": F690,
    "bomb-off": F6C4,
    "bone": F0B9,
    "book": F0BA,
    "book-minus": F5D9,
    "book-multiple": F0BB,
    "book-multiple-variant": F0BC,
    "book-open": F0BD,
    "book-open-page-variant": F5DA,
    "book-open-variant": F0BE,
    "book-plus": F5DB,
    "book-variant": F0BF,
    "bookmark": F0C0,
    "bookmark-check": F0C1,
    "bookmark-music": F0C2,
    "bookmark-outline": F0C3,
    "bookmark-plus": F0C5,
    "bookmark-plus-outline": F0C4,
    "bookmark-remove": F0C6,
    "boombox": F5DC,
    "bootstrap": F6C5,
    "border-all": F0C7,
    "border-bottom": F0C8,
    "border-color": F0C9,
    "border-horizontal": F0CA,
    "border-inside": F0CB,
    "border-left": F0CC,
    "border-none": F0CD,
    "border-outside": F0CE,
    "border-right": F0CF,
    "border-style": F0D0,
    "border-top": F0D1,
    "border-vertical": F0D2,
    "bow-tie": F677,
    "bowl": F617,
    "bowling": F0D3,
    "box": F0D4,
    "box-cutter": F0D5,
    "box-shadow": F637,
    "bridge": F618,
    "briefcase": F0D6,
    "briefcase-check": F0D7,
    "briefcase-download": F0D8,
    "briefcase-upload": F0D9,
    "brightness-1": F0DA,
    "brightness-2": F0DB,
    "brightness-3": F0DC,
    "brightness-4": F0DD,
    "brightness-5": F0DE,
    "brightness-6": F0DF,
    "brightness-7": F0E0,
    "brightness-auto": F0E1,
    "broom": F0E2,
    "brush": F0E3,
    "buffer": F619,
    "bug": F0E4,
    "bulletin-board": F0E5,
    "bullhorn": F0E6,
    "bullseye": F5DD,
    "burst-mode": F5DE,
    "bus": F0E7,
    "cached": F0E8,
    "cake": F0E9,
    "cake-layered": F0EA,
    "cake-variant": F0EB,
    "calculator": F0EC,
    "calendar": F0ED,
    "calendar-blank": F0EE,
    "calendar-check": F0EF,
    "calendar-clock": F0F0,
    "calendar-multiple": F0F1,
    "calendar-multiple-check": F0F2,
    "calendar-plus": F0F3,
    "calendar-question": F691,
    "calendar-range": F678,
    "calendar-remove": F0F4,
    "calendar-text": F0F5,
    "calendar-today": F0F6,
    "call-made": F0F7,
    "call-merge": F0F8,
    "call-missed": F0F9,
    "call-received": F0FA,
    "call-split": F0FB,
    "camcorder": F0FC,
    "camcorder-box": F0FD,
    "camcorder-box-off": F0FE,
    "camcorder-off": F0FF,
    "camera": F100,
    "camera-burst": F692,
    "camera-enhance": F101,
    "camera-front": F102,
    "camera-front-variant": F103,
    "camera-iris": F104,
    "camera-off": F5DF,
    "camera-party-mode": F105,
    "camera-rear": F106,
    "camera-rear-variant": F107,
    "camera-switch": F108,
    "camera-timer": F109,
    "cancel": F739,
    "candle": F5E2,
    "candycane": F10A,
    "car": F10B,
    "car-battery": F10C,
    "car-connected": F10D,
    "car-wash": F10E,
    "cards": F638,
    "cards-outline": F639,
    "cards-playing-outline": F63A,
    "cards-variant": F6C6,
    "carrot": F10F,
    "cart": F110,
    "cart-off": F66B,
    "cart-outline": F111,
    "cart-plus": F112,
    "case-sensitive-alt": F113,
    "cash": F114,
    "cash-100": F115,
    "cash-multiple": F116,
    "cash-usd": F117,
    "cast": F118,
    "cast-connected": F119,
    "cast-off": F789,
    "castle": F11A,
    "cat": F11B,
    "ceiling-light": F768,
    "cellphone": F11C,
    "cellphone-android": F11D,
    "cellphone-basic": F11E,
    "cellphone-dock": F11F,
    "cellphone-iphone": F120,
    "cellphone-link": F121,
    "cellphone-link-off": F122,
    "cellphone-settings": F123,
    "certificate": F124,
    "chair-school": F125,
    "chart-arc": F126,
    "chart-areaspline": F127,
    "chart-bar": F128,
    "chart-bar-stacked": F769,
    "chart-bubble": F5E3,
    "chart-gantt": F66C,
    "chart-histogram": F129,
    "chart-line": F12A,
    "chart-line-stacked": F76A,
    "chart-pie": F12B,
    "chart-scatterplot-hexbin": F66D,
    "chart-timeline": F66E,
    "check": F12C,
    "check-all": F12D,
    "check-circle": F5E0,
    "check-circle-outline": F5E1,
    "checkbox-blank": F12E,
    "checkbox-blank-circle": F12F,
    "checkbox-blank-circle-outline": F130,
    "checkbox-blank-outline": F131,
    "checkbox-marked": F132,
    "checkbox-marked-circle": F133,
    "checkbox-marked-circle-outline": F134,
    "checkbox-marked-outline": F135,
    "checkbox-multiple-blank": F136,
    "checkbox-multiple-blank-circle": F63B,
    "checkbox-multiple-blank-circle-outline": F63C,
    "checkbox-multiple-blank-outline": F137,
    "checkbox-multiple-marked": F138,
    "checkbox-multiple-marked-circle": F63D,
    "checkbox-multiple-marked-circle-outline": F63E,
    "checkbox-multiple-marked-outline": F139,
    "checkerboard": F13A,
    "chemical-weapon": F13B,
    "chevron-double-down": F13C,
    "chevron-double-left": F13D,
    "chevron-double-right": F13E,
    "chevron-double-up": F13F,
    "chevron-down": F140,
    "chevron-left": F141,
    "chevron-right": F142,
    "chevron-up": F143,
    "chip": F61A,
    "church": F144,
    "circle": F764,
    "circle-outline": F765,
    "cisco-webex": F145,
    "city": F146,
    "clipboard": F147,
    "clipboard-account": F148,
    "clipboard-alert": F149,
    "clipboard-arrow-down": F14A,
    "clipboard-arrow-left": F14B,
    "clipboard-check": F14C,
    "clipboard-flow": F6C7,
    "clipboard-outline": F14D,
    "clipboard-plus": F750,
    "clipboard-text": F14E,
    "clippy": F14F,
    "clock": F150,
    "clock-alert": F5CE,
    "clock-end": F151,
    "clock-fast": F152,
    "clock-in": F153,
    "clock-out": F154,
    "clock-start": F155,
    "close": F156,
    "close-box": F157,
    "close-box-outline": F158,
    "close-circle": F159,
    "close-circle-outline": F15A,
    "close-network": F15B,
    "close-octagon": F15C,
    "close-octagon-outline": F15D,
    "close-outline": F6C8,
    "closed-caption": F15E,
    "cloud": F15F,
    "cloud-check": F160,
    "cloud-circle": F161,
    "cloud-download": F162,
    "cloud-off-outline": F164,
    "cloud-outline": F163,
    "cloud-print": F165,
    "cloud-print-outline": F166,
    "cloud-sync": F63F,
    "cloud-upload": F167,
    "code-array": F168,
    "code-braces": F169,
    "code-brackets": F16A,
    "code-equal": F16B,
    "code-greater-than": F16C,
    "code-greater-than-or-equal": F16D,
    "code-less-than": F16E,
    "code-less-than-or-equal": F16F,
    "code-not-equal": F170,
    "code-not-equal-variant": F171,
    "code-parentheses": F172,
    "code-string": F173,
    "code-tags": F174,
    "code-tags-check": F693,
    "codepen": F175,
    "coffee": F176,
    "coffee-outline": F6C9,
    "coffee-to-go": F177,
    "coin": F178,
    "coins": F694,
    "collage": F640,
    "color-helper": F179,
    "comment": F17A,
    "comment-account": F17B,
    "comment-account-outline": F17C,
    "comment-alert": F17D,
    "comment-alert-outline": F17E,
    "comment-check": F17F,
    "comment-check-outline": F180,
    "comment-multiple-outline": F181,
    "comment-outline": F182,
    "comment-plus-outline": F183,
    "comment-processing": F184,
    "comment-processing-outline": F185,
    "comment-question-outline": F186,
    "comment-remove-outline": F187,
    "comment-text": F188,
    "comment-text-outline": F189,
    "compare": F18A,
    "compass": F18B,
    "compass-outline": F18C,
    "console": F18D,
    "contact-mail": F18E,
    "contacts": F6CA,
    "content-copy": F18F,
    "content-cut": F190,
    "content-duplicate": F191,
    "content-paste": F192,
    "content-save": F193,
    "content-save-all": F194,
    "content-save-settings": F61B,
    "contrast": F195,
    "contrast-box": F196,
    "contrast-circle": F197,
    "cookie": F198,
    "copyright": F5E6,
    "counter": F199,
    "cow": F19A,
    "creation": F1C9,
    "credit-card": F19B,
    "credit-card-multiple": F19C,
    "credit-card-off": F5E4,
    "credit-card-plus": F675,
    "credit-card-scan": F19D,
    "crop": F19E,
    "crop-free": F19F,
    "crop-landscape": F1A0,
    "crop-portrait": F1A1,
    "crop-rotate": F695,
    "crop-square": F1A2,
    "crosshairs": F1A3,
    "crosshairs-gps": F1A4,
    "crown": F1A5,
    "cube": F1A6,
    "cube-outline": F1A7,
    "cube-send": F1A8,
    "cube-unfolded": F1A9,
    "cup": F1AA,
    "cup-off": F5E5,
    "cup-water": F1AB,
    "currency-btc": F1AC,
    "currency-eur": F1AD,
    "currency-gbp": F1AE,
    "currency-inr": F1AF,
    "currency-ngn": F1B0,
    "currency-rub": F1B1,
    "currency-try": F1B2,
    "currency-usd": F1B3,
    "currency-usd-off": F679,
    "cursor-default": F1B4,
    "cursor-default-outline": F1B5,
    "cursor-move": F1B6,
    "cursor-pointer": F1B7,
    "cursor-text": F5E7,
    "database": F1B8,
    "database-minus": F1B9,
    "database-plus": F1BA,
    "debug-step-into": F1BB,
    "debug-step-out": F1BC,
    "debug-step-over": F1BD,
    "decagram": F76B,
    "decagram-outline": F76C,
    "decimal-decrease": F1BE,
    "decimal-increase": F1BF,
    "delete": F1C0,
    "delete-circle": F682,
    "delete-empty": F6CB,
    "delete-forever": F5E8,
    "delete-sweep": F5E9,
    "delete-variant": F1C1,
    "delta": F1C2,
    "deskphone": F1C3,
    "desktop-mac": F1C4,
    "desktop-tower": F1C5,
    "details": F1C6,
    "developer-board": F696,
    "deviantart": F1C7,
    "dialpad": F61C,
    "diamond": F1C8,
    "dice-1": F1CA,
    "dice-2": F1CB,
    "dice-3": F1CC,
    "dice-4": F1CD,
    "dice-5": F1CE,
    "dice-6": F1CF,
    "dice-d10": F76E,
    "dice-d20": F5EA,
    "dice-d4": F5EB,
    "dice-d6": F5EC,
    "dice-d8": F5ED,
    "dice-multiple": F76D,
    "dictionary": F61D,
    "directions": F1D0,
    "directions-fork": F641,
    "discord": F66F,
    "disk": F5EE,
    "disk-alert": F1D1,
    "disqus": F1D2,
    "disqus-outline": F1D3,
    "division": F1D4,
    "division-box": F1D5,
    "dna": F683,
    "dns": F1D6,
    "do-not-disturb": F697,
    "do-not-disturb-off": F698,
    "dolby": F6B2,
    "domain": F1D7,
    "dots-horizontal": F1D8,
    "dots-vertical": F1D9,
    "douban": F699,
    "download": F1DA,
    "download-network": F6F3,
    "drag": F1DB,
    "drag-horizontal": F1DC,
    "drag-vertical": F1DD,
    "drawing": F1DE,
    "drawing-box": F1DF,
    "dribbble": F1E0,
    "dribbble-box": F1E1,
    "drone": F1E2,
    "dropbox": F1E3,
    "drupal": F1E4,
    "duck": F1E5,
    "dumbbell": F1E6,
    "earth": F1E7,
    "earth-box": F6CC,
    "earth-box-off": F6CD,
    "earth-off": F1E8,
    "edge": F1E9,
    "eject": F1EA,
    "elevation-decline": F1EB,
    "elevation-rise": F1EC,
    "elevator": F1ED,
    "email": F1EE,
    "email-alert": F6CE,
    "email-open": F1EF,
    "email-open-outline": F5EF,
    "email-outline": F1F0,
    "email-secure": F1F1,
    "email-variant": F5F0,
    "emby": F6B3,
    "emoticon": F1F2,
    "emoticon-cool": F1F3,
    "emoticon-dead": F69A,
    "emoticon-devil": F1F4,
    "emoticon-excited": F69B,
    "emoticon-happy": F1F5,
    "emoticon-neutral": F1F6,
    "emoticon-poop": F1F7,
    "emoticon-sad": F1F8,
    "emoticon-tongue": F1F9,
    "engine": F1FA,
    "engine-outline": F1FB,
    "equal": F1FC,
    "equal-box": F1FD,
    "eraser": F1FE,
    "eraser-variant": F642,
    "escalator": F1FF,
    "ethernet": F200,
    "ethernet-cable": F201,
    "ethernet-cable-off": F202,
    "etsy": F203,
    "ev-station": F5F1,
    "evernote": F204,
    "exclamation": F205,
    "exit-to-app": F206,
    "export": F207,
    "eye": F208,
    "eye-off": F209,
    "eye-off-outline": F6D0,
    "eye-outline": F6CF,
    "eyedropper": F20A,
    "eyedropper-variant": F20B,
    "face": F643,
    "face-profile": F644,
    "facebook": F20C,
    "facebook-box": F20D,
    "facebook-messenger": F20E,
    "factory": F20F,
    "fan": F210,
    "fast-forward": F211,
    "fast-forward-outline": F6D1,
    "fax": F212,
    "feather": F6D2,
    "ferry": F213,
    "file": F214,
    "file-account": F73A,
    "file-chart": F215,
    "file-check": F216,
    "file-cloud": F217,
    "file-delimited": F218,
    "file-document": F219,
    "file-document-box": F21A,
    "file-excel": F21B,
    "file-excel-box": F21C,
    "file-export": F21D,
    "file-find": F21E,
    "file-hidden": F613,
    "file-image": F21F,
    "file-import": F220,
    "file-lock": F221,
    "file-multiple": F222,
    "file-music": F223,
    "file-outline": F224,
    "file-pdf": F225,
    "file-pdf-box": F226,
    "file-plus": F751,
    "file-powerpoint": F227,
    "file-powerpoint-box": F228,
    "file-presentation-box": F229,
    "file-restore": F670,
    "file-send": F22A,
    "file-tree": F645,
    "file-video": F22B,
    "file-word": F22C,
    "file-word-box": F22D,
    "file-xml": F22E,
    "film": F22F,
    "filmstrip": F230,
    "filmstrip-off": F231,
    "filter": F232,
    "filter-outline": F233,
    "filter-remove": F234,
    "filter-remove-outline": F235,
    "filter-variant": F236,
    "find-replace": F6D3,
    "fingerprint": F237,
    "fire": F238,
    "firefox": F239,
    "fish": F23A,
    "flag": F23B,
    "flag-checkered": F23C,
    "flag-outline": F23D,
    "flag-outline-variant": F23E,
    "flag-triangle": F23F,
    "flag-variant": F240,
    "flash": F241,
    "flash-auto": F242,
    "flash-off": F243,
    "flash-outline": F6D4,
    "flash-red-eye": F67A,
    "flashlight": F244,
    "flashlight-off": F245,
    "flask": F093,
    "flask-empty": F094,
    "flask-empty-outline": F095,
    "flask-outline": F096,
    "flattr": F246,
    "flip-to-back": F247,
    "flip-to-front": F248,
    "floppy": F249,
    "flower": F24A,
    "folder": F24B,
    "folder-account": F24C,
    "folder-download": F24D,
    "folder-google-drive": F24E,
    "folder-image": F24F,
    "folder-lock": F250,
    "folder-lock-open": F251,
    "folder-move": F252,
    "folder-multiple": F253,
    "folder-multiple-image": F254,
    "folder-multiple-outline": F255,
    "folder-open": F76F,
    "folder-outline": F256,
    "folder-plus": F257,
    "folder-remove": F258,
    "folder-star": F69C,
    "folder-upload": F259,
    "font-awesome": F03A,
    "food": F25A,
    "food-apple": F25B,
    "food-fork-drink": F5F2,
    "food-off": F5F3,
    "food-variant": F25C,
    "football": F25D,
    "football-australian": F25E,
    "football-helmet": F25F,
    "format-align-bottom": F752,
    "format-align-center": F260,
    "format-align-justify": F261,
    "format-align-left": F262,
    "format-align-middle": F753,
    "format-align-right": F263,
    "format-align-top": F754,
    "format-annotation-plus": F646,
    "format-bold": F264,
    "format-clear": F265,
    "format-color-fill": F266,
    "format-color-text": F69D,
    "format-float-center": F267,
    "format-float-left": F268,
    "format-float-none": F269,
    "format-float-right": F26A,
    "format-font": F6D5,
    "format-header-1": F26B,
    "format-header-2": F26C,
    "format-header-3": F26D,
    "format-header-4": F26E,
    "format-header-5": F26F,
    "format-header-6": F270,
    "format-header-decrease": F271,
    "format-header-equal": F272,
    "format-header-increase": F273,
    "format-header-pound": F274,
    "format-horizontal-align-center": F61E,
    "format-horizontal-align-left": F61F,
    "format-horizontal-align-right": F620,
    "format-indent-decrease": F275,
    "format-indent-increase": F276,
    "format-italic": F277,
    "format-line-spacing": F278,
    "format-line-style": F5C8,
    "format-line-weight": F5C9,
    "format-list-bulleted": F279,
    "format-list-bulleted-type": F27A,
    "format-list-checks": F755,
    "format-list-numbers": F27B,
    "format-page-break": F6D6,
    "format-paint": F27C,
    "format-paragraph": F27D,
    "format-pilcrow": F6D7,
    "format-quote-close": F27E,
    "format-quote-open": F756,
    "format-rotate-90": F6A9,
    "format-section": F69E,
    "format-size": F27F,
    "format-strikethrough": F280,
    "format-strikethrough-variant": F281,
    "format-subscript": F282,
    "format-superscript": F283,
    "format-text": F284,
    "format-textdirection-l-to-r": F285,
    "format-textdirection-r-to-l": F286,
    "format-title": F5F4,
    "format-underline": F287,
    "format-vertical-align-bottom": F621,
    "format-vertical-align-center": F622,
    "format-vertical-align-top": F623,
    "format-wrap-inline": F288,
    "format-wrap-square": F289,
    "format-wrap-tight": F28A,
    "format-wrap-top-bottom": F28B,
    "forum": F28C,
    "forward": F28D,
    "foursquare": F28E,
    "fridge": F28F,
    "fridge-filled": F290,
    "fridge-filled-bottom": F291,
    "fridge-filled-top": F292,
    "fullscreen": F293,
    "fullscreen-exit": F294,
    "function": F295,
    "gamepad": F296,
    "gamepad-variant": F297,
    "garage": F6D8,
    "garage-open": F6D9,
    "gas-cylinder": F647,
    "gas-station": F298,
    "gate": F299,
    "gauge": F29A,
    "gavel": F29B,
    "gender-female": F29C,
    "gender-male": F29D,
    "gender-male-female": F29E,
    "gender-transgender": F29F,
    "gesture-double-tap": F73B,
    "gesture-swipe-down": F73C,
    "gesture-swipe-left": F73D,
    "gesture-swipe-right": F73E,
    "gesture-swipe-up": F73F,
    "gesture-tap": F740,
    "gesture-two-double-tap": F741,
    "gesture-two-tap": F742,
    "ghost": F2A0,
    "gift": F2A1,
    "git": F2A2,
    "github-box": F2A3,
    "github-circle": F2A4,
    "github-face": F6DA,
    "glass-flute": F2A5,
    "glass-mug": F2A6,
    "glass-stange": F2A7,
    "glass-tulip": F2A8,
    "glassdoor": F2A9,
    "glasses": F2AA,
    "gmail": F2AB,
    "gnome": F2AC,
    "gondola": F685,
    "google": F2AD,
    "google-cardboard": F2AE,
    "google-chrome": F2AF,
    "google-circles": F2B0,
    "google-circles-communities": F2B1,
    "google-circles-extended": F2B2,
    "google-circles-group": F2B3,
    "google-controller": F2B4,
    "google-controller-off": F2B5,
    "google-drive": F2B6,
    "google-earth": F2B7,
    "google-glass": F2B8,
    "google-keep": F6DB,
    "google-maps": F5F5,
    "google-nearby": F2B9,
    "google-pages": F2BA,
    "google-photos": F6DC,
    "google-physical-web": F2BB,
    "google-play": F2BC,
    "google-plus": F2BD,
    "google-plus-box": F2BE,
    "google-translate": F2BF,
    "google-wallet": F2C0,
    "gradient": F69F,
    "grease-pencil": F648,
    "grid": F2C1,
    "grid-large": F757,
    "grid-off": F2C2,
    "group": F2C3,
    "guitar-acoustic": F770,
    "guitar-electric": F2C4,
    "guitar-pick": F2C5,
    "guitar-pick-outline": F2C6,
    "hackernews": F624,
    "hamburger": F684,
    "hand-pointing-right": F2C7,
    "hanger": F2C8,
    "hangouts": F2C9,
    "harddisk": F2CA,
    "headphones": F2CB,
    "headphones-box": F2CC,
    "headphones-settings": F2CD,
    "headset": F2CE,
    "headset-dock": F2CF,
    "headset-off": F2D0,
    "heart": F2D1,
    "heart-box": F2D2,
    "heart-box-outline": F2D3,
    "heart-broken": F2D4,
    "heart-half": F6DE,
    "heart-half-full": F6DD,
    "heart-half-outline": F6DF,
    "heart-off": F758,
    "heart-outline": F2D5,
    "heart-pulse": F5F6,
    "help": F2D6,
    "help-box": F78A,
    "help-circle": F2D7,
    "help-circle-outline": F625,
    "help-network": F6F4,
    "hexagon": F2D8,
    "hexagon-multiple": F6E0,
    "hexagon-outline": F2D9,
    "highway": F5F7,
    "history": F2DA,
    "hololens": F2DB,
    "home": F2DC,
    "home-map-marker": F5F8,
    "home-modern": F2DD,
    "home-outline": F6A0,
    "home-variant": F2DE,
    "hook": F6E1,
    "hook-off": F6E2,
    "hops": F2DF,
    "hospital": F2E0,
    "hospital-building": F2E1,
    "hospital-marker": F2E2,
    "hotel": F2E3,
    "houzz": F2E4,
    "houzz-box": F2E5,
    "human": F2E6,
    "human-child": F2E7,
    "human-female": F649,
    "human-greeting": F64A,
    "human-handsdown": F64B,
    "human-handsup": F64C,
    "human-male": F64D,
    "human-male-female": F2E8,
    "human-pregnant": F5CF,
    "humble-bundle": F743,
    "image": F2E9,
    "image-album": F2EA,
    "image-area": F2EB,
    "image-area-close": F2EC,
    "image-broken": F2ED,
    "image-broken-variant": F2EE,
    "image-filter": F2EF,
    "image-filter-black-white": F2F0,
    "image-filter-center-focus": F2F1,
    "image-filter-center-focus-weak": F2F2,
    "image-filter-drama": F2F3,
    "image-filter-frames": F2F4,
    "image-filter-hdr": F2F5,
    "image-filter-none": F2F6,
    "image-filter-tilt-shift": F2F7,
    "image-filter-vintage": F2F8,
    "image-multiple": F2F9,
    "import": F2FA,
    "inbox": F686,
    "inbox-arrow-down": F2FB,
    "inbox-arrow-up": F3D1,
    "incognito": F5F9,
    "infinity": F6E3,
    "information": F2FC,
    "information-outline": F2FD,
    "information-variant": F64E,
    "instagram": F2FE,
    "instapaper": F2FF,
    "internet-explorer": F300,
    "invert-colors": F301,
    "itunes": F676,
    "jeepney": F302,
    "jira": F303,
    "jsfiddle": F304,
    "json": F626,
    "keg": F305,
    "kettle": F5FA,
    "key": F306,
    "key-change": F307,
    "key-minus": F308,
    "key-plus": F309,
    "key-remove": F30A,
    "key-variant": F30B,
    "keyboard": F30C,
    "keyboard-backspace": F30D,
    "keyboard-caps": F30E,
    "keyboard-close": F30F,
    "keyboard-off": F310,
    "keyboard-return": F311,
    "keyboard-tab": F312,
    "keyboard-variant": F313,
    "kickstarter": F744,
    "kodi": F314,
    "label": F315,
    "label-outline": F316,
    "lambda": F627,
    "lamp": F6B4,
    "lan": F317,
    "lan-connect": F318,
    "lan-disconnect": F319,
    "lan-pending": F31A,
    "language-c": F671,
    "language-cpp": F672,
    "language-csharp": F31B,
    "language-css3": F31C,
    "language-html5": F31D,
    "language-javascript": F31E,
    "language-php": F31F,
    "language-python": F320,
    "language-python-text": F321,
    "language-swift": F6E4,
    "language-typescript": F6E5,
    "laptop": F322,
    "laptop-chromebook": F323,
    "laptop-mac": F324,
    "laptop-off": F6E6,
    "laptop-windows": F325,
    "lastfm": F326,
    "launch": F327,
    "layers": F328,
    "layers-off": F329,
    "lead-pencil": F64F,
    "leaf": F32A,
    "led-off": F32B,
    "led-on": F32C,
    "led-outline": F32D,
    "led-variant-off": F32E,
    "led-variant-on": F32F,
    "led-variant-outline": F330,
    "library": F331,
    "library-books": F332,
    "library-music": F333,
    "library-plus": F334,
    "lightbulb": F335,
    "lightbulb-on": F6E7,
    "lightbulb-on-outline": F6E8,
    "lightbulb-outline": F336,
    "link": F337,
    "link-off": F338,
    "link-variant": F339,
    "link-variant-off": F33A,
    "linkedin": F33B,
    "linkedin-box": F33C,
    "linux": F33D,
    "loading": F771,
    "lock": F33E,
    "lock-open": F33F,
    "lock-open-outline": F340,
    "lock-outline": F341,
    "lock-pattern": F6E9,
    "lock-plus": F5FB,
    "lock-reset": F772,
    "login": F342,
    "login-variant": F5FC,
    "logout": F343,
    "logout-variant": F5FD,
    "looks": F344,
    "loop": F6EA,
    "loupe": F345,
    "lumx": F346,
    "magnet": F347,
    "magnet-on": F348,
    "magnify": F349,
    "magnify-minus": F34A,
    "magnify-minus-outline": F6EB,
    "magnify-plus": F34B,
    "magnify-plus-outline": F6EC,
    "mail-ru": F34C,
    "mailbox": F6ED,
    "map": F34D,
    "map-marker": F34E,
    "map-marker-circle": F34F,
    "map-marker-minus": F650,
    "map-marker-multiple": F350,
    "map-marker-off": F351,
    "map-marker-plus": F651,
    "map-marker-radius": F352,
    "margin": F353,
    "markdown": F354,
    "marker": F652,
    "marker-check": F355,
    "martini": F356,
    "material-ui": F357,
    "math-compass": F358,
    "matrix": F628,
    "maxcdn": F359,
    "medical-bag": F6EE,
    "medium": F35A,
    "memory": F35B,
    "menu": F35C,
    "menu-down": F35D,
    "menu-down-outline": F6B5,
    "menu-left": F35E,
    "menu-right": F35F,
    "menu-up": F360,
    "menu-up-outline": F6B6,
    "message": F361,
    "message-alert": F362,
    "message-bulleted": F6A1,
    "message-bulleted-off": F6A2,
    "message-draw": F363,
    "message-image": F364,
    "message-outline": F365,
    "message-plus": F653,
    "message-processing": F366,
    "message-reply": F367,
    "message-reply-text": F368,
    "message-settings": F6EF,
    "message-settings-variant": F6F0,
    "message-text": F369,
    "message-text-outline": F36A,
    "message-video": F36B,
    "meteor": F629,
    "microphone": F36C,
    "microphone-off": F36D,
    "microphone-outline": F36E,
    "microphone-settings": F36F,
    "microphone-variant": F370,
    "microphone-variant-off": F371,
    "microscope": F654,
    "microsoft": F372,
    "minecraft": F373,
    "minus": F374,
    "minus-box": F375,
    "minus-box-outline": F6F1,
    "minus-circle": F376,
    "minus-circle-outline": F377,
    "minus-network": F378,
    "mixcloud": F62A,
    "monitor": F379,
    "monitor-multiple": F37A,
    "more": F37B,
    "motorbike": F37C,
    "mouse": F37D,
    "mouse-off": F37E,
    "mouse-variant": F37F,
    "mouse-variant-off": F380,
    "move-resize": F655,
    "move-resize-variant": F656,
    "movie": F381,
    "multiplication": F382,
    "multiplication-box": F383,
    "music": F759,
    "music-box": F384,
    "music-box-outline": F385,
    "music-circle": F386,
    "music-note": F387,
    "music-note-bluetooth": F5FE,
    "music-note-bluetooth-off": F5FF,
    "music-note-eighth": F388,
    "music-note-half": F389,
    "music-note-off": F38A,
    "music-note-quarter": F38B,
    "music-note-sixteenth": F38C,
    "music-note-whole": F38D,
    "music-off": F75A,
    "nature": F38E,
    "nature-people": F38F,
    "navigation": F390,
    "near-me": F5CD,
    "needle": F391,
    "nest-protect": F392,
    "nest-thermostat": F393,
    "netflix": F745,
    "network": F6F2,
    "new-box": F394,
    "newspaper": F395,
    "nfc": F396,
    "nfc-tap": F397,
    "nfc-variant": F398,
    "ninja": F773,
    "nodejs": F399,
    "note": F39A,
    "note-multiple": F6B7,
    "note-multiple-outline": F6B8,
    "note-outline": F39B,
    "note-plus": F39C,
    "note-plus-outline": F39D,
    "note-text": F39E,
    "notification-clear-all": F39F,
    "npm": F6F6,
    "nuke": F6A3,
    "numeric": F3A0,
    "numeric-0-box": F3A1,
    "numeric-0-box-multiple-outline": F3A2,
    "numeric-0-box-outline": F3A3,
    "numeric-1-box": F3A4,
    "numeric-1-box-multiple-outline": F3A5,
    "numeric-1-box-outline": F3A6,
    "numeric-2-box": F3A7,
    "numeric-2-box-multiple-outline": F3A8,
    "numeric-2-box-outline": F3A9,
    "numeric-3-box": F3AA,
    "numeric-3-box-multiple-outline": F3AB,
    "numeric-3-box-outline": F3AC,
    "numeric-4-box": F3AD,
    "numeric-4-box-multiple-outline": F3AE,
    "numeric-4-box-outline": F3AF,
    "numeric-5-box": F3B0,
    "numeric-5-box-multiple-outline": F3B1,
    "numeric-5-box-outline": F3B2,
    "numeric-6-box": F3B3,
    "numeric-6-box-multiple-outline": F3B4,
    "numeric-6-box-outline": F3B5,
    "numeric-7-box": F3B6,
    "numeric-7-box-multiple-outline": F3B7,
    "numeric-7-box-outline": F3B8,
    "numeric-8-box": F3B9,
    "numeric-8-box-multiple-outline": F3BA,
    "numeric-8-box-outline": F3BB,
    "numeric-9-box": F3BC,
    "numeric-9-box-multiple-outline": F3BD,
    "numeric-9-box-outline": F3BE,
    "numeric-9-plus-box": F3BF,
    "numeric-9-plus-box-multiple-outline": F3C0,
    "numeric-9-plus-box-outline": F3C1,
    "nut": F6F7,
    "nutrition": F3C2,
    "oar": F67B,
    "octagon": F3C3,
    "octagon-outline": F3C4,
    "octagram": F6F8,
    "octagram-outline": F774,
    "odnoklassniki": F3C5,
    "office": F3C6,
    "oil": F3C7,
    "oil-temperature": F3C8,
    "omega": F3C9,
    "onedrive": F3CA,
    "onenote": F746,
    "opacity": F5CC,
    "open-in-app": F3CB,
    "open-in-new": F3CC,
    "openid": F3CD,
    "opera": F3CE,
    "orbit": F018,
    "ornament": F3CF,
    "ornament-variant": F3D0,
    "owl": F3D2,
    "package": F3D3,
    "package-down": F3D4,
    "package-up": F3D5,
    "package-variant": F3D6,
    "package-variant-closed": F3D7,
    "page-first": F600,
    "page-last": F601,
    "page-layout-body": F6F9,
    "page-layout-footer": F6FA,
    "page-layout-header": F6FB,
    "page-layout-sidebar-left": F6FC,
    "page-layout-sidebar-right": F6FD,
    "palette": F3D8,
    "palette-advanced": F3D9,
    "panda": F3DA,
    "pandora": F3DB,
    "panorama": F3DC,
    "panorama-fisheye": F3DD,
    "panorama-horizontal": F3DE,
    "panorama-vertical": F3DF,
    "panorama-wide-angle": F3E0,
    "paper-cut-vertical": F3E1,
    "paperclip": F3E2,
    "parking": F3E3,
    "pause": F3E4,
    "pause-circle": F3E5,
    "pause-circle-outline": F3E6,
    "pause-octagon": F3E7,
    "pause-octagon-outline": F3E8,
    "paw": F3E9,
    "paw-off": F657,
    "pen": F3EA,
    "pencil": F3EB,
    "pencil-box": F3EC,
    "pencil-box-outline": F3ED,
    "pencil-circle": F6FE,
    "pencil-circle-outline": F775,
    "pencil-lock": F3EE,
    "pencil-off": F3EF,
    "pentagon": F6FF,
    "pentagon-outline": F700,
    "percent": F3F0,
    "periscope": F747,
    "pharmacy": F3F1,
    "phone": F3F2,
    "phone-bluetooth": F3F3,
    "phone-classic": F602,
    "phone-forward": F3F4,
    "phone-hangup": F3F5,
    "phone-in-talk": F3F6,
    "phone-incoming": F3F7,
    "phone-locked": F3F8,
    "phone-log": F3F9,
    "phone-minus": F658,
    "phone-missed": F3FA,
    "phone-outgoing": F3FB,
    "phone-paused": F3FC,
    "phone-plus": F659,
    "phone-settings": F3FD,
    "phone-voip": F3FE,
    "pi": F3FF,
    "pi-box": F400,
    "piano": F67C,
    "pig": F401,
    "pill": F402,
    "pillar": F701,
    "pin": F403,
    "pin-off": F404,
    "pine-tree": F405,
    "pine-tree-box": F406,
    "pinterest": F407,
    "pinterest-box": F408,
    "pistol": F702,
    "pizza": F409,
    "plane-shield": F6BA,
    "play": F40A,
    "play-box-outline": F40B,
    "play-circle": F40C,
    "play-circle-outline": F40D,
    "play-pause": F40E,
    "play-protected-content": F40F,
    "playlist-check": F5C7,
    "playlist-minus": F410,
    "playlist-play": F411,
    "playlist-plus": F412,
    "playlist-remove": F413,
    "playstation": F414,
    "plex": F6B9,
    "plus": F415,
    "plus-box": F416,
    "plus-box-outline": F703,
    "plus-circle": F417,
    "plus-circle-multiple-outline": F418,
    "plus-circle-outline": F419,
    "plus-network": F41A,
    "plus-one": F41B,
    "plus-outline": F704,
    "pocket": F41C,
    "pokeball": F41D,
    "polaroid": F41E,
    "poll": F41F,
    "poll-box": F420,
    "polymer": F421,
    "pool": F606,
    "popcorn": F422,
    "pot": F65A,
    "pot-mix": F65B,
    "pound": F423,
    "pound-box": F424,
    "power": F425,
    "power-plug": F6A4,
    "power-plug-off": F6A5,
    "power-settings": F426,
    "power-socket": F427,
    "prescription": F705,
    "presentation": F428,
    "presentation-play": F429,
    "printer": F42A,
    "printer-3d": F42B,
    "printer-alert": F42C,
    "printer-settings": F706,
    "priority-high": F603,
    "priority-low": F604,
    "professional-hexagon": F42D,
    "projector": F42E,
    "projector-screen": F42F,
    "publish": F6A6,
    "pulse": F430,
    "puzzle": F431,
    "qqchat": F605,
    "qrcode": F432,
    "qrcode-scan": F433,
    "quadcopter": F434,
    "quality-high": F435,
    "quicktime": F436,
    "radar": F437,
    "radiator": F438,
    "radio": F439,
    "radio-handheld": F43A,
    "radio-tower": F43B,
    "radioactive": F43C,
    "radiobox-blank": F43D,
    "radiobox-marked": F43E,
    "raspberrypi": F43F,
    "ray-end": F440,
    "ray-end-arrow": F441,
    "ray-start": F442,
    "ray-start-arrow": F443,
    "ray-start-end": F444,
    "ray-vertex": F445,
    "rdio": F446,
    "react": F707,
    "read": F447,
    "readability": F448,
    "receipt": F449,
    "record": F44A,
    "record-rec": F44B,
    "recycle": F44C,
    "reddit": F44D,
    "redo": F44E,
    "redo-variant": F44F,
    "refresh": F450,
    "regex": F451,
    "relative-scale": F452,
    "reload": F453,
    "remote": F454,
    "rename-box": F455,
    "reorder-horizontal": F687,
    "reorder-vertical": F688,
    "repeat": F456,
    "repeat-off": F457,
    "repeat-once": F458,
    "replay": F459,
    "reply": F45A,
    "reply-all": F45B,
    "reproduction": F45C,
    "resize-bottom-right": F45D,
    "responsive": F45E,
    "restart": F708,
    "restore": F6A7,
    "rewind": F45F,
    "rewind-outline": F709,
    "rhombus": F70A,
    "rhombus-outline": F70B,
    "ribbon": F460,
    "road": F461,
    "road-variant": F462,
    "robot": F6A8,
    "rocket": F463,
    "roomba": F70C,
    "rotate-3d": F464,
    "rotate-left": F465,
    "rotate-left-variant": F466,
    "rotate-right": F467,
    "rotate-right-variant": F468,
    "rounded-corner": F607,
    "router-wireless": F469,
    "routes": F46A,
    "rowing": F608,
    "rss": F46B,
    "rss-box": F46C,
    "ruler": F46D,
    "run": F70D,
    "run-fast": F46E,
    "sale": F46F,
    "satellite": F470,
    "satellite-variant": F471,
    "saxophone": F609,
    "scale": F472,
    "scale-balance": F5D1,
    "scale-bathroom": F473,
    "scanner": F6AA,
    "school": F474,
    "screen-rotation": F475,
    "screen-rotation-lock": F476,
    "screwdriver": F477,
    "script": F478,
    "sd": F479,
    "seal": F47A,
    "search-web": F70E,
    "seat-flat": F47B,
    "seat-flat-angled": F47C,
    "seat-individual-suite": F47D,
    "seat-legroom-extra": F47E,
    "seat-legroom-normal": F47F,
    "seat-legroom-reduced": F480,
    "seat-recline-extra": F481,
    "seat-recline-normal": F482,
    "security": F483,
    "security-home": F689,
    "security-network": F484,
    "select": F485,
    "select-all": F486,
    "select-inverse": F487,
    "select-off": F488,
    "selection": F489,
    "selection-off": F776,
    "send": F48A,
    "serial-port": F65C,
    "server": F48B,
    "server-minus": F48C,
    "server-network": F48D,
    "server-network-off": F48E,
    "server-off": F48F,
    "server-plus": F490,
    "server-remove": F491,
    "server-security": F492,
    "set-all": F777,
    "set-center": F778,
    "set-center-right": F779,
    "set-left": F77A,
    "set-left-center": F77B,
    "set-left-right": F77C,
    "set-none": F77D,
    "set-right": F77E,
    "settings": F493,
    "settings-box": F494,
    "shape-circle-plus": F65D,
    "shape-plus": F495,
    "shape-polygon-plus": F65E,
    "shape-rectangle-plus": F65F,
    "shape-square-plus": F660,
    "share": F496,
    "share-variant": F497,
    "shield": F498,
    "shield-half-full": F77F,
    "shield-outline": F499,
    "shopping": F49A,
    "shopping-music": F49B,
    "shovel": F70F,
    "shovel-off": F710,
    "shredder": F49C,
    "shuffle": F49D,
    "shuffle-disabled": F49E,
    "shuffle-variant": F49F,
    "sigma": F4A0,
    "sigma-lower": F62B,
    "sign-caution": F4A1,
    "sign-direction": F780,
    "sign-text": F781,
    "signal": F4A2,
    "signal-2g": F711,
    "signal-3g": F712,
    "signal-4g": F713,
    "signal-hspa": F714,
    "signal-hspa-plus": F715,
    "signal-off": F782,
    "signal-variant": F60A,
    "silverware": F4A3,
    "silverware-fork": F4A4,
    "silverware-spoon": F4A5,
    "silverware-variant": F4A6,
    "sim": F4A7,
    "sim-alert": F4A8,
    "sim-off": F4A9,
    "sitemap": F4AA,
    "skip-backward": F4AB,
    "skip-forward": F4AC,
    "skip-next": F4AD,
    "skip-next-circle": F661,
    "skip-next-circle-outline": F662,
    "skip-previous": F4AE,
    "skip-previous-circle": F663,
    "skip-previous-circle-outline": F664,
    "skull": F68B,
    "skype": F4AF,
    "skype-business": F4B0,
    "slack": F4B1,
    "sleep": F4B2,
    "sleep-off": F4B3,
    "smoking": F4B4,
    "smoking-off": F4B5,
    "snapchat": F4B6,
    "snowflake": F716,
    "snowman": F4B7,
    "soccer": F4B8,
    "sofa": F4B9,
    "solid": F68C,
    "sort": F4BA,
    "sort-alphabetical": F4BB,
    "sort-ascending": F4BC,
    "sort-descending": F4BD,
    "sort-numeric": F4BE,
    "sort-variant": F4BF,
    "soundcloud": F4C0,
    "source-branch": F62C,
    "source-commit": F717,
    "source-commit-end": F718,
    "source-commit-end-local": F719,
    "source-commit-local": F71A,
    "source-commit-next-local": F71B,
    "source-commit-start": F71C,
    "source-commit-start-next-local": F71D,
    "source-fork": F4C1,
    "source-merge": F62D,
    "source-pull": F4C2,
    "speaker": F4C3,
    "speaker-off": F4C4,
    "speaker-wireless": F71E,
    "speedometer": F4C5,
    "spellcheck": F4C6,
    "spotify": F4C7,
    "spotlight": F4C8,
    "spotlight-beam": F4C9,
    "spray": F665,
    "square": F763,
    "square-inc": F4CA,
    "square-inc-cash": F4CB,
    "square-outline": F762,
    "square-root": F783,
    "stackexchange": F60B,
    "stackoverflow": F4CC,
    "stadium": F71F,
    "stairs": F4CD,
    "star": F4CE,
    "star-circle": F4CF,
    "star-half": F4D0,
    "star-off": F4D1,
    "star-outline": F4D2,
    "steam": F4D3,
    "steering": F4D4,
    "step-backward": F4D5,
    "step-backward-2": F4D6,
    "step-forward": F4D7,
    "step-forward-2": F4D8,
    "stethoscope": F4D9,
    "sticker": F5D0,
    "sticker-emoji": F784,
    "stocking": F4DA,
    "stop": F4DB,
    "stop-circle": F666,
    "stop-circle-outline": F667,
    "store": F4DC,
    "store-24-hour": F4DD,
    "stove": F4DE,
    "subdirectory-arrow-left": F60C,
    "subdirectory-arrow-right": F60D,
    "subway": F6AB,
    "subway-variant": F4DF,
    "summit": F785,
    "sunglasses": F4E0,
    "surround-sound": F5C5,
    "svg": F720,
    "swap-horizontal": F4E1,
    "swap-vertical": F4E2,
    "swim": F4E3,
    "switch": F4E4,
    "sword": F4E5,
    "sword-cross": F786,
    "sync": F4E6,
    "sync-alert": F4E7,
    "sync-off": F4E8,
    "tab": F4E9,
    "tab-plus": F75B,
    "tab-unselected": F4EA,
    "table": F4EB,
    "table-column-plus-after": F4EC,
    "table-column-plus-before": F4ED,
    "table-column-remove": F4EE,
    "table-column-width": F4EF,
    "table-edit": F4F0,
    "table-large": F4F1,
    "table-row-height": F4F2,
    "table-row-plus-after": F4F3,
    "table-row-plus-before": F4F4,
    "table-row-remove": F4F5,
    "tablet": F4F6,
    "tablet-android": F4F7,
    "tablet-ipad": F4F8,
    "taco": F761,
    "tag": F4F9,
    "tag-faces": F4FA,
    "tag-heart": F68A,
    "tag-multiple": F4FB,
    "tag-outline": F4FC,
    "tag-plus": F721,
    "tag-remove": F722,
    "tag-text-outline": F4FD,
    "target": F4FE,
    "taxi": F4FF,
    "teamviewer": F500,
    "telegram": F501,
    "television": F502,
    "television-guide": F503,
    "temperature-celsius": F504,
    "temperature-fahrenheit": F505,
    "temperature-kelvin": F506,
    "tennis": F507,
    "tent": F508,
    "terrain": F509,
    "test-tube": F668,
    "text-shadow": F669,
    "text-to-speech": F50A,
    "text-to-speech-off": F50B,
    "textbox": F60E,
    "texture": F50C,
    "theater": F50D,
    "theme-light-dark": F50E,
    "thermometer": F50F,
    "thermometer-lines": F510,
    "thumb-down": F511,
    "thumb-down-outline": F512,
    "thumb-up": F513,
    "thumb-up-outline": F514,
    "thumbs-up-down": F515,
    "ticket": F516,
    "ticket-account": F517,
    "ticket-confirmation": F518,
    "ticket-percent": F723,
    "tie": F519,
    "tilde": F724,
    "timelapse": F51A,
    "timer": F51B,
    "timer-10": F51C,
    "timer-3": F51D,
    "timer-off": F51E,
    "timer-sand": F51F,
    "timer-sand-empty": F6AC,
    "timer-sand-full": F78B,
    "timetable": F520,
    "toggle-switch": F521,
    "toggle-switch-off": F522,
    "tooltip": F523,
    "tooltip-edit": F524,
    "tooltip-image": F525,
    "tooltip-outline": F526,
    "tooltip-outline-plus": F527,
    "tooltip-text": F528,
    "tooth": F529,
    "tor": F52A,
    "tower-beach": F680,
    "tower-fire": F681,
    "traffic-light": F52B,
    "train": F52C,
    "tram": F52D,
    "transcribe": F52E,
    "transcribe-close": F52F,
    "transfer": F530,
    "transit-transfer": F6AD,
    "translate": F5CA,
    "treasure-chest": F725,
    "tree": F531,
    "trello": F532,
    "trending-down": F533,
    "trending-neutral": F534,
    "trending-up": F535,
    "triangle": F536,
    "triangle-outline": F537,
    "trophy": F538,
    "trophy-award": F539,
    "trophy-outline": F53A,
    "trophy-variant": F53B,
    "trophy-variant-outline": F53C,
    "truck": F53D,
    "truck-delivery": F53E,
    "truck-fast": F787,
    "truck-trailer": F726,
    "tshirt-crew": F53F,
    "tshirt-v": F540,
    "tumblr": F541,
    "tumblr-reblog": F542,
    "tune": F62E,
    "tune-vertical": F66A,
    "twitch": F543,
    "twitter": F544,
    "twitter-box": F545,
    "twitter-circle": F546,
    "twitter-retweet": F547,
    "uber": F748,
    "ubuntu": F548,
    "umbraco": F549,
    "umbrella": F54A,
    "umbrella-outline": F54B,
    "undo": F54C,
    "undo-variant": F54D,
    "unfold-less-horizontal": F54E,
    "unfold-less-vertical": F75F,
    "unfold-more-horizontal": F54F,
    "unfold-more-vertical": F760,
    "ungroup": F550,
    "unity": F6AE,
    "untappd": F551,
    "update": F6AF,
    "upload": F552,
    "upload-network": F6F5,
    "usb": F553,
    "vector-arrange-above": F554,
    "vector-arrange-below": F555,
    "vector-circle": F556,
    "vector-circle-variant": F557,
    "vector-combine": F558,
    "vector-curve": F559,
    "vector-difference": F55A,
    "vector-difference-ab": F55B,
    "vector-difference-ba": F55C,
    "vector-intersection": F55D,
    "vector-line": F55E,
    "vector-point": F55F,
    "vector-polygon": F560,
    "vector-polyline": F561,
    "vector-radius": F749,
    "vector-rectangle": F5C6,
    "vector-selection": F562,
    "vector-square": F001,
    "vector-triangle": F563,
    "vector-union": F564,
    "verified": F565,
    "vibrate": F566,
    "video": F567,
    "video-off": F568,
    "video-switch": F569,
    "view-agenda": F56A,
    "view-array": F56B,
    "view-carousel": F56C,
    "view-column": F56D,
    "view-dashboard": F56E,
    "view-day": F56F,
    "view-grid": F570,
    "view-headline": F571,
    "view-list": F572,
    "view-module": F573,
    "view-parallel": F727,
    "view-quilt": F574,
    "view-sequential": F728,
    "view-stream": F575,
    "view-week": F576,
    "vimeo": F577,
    "vine": F578,
    "violin": F60F,
    "visualstudio": F610,
    "vk": F579,
    "vk-box": F57A,
    "vk-circle": F57B,
    "vlc": F57C,
    "voice": F5CB,
    "voicemail": F57D,
    "volume-high": F57E,
    "volume-low": F57F,
    "volume-medium": F580,
    "volume-minus": F75D,
    "volume-mute": F75E,
    "volume-off": F581,
    "volume-plus": F75C,
    "vpn": F582,
    "walk": F583,
    "wallet": F584,
    "wallet-giftcard": F585,
    "wallet-membership": F586,
    "wallet-travel": F587,
    "wan": F588,
    "washing-machine": F729,
    "watch": F589,
    "watch-export": F58A,
    "watch-import": F58B,
    "watch-vibrate": F6B0,
    "water": F58C,
    "water-off": F58D,
    "water-percent": F58E,
    "water-pump": F58F,
    "watermark": F612,
    "waves": F78C,
    "weather-cloudy": F590,
    "weather-fog": F591,
    "weather-hail": F592,
    "weather-lightning": F593,
    "weather-lightning-rainy": F67D,
    "weather-night": F594,
    "weather-partlycloudy": F595,
    "weather-pouring": F596,
    "weather-rainy": F597,
    "weather-snowy": F598,
    "weather-snowy-rainy": F67E,
    "weather-sunny": F599,
    "weather-sunset": F59A,
    "weather-sunset-down": F59B,
    "weather-sunset-up": F59C,
    "weather-windy": F59D,
    "weather-windy-variant": F59E,
    "web": F59F,
    "webcam": F5A0,
    "webhook": F62F,
    "webpack": F72A,
    "wechat": F611,
    "weight": F5A1,
    "weight-kilogram": F5A2,
    "whatsapp": F5A3,
    "wheelchair-accessibility": F5A4,
    "white-balance-auto": F5A5,
    "white-balance-incandescent": F5A6,
    "white-balance-iridescent": F5A7,
    "white-balance-sunny": F5A8,
    "widgets": F72B,
    "wifi": F5A9,
    "wifi-off": F5AA,
    "wii": F5AB,
    "wiiu": F72C,
    "wikipedia": F5AC,
    "window-close": F5AD,
    "window-closed": F5AE,
    "window-maximize": F5AF,
    "window-minimize": F5B0,
    "window-open": F5B1,
    "window-restore": F5B2,
    "windows": F5B3,
    "wordpress": F5B4,
    "worker": F5B5,
    "wrap": F5B6,
    "wrench": F5B7,
    "wunderlist": F5B8,
    "xaml": F673,
    "xbox": F5B9,
    "xbox-controller": F5BA,
    "xbox-controller-battery-alert": F74A,
    "xbox-controller-battery-empty": F74B,
    "xbox-controller-battery-full": F74C,
    "xbox-controller-battery-low": F74D,
    "xbox-controller-battery-medium": F74E,
    "xbox-controller-battery-unknown": F74F,
    "xbox-controller-off": F5BB,
    "xda": F5BC,
    "xing": F5BD,
    "xing-box": F5BE,
    "xing-circle": F5BF,
    "xml": F5C0,
    "yammer": F788,
    "yeast": F5C1,
    "yelp": F5C2,
    "yin-yang": F67F,
    "youtube-play": F5C3,
    "zip-box": F5C4
);