.nav-overlay {
	@include transition(all .5s cubic-bezier(0, 0, .4, .9));
	background-color: $app-overlay-bg;
	height: 100%;
	left: 0;
	opacity: 0;
	position: fixed;
	top: 0;
	visibility: hidden;
	width: 100%;

	&.is-visible {
		opacity: 1;
		visibility: visible;
		z-index: 1;
	}
	&:hover {
		cursor: url('../ornio-nav-app/images/close.png'), url('../ornio-nav-app/images/close.cur'), auto;
	}

	@media (max-width: $app-break-point) {
		display: none;
	}
}