.input-icon {
	background-color: #ffffff;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	border-right: 0;
}

.has-danger{
    .ember-power-select-trigger, .custom-select{
        border-color: $brand-danger;
        color: $brand-danger;
    }
}

/*
.app-order-create {

    .description {
        border: 2px solid $table-border-color;
    }

    .order-detail {
        border-top: solid 1px #EBECEE;
        border-bottom: solid 1px #EBECEE;
        background-color: #FFFCF7;
    }

    .input-group-addon {
        background-color: #ffffff;
    }

    .start-date-input {
        flex-direction: row;
    }

    .input-group-addon, .input-group-btn, .input-group .form-control{
        flex-direction: row;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
    }

    .input-group-addon {
        border-right: 0;
    }

    .duration {
        border-radius: 5px;
    }

    .select-tag {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-position: right 50%;

    }

    select.form-control:not([size]):not([multiple]) {
        height:inherit;
    }



    .md-datepicker-group {
        border: 1px solid rgba(0, 0, 0, 0.15);
    }

    .input-group-btn {
        flex-direction: row;
    }

    .time-options {
        height: inherit !important;
        flex-direction: row;
    }

    .separator {
        border-bottom: 1px solid $table-border-color;
    }

    .mine-vikarer{
        &:hover, &.active {
            border: 1px solid #A3ECDD;
            background-color: #EBFBF8;
            height: 160px;
        }

    }



    .time {
        padding-left:19px;
    }



    .order-user-notified {
        background-color: #ffffff;
        max-width: 600px;
    }

    .dropdown-right {
        left: auto;
        right: 0;
    }

    .collapse-textarea {
        width: 100%;
    }

    .table {
        min-width: 800px;
        font-size:16px;
    }

    .table span {
        line-height: 1;
    }

    .disable-select {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        position:relative;
    }

    .form-inline input, select, select option {
        color: #333 !important;
    }

    .form-inline label {
        display: inherit;
    }

    .shift-types .down-arrow i:after {
        width: 0;
        position: absolute;
        height: 0;
        top: 15px;
        right: 10px;
        z-index: 999;
        padding: 0;
        content: '';
        border-left: .255em solid transparent;
        border-right: .255em solid transparent;
        border-top: 0.55em solid #727272;
    }

    .timer {
        padding-top: 5px;
    }

    .dropdown-item:hover {
        background-color: #fff;
    }

}
*/



.ember-power-select-trigger {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	width: 100%;
}

.user-rate-order {
	display: inline-flex;
	a {
		border-radius: 50%;
		@include size(40px);
		border: 1px solid $table-border-color;
		text-align: center;
		margin-right: 5px;
		&:before{
			line-height: 36px !important;
		}
	}
}
