.__contractor__upload__profile-media-change__7b24d{

    //opacity: 0;
    &.transition-panel {

        animation: showElement 1500ms 0s ease-in;
        @keyframes showElement {
            0% {
                opacity: 0;
                //transform: translateX(-100px);
            }

            100% {
                opacity: 1;
                //transform: translateX(0);
            }
        }
    }

    @include media-breakpoint-up(md) {
        width: 100%;
        margin-left: 0;
    }

    @include media-breakpoint-up(lg) {
        width: 809px;
        margin-left: -143px;
    }

    @include media-breakpoint-up(xl) {
        width: 900px;
        margin-left: -100px;
    }

    @media (min-width: 1366px){
        width: 1024px;
        margin-left: -100px;
    }
    &.is-lg.open, &.is-xl.open{
        width: 100%;
        margin: 0;
    }
}
