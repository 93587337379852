.__cal__month-view__6ca41 {
    .calendar {
        .calendar-header {
            display: flex;
            align-items: center;
            padding: 14px;

            .month {
                margin-bottom: 0;
                font-weight: 400;
                .year {
                    font-weight: 300;
                }
            }

            .calendar-navigation {
                margin-left: 14px;
            }
            i {
                color: #AAA8A8;
                &:hover {
                    color: $primaryColor;
                }
            }
        }

        .weekdays {
            margin-bottom: 0;
            border-top: 1px solid #EBECEE;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            padding: 0;

            li {
                display: flex;
                align-items: center;
                justify-content: start;
                list-style: none;
                margin-left: 0;
                color: #6A6A6A;
                padding-top: 10px;
                padding-bottom: 10px;

                &:first-child {
                    padding-left: 14px;
                }
            }
        }

        .day-grid {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                color: #343333;
            }
        }

        ul.day-grid li {
            min-height: 90px;
            border: 1px solid #EBECEE;

            margin-bottom: -1px;
            margin-right: -1px;

            .day {
                display: block;
                padding: 14px 14px 4px 14px;
            }
        }

        ul.weekdays abbr[title] {
            border: none;
            text-decoration: none;
        }

        //ul.day-grid li:nth-child(1),
        //ul.day-grid li:nth-child(2),
        //ul.day-grid li:nth-child(3),
        //ul.day-grid li:nth-child(34),
        //ul.day-grid li:nth-child(35) {
        //    color: #C2C2C2;
        //}

        ul.day-grid li.month-prev{
            color: #C2C2C2;
        }



        .shifts {
            padding: 14px;

            .shifts-item {
                margin-right: 1rem;
                color: #6A6A6A;
            }
            .sh-green {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $green;
                margin-right: 10px;
            }
            .sh-orange {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $orange;
                margin-right: 10px;
            }
            .sh-red {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $red;
                margin-right: 10px;
            }
        }


        .order {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 2px;
            font-size: 14px;
            font-weight: 500;
            color: $white;
            padding: 2px 8px;
            margin-top: 3px;
            cursor: pointer;
            time {
                font-weight: 400;
            }

            &.is-second-day{
                border-left: 0 none !important;
            }

            // Empty shift space
            &.shift-type-0 {

                border-left: 2px solid transparent;
                background-color: transparent;
                color: transparent;

                &.selected {
                    color: transparent;
                    background-color: transparent;
                }
            }

            // Night shift
            &.shift-type-4 {
                position: relative;
                border-left: 2px solid #F55964;
                background-color: #FFE5E7;
                color: #E14550;

                &.selected {
                    color: white;
                    background-color: #F55964;
                }
            }

            // Day & evning shift
            &.shift-type-2, &.shift-type-3 {
                border-left: 2px solid #F2B134;
                background-color: #FFFAEC;
                color: #D39837;

                &.selected {
                    color: white;
                    background-color: #F2B134;
                }
            }

            // All day shift
            &.shift-type-1 {

                border-left: 2px solid #14CA9C;
                background-color: #D8F9F5;
                color: #15BF95;

                &.selected {
                    color: white;
                    background-color: #14CA9C;
                }
            }

            &.collapsed{
                height: 6px;
                color: transparent;
                &:hover{
                    color: transparent;
                    cursor: pointer;
                }
                &.show{
                    height: auto;
                }
                &.dognvakt {
                    background-color: #14CA9C;
                    &.show{
                        color: white;
                    }
                }
                &.nattevakt {
                    background-color: #F2B134;
                    &.show{
                        color: white;
                    }
                }

            }


        }


        .today {
            background-color: #F6F8FA;
        }
    }
}
