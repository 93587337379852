.__contractor__profile__content-main-default__5a331{
	.profile-content-wraper {
		.course, .also-worked, .user-badges {
			margin: 20px 0;

			p {
				margin: 0;
			}
			ul {
				display: flex;
				flex-direction: row;
				list-style: none;
				margin: 0;
				padding: 30px;

				-webkit-flex-wrap: wrap; /* Safari */
				flex-wrap:         wrap;

			}
			h6 {
				display: inline-block;
				font-weight: normal;
				margin-left: 10px;
				font-size: 1.125rem;
			}
		}

		.course {
			ul {
				@include media-breakpoint-down(md) {
					flex-direction: column;
				}

				padding-bottom: 0;

				li {
					width: 50%;
					margin-bottom: 30px;
					@include media-breakpoint-down(md) {
						width: 100%;
						margin: 10px 0 30px 0;
					}

					h6{
						margin-bottom: 0;
					}

					.nc-icon{
						color: $orange;
					}
				}
			}
		}

		.also-worked {
			ul {
				flex-wrap: wrap;
				@include media-breakpoint-down(md) {
					flex-direction: column;
				}
				li {
					padding: 6px 24px;
					margin: 5px 10px 5px 0;
					background-color: $green;
					border-radius: 50px;
					color: white;
				}
			}
		}

		.user-badges {
			ul {
				padding: 18px 18px;

				@include media-breakpoint-down(md) {
					flex-direction: column;
				}

				.user-badge-item {
					display: flex;
					align-items: center;
					width: 50%;

					@include media-breakpoint-down(md) {
						width: 100%;
					}

					.badge-bg{
						width: 100%;
						padding: 24px;
						margin: 12px 12px;
						background: url(/assets/images/icons/badges-bg.svg) #F6F8FA no-repeat right;

						@include media-breakpoint-down(md) {
							margin: 10px 0;
						}
					}

					.badge-title {
						color: $black;
						padding-left: 14px;
						margin: 0;
					}

					h6{
						margin-bottom: 0;
					}

					.nc-icon{
						color: $orange;
					}
				}
			}
		}
	}

}