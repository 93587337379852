$accent-color: #e040fb !default;
$disabled-textfield-grey: #9e9e9e !default;
$divider-color: #c2c2c2 !default;
$datepicker-background-color: #fff !default;
$text-on-accent-color: #fff !default;
$invalid-red: #f44336 !default;
$month-selector-color: #757575 !default;
$datepicker-main-text-color: #383838 !default;
$datepicker-disabled-text-color: #CCCCCC !default;
$box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !default;
$font: Roboto, sans-serif !default;
$font-form: 14px !default;
$font-small: 12px !default;

.md-datepicker-group {
  margin: 15px 0;
  position: relative;
  box-sizing: border-box;
  font-family: $font;

  input::-ms-clear {
    display: none;
  }

  input {
    font-size: $font-form;
    font-family: $font;
    box-sizing: border-box;
    background: none;
    border: 0;
    border-bottom: 1px solid $accent-color;
    padding: 10px 10px 5px 5px;
    display:block;
    width: 100%;
    height: 33px;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
    }

    &:disabled {
      color: $disabled-textfield-grey;
      border-bottom-color: $disabled-textfield-grey;
      cursor: not-allowed;
    }

    ~ .datepicker-error {
      color: $invalid-red;
      font-size: $font-small;
      padding: 10px 0 5px 5px;
    }

    ~ .bar {
      position: relative;
      display: block;

      &:before {
        content: '';
        height: 2px;
        width: 0;
        bottom: 0;
        position: absolute;
        background: $accent-color;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
      }
    }

    ~ label {
      position: absolute;
      color: $divider-color;
      font-size: $font-form;
      left: 5px;
      top: 10px;
      pointer-events:none;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    &:disabled  {
      & ~ label {
        color: $disabled-textfield-grey;
      }

      & ~ .down-arrow {
        cursor: not-allowed;

        i {
          &:after {
            border-top: 0.55em solid $disabled-textfield-grey;
          }
        }
      }
    }

    &:focus {
      outline: 0;

      ~ .bar:before {
        width: 100%;
      }

      ~ .datepicker-container {
        .datepicker-inner {
          display: block;
          min-height: 440px;
        }
      }
    }

    &:focus ~ label, ~ .text-present {
      top: -11px;
      font-size: $font-small;
      color: $accent-color;
    }

    &.invalid, &:disabled.invalid, &.isDirty:required.invalid, &:disabled.isDirty:required, &:disabled.isDirty.validate {
      border-bottom: 1px solid $invalid-red;

      ~ .bar {
        &:before {
          background: $invalid-red;
        }
      }

      ~ label {
        color: $invalid-red;
      }
    }
  }

  .down-arrow {
    cursor: pointer;
    background: none;
    min-width: 0;
    height: 10px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 9px;
    top: 22px;
    border: 0;
    box-shadow: none;

    i {
      &:after {
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: .255em solid transparent;
        border-right: .255em solid transparent;
        border-top: 0.55em solid #727272;
      }
    }
  }

  .datepicker-container {
    position: absolute;
    z-index: 100;
    width: 311px;

    &.drop-up {
      bottom: 50px;
    }
    &.drop-down {
      top: 40px;
    }

    p {
      margin: 0;
    }

    &.error-message-present {
      top: 60px;
    }

    .datepicker-inner {
      display: none;
      background-color: $datepicker-background-color;
      border-radius: 0 0 3px 3px;
      margin: 0;
      box-shadow: $box-shadow;

      .datepicker-head {
        height: 95px;
        background-color: $accent-color;
        color: $text-on-accent-color;
        border-radius: 3px 3px 0 0;
        padding: 18px 21px 0 21px;

        .head-time {
          font-size: 14px;
          float: right;
        }

        .head-year {
          font-size: 14px;
          margin-bottom: 6px;
        }

        .head-day-month {
          font-size: 32px;
          font-weight: 500;
        }
      }

      .datepicker-main {
        padding: 0 8px;

        span {
          font-size: 12px;
          width: 42px;
        }

        .month-select {
          display: flex;
          height: 48px;
          margin-bottom: 15px;

          .month-toggle {
            cursor: pointer;
            text-decoration: none;
            margin: 0;
            border: 0;
            padding: 17px 6px 6px 6px;
            position: relative;
            outline: none;
            background: none;

            svg {
              display: inline-block;
              color: $month-selector-color;
              fill: currentcolor;
              height: 24px;
              width: 24px;
              transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
              -webkit-user-select: none;
            }
          }

          .selected-month-year {
            height: 100%;
            width: 100%;
            position: relative;
            text-align: center;
            color: $datepicker-main-text-color;
            padding-top: 20px;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .day-header {
          display: flex;
          height: 20px;
          margin-bottom: 6px;

          span {
            opacity: 0.5;
            text-align: center;
          }
        }

        .day-select {
          padding-bottom: 10px;

          .week {
            display: flex;
            height: 41px;
            text-align: center;

            .blank-day {
              width: 42px;
            }

            .btn-date {
              cursor: pointer;
              height: 34px;
              text-decoration: none;
              margin: 0;
              border: 0;
              position: relative;
              outline: none;
              background: none;
              padding: 4px 0;
              width: 42px;

              .date-circle {
                height: 34px;
                width: 34px;
                position: absolute;
                top: 0;
                left: 4px;
                opacity: 0;
                border-radius: 50%;
                background-color: $accent-color;
                transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
                transform: scale(0);
              }
              span {
                position: relative;
                color: $datepicker-main-text-color;
                line-height: 29px;

                &.today {
                  color: $accent-color;
                }
              }

              &:hover {
                .date-circle {
                  opacity: 0.6;
                  transform: scale(1);
                }

                span {
                  color: $text-on-accent-color;
                }
              }

              &.selected {
                .date-circle {
                  opacity: 1;
                  transform: scale(1);
                }

                span {
                  color: $text-on-accent-color;
                }
              }

              &.disabled {
                cursor: not-allowed;

                span {
                  color: $datepicker-disabled-text-color;
                }

                &:hover {
                  .date-circle {
                    opacity: 0;
                    transform: scale(0);
                  }

                  span {
                    color: $datepicker-disabled-text-color;
                  }
                }

                &.selected {
                  span {
                    color: $text-on-accent-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
