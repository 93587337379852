.__map__contractee-list__e1326{
	height: $map-height-contractors;
	width: 100%;
	margin-bottom: 4rem;
	.g-map-canvas {
		width: 100%;
		height: $map-height-contractors;
	}

	.g-map{
		height: $map-height-contractors;
		width: 100%;
	}
	.g-map-canvas {
		width: 100%;
		height: $map-height-contractors;
	}
}