.__admin__contractor-item__85b71 {
	background: #fff;
	@include media-breakpoint-down(lg) {
		width: 100%;
	}
	&.active{
		border: 1px solid #A3ECDD;
	}
	.more{
		position: absolute;
		right: 20px;
		top: 20px;
	}
	.user-image {
		position: relative;
		@include media-breakpoint-down(lg) {
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			width: 100%;
		}
		@include media-breakpoint-up(xl) {
			width: $sidenav-width;
		}
	}

	.user-video{
		position: absolute;
		left: 10px;
		bottom: 10px;
		padding: 0;
		margin: 0;
		@include size(40px);
		i{
			color: $white;
		}
	}

	.user-content {
		position: relative;
		width: 100%;
		@include media-breakpoint-up(xs) {
			padding: 20px 25px;
		}
		@include media-breakpoint-up(md) {
			padding: 20px 11px;
		}
		@include media-breakpoint-up(lg) {
			padding: 15px 11px;
		}
		@include media-breakpoint-up(xl) {
			padding: 20px 11px;
		}
		.select_user {
			position: absolute;
			right: 25px;
			top: 17px;
			width: 17px;
		}
		.row{
			width: 100%;
		}

		.progress-bar {
			height: 4px;
		}

		.progress {
			background-color: #EBECEE;
		}

	}

	.user-skills{
		font-size: 80%;
		@include media-breakpoint-down(lg) {
			margin-bottom: 2rem !important;
		}
	}
}

