.__contractee__settings__contractee-avatar__f5e6a {
	//opacity: 0;
	&.transition-panel {

		animation: showElement 1500ms 0s ease-in;
		@keyframes showElement {
			0% {
				opacity: 0;
				//transform: translateX(-100px);
			}

			100% {
				opacity: 1;
				//transform: translateX(0);
			}
		}
	}
	.avatar-settings {
		border: 2px dashed #eaeaea;
		width: 100%;
		height: 320px;
	}

	.avatar-container {
		height: 320px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}