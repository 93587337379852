$vacancy-gray-500: #3d4858; //Headings
$vacancy-gray-400: #8492a6; //paragraphs
$vacancy-gray-300: #d3dce6; //icons
$vacancy-gray-200: #e0e6ed; //icons
$vacancy-gray-100: #f9fafc; //bg

$vacancy-standard-color: #f2b134;
$vacancy-premium-color: #4b8afb;
$vacancy-mdi-color: $vacancy-gray-300;

$vacancy-gray-light-color: #8492a6;
$vacancy-border-gray-light-color: #e0e6ed;
$vacancy-wizard-price-font-family: 'Merriweather', serif;

$vacancy-font-family: 'Avenir Next';
$vacancy-font-ext: 'ttf';
$vacancy-font-path-regular: '/assets/fonts/avenir-next/AvenirNext-Regular';
$vacancy-font-path-medium: '/assets/fonts/avenir-next/AvenirNext-Medium';
$vacancy-font-path-bold: '/assets/fonts/avenir-next/AvenirNext-Bold';

$shadow-action-length: 3px 3px 10px 0px;
$shadow-action-length2: -3px -3px 10px 0px;

.vacancy-wizard-shadow {
    -webkit-box-shadow: 8px 8px 30px 0px rgba(61, 72, 88, 0.06);
    -moz-box-shadow: 8px 8px 30px 0px rgba(61, 72, 88, 0.06);
    box-shadow: 8px 8px 30px 0px rgba(61, 72, 88, 0.06);
}

@include font-face($vacancy-font-family, $vacancy-font-path-regular, 400, normal, ttf);
@include font-face($vacancy-font-family, $vacancy-font-path-medium, 500, normal, ttf);
@include font-face($vacancy-font-family, $vacancy-font-path-bold, 700, normal, ttf);

//.vacancy-wizard-container {
//    @media (min-width: 1200px) {
//        .container {
//            max-width: 1140px;
//        }
//    }
//
//}

.vacancy-wizard {
    font-family: $vacancy-font-family;
    .vacancy-wizard-step {
        position: relative;
        .vacancy-wizard-header {
            margin-bottom: 50px;
            text-align: center;
            .vacancy-wizard-header-title {
                font-size: 40px;
                color: $vacancy-gray-500;
                font-weight: 500;
                margin-bottom: 1rem;
            }
            .vacancy-wizard-header-description {
                color: $vacancy-gray-500;
                font-size: 22px;
                font-weight: 400;
            }
        }
    }
    .vacancy-wizard-step_5 {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 130px 45px 45px;
        margin: 0 -50px;

        @include media-breakpoint-down(md) {
            margin: 100px 0 0;
        }

        .vacancy-wizard-header {
            .vacancy-wizard-header-title {
                font-weight: 500;
            }
            .vacancy-wizard-header-description {
                line-height: 1.45;
                font-weight: 300;
            }
        }
        .vacancy-wizard-image-header {
            @include horizontal-align(absolute);
            top: -60px;
        }
        .vacancy-wizard-plan-details {
            .vacancy-wizard-plan-title {
                color: $vacancy-gray-light-color;
                margin-bottom: 10px;
                display: block;
            }
            .vacancy-wizard-plan-list {
                list-style: none;
                padding: 0;
                li {
                    padding: 20px 0;
                    border-bottom: 1px solid $vacancy-border-gray-light-color;
                    &:first-child {
                        border-top: 1px solid $vacancy-border-gray-light-color;
                    }
                    .vacancy-wizard-plan-list-check {
                        margin-right: 20px;

                        svg {
                            fill: $brand-primary;
                            path {
                                fill: $brand-primary;
                            }
                        }
                    }
                    .vacancy-wizard-plan-list-price {
                        float: right;
                        font-weight: 500;
                        font-size: 1.25rem;
                        font-family: $vacancy-wizard-price-font-family;
                    }
                }
            }
        }
        .vacancy-wizard-plan-total {
            width: 100%;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            margin: 30px 0;
            .vacancy-wizard-plan-total-name {

            }
            .vacancy-wizard-plan-total-price {
                font-family: $vacancy-wizard-price-font-family;
            }
            .vacancy-wizard-plan-total-note {
                font-size: 80%;
                font-weight: 400;
            }
        }
        .vacancy-wizard-plan-action {
            width: 100%;
            display: flex;
            align-items: flex-end;
            flex-direction: column;

        }
    }
    .vacancy-wizard-step_3 {
        text-align: left;
        width: 100%;
        .vacancy-wizard-features {
            width: 100%;
            margin-bottom: 30px;
            position: relative;
            .vacancy-wizard-features-select-extra-feature {
                position: relative;
                text-align: right;
                margin-bottom: 20px;
                margin-right: 25px;
                color: $vacancy-gray-light-color;
                font-weight: 500;
                @include media-breakpoint-down(md) {
                    display: none;
                }
                .vacancy-wizard-features-select-extra-feature-image {
                    background: transparent url("../assets/images/features/arrow-select.svg") 0 0;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    width: 72px;
                    height: 78px;
                    position: absolute;
                    right: -78px;
                    top: 10px;

                }
            }
            .vacancy-wizard-features-item {
                display: flex;
                flex: 1;
                margin-bottom: 20px;
                padding: 25px 25px 25px 0;

                @extend .bg-white;
                @include transition($transition-base);

                @include media-breakpoint-down(md) {
                    padding: 25px;
                    flex-direction: column;
                    align-items: center;
                    align-content: center;
                }
                &:hover {
                    cursor: pointer;
                    box-sizing: border-box;
                    -webkit-box-shadow: $shadow-action-length rgba($vacancy-standard-color, 0.34), $shadow-action-length2 rgba($vacancy-standard-color, 0.34);
                    box-shadow: $shadow-action-length rgba($vacancy-standard-color, 0.34), $shadow-action-length2 rgba($vacancy-standard-color, 0.34);
                    @include transition($transition-base);
                }
                .vacancy-wizard-features-item-image {
                    width: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    pointer-events: none;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 20px;
                    }
                }
                .vacancy-wizard-features-item-content {
                    width: 100%;
                    pointer-events: none;

                    @include media-breakpoint-down(md) {
                        text-align: center;
                        margin-bottom: 20px;
                    }
                    .vacancy-wizard-features-item-price {
                        color: $brand-primary;
                        font-family: $vacancy-wizard-price-font-family;
                        &.nok {
                            &:after {
                                margin-left: 0;
                            }
                        }
                    }
                    .vacancy-wizard-features-item-description {
                        color: $vacancy-gray-light-color;
                    }
                }
                .vacancy-wizard-plans-plan_feature-item-check {

                }
            }
        }
        .vacancy-wizard-total {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 20px;
            .vacancy-wizard-total-description {
                color: $vacancy-gray-light-color;
                text-transform: uppercase;
                font-size: 16px;
                line-height: 2.88;
                margin-right: 20px;
            }
            .vacancy-wizard-total-price {
                font-family: $vacancy-wizard-price-font-family;
                font-size: 28px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.64;
                letter-spacing: -0.6px;
            }

        }
        .vacancy-wizard-action {
            display: flex;
            justify-content: flex-end;
            .vacancy-wizard-action-button {
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                font-weight: 500;
                text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.05);
            }
        }
    }
    .vacancy-wizard-step_2 {
        .vacancy-wizard-services {
            display: flex;
            margin: 0 -10px 80px;
            align-items: baseline;
            .vacancy-wizard-service-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 10px;
                padding: 0 10px;
                .vacancy-wizard-service-item-image {
                    margin-bottom: 25px;
                }
                .vacancy-wizard-service-item-description {
                    text-align: center;
                    color: $vacancy-gray-500;
                }
            }
        }
        .vacancy-wizard-plans {
            display: flex;
            margin: 0 -10px 80px;
            @include media-breakpoint-down(lg) {
                flex-direction: column;
                margin-bottom: 10px;
            }
            .vacancy-wizard-plans-plan {
                flex: 1;
                margin: 0 10px;
                padding: 45px;
                background-color: #e2e2e2;
                display: flex;
                flex-direction: column;
                position: relative;
                @extend .vacancy-wizard-shadow;

                @include media-breakpoint-down(lg) {
                    margin-bottom: 2rem;
                }
                &:hover {
                    //cursor: pointer;
                    .vacancy-wizard-plans-plan-bottom {
                        @include transition($transition-base);
                        opacity: 1;
                    }

                }

                &.vacancy-wizard-plans-plan-standard {
                    .vacancy-wizard-plans-plan-header {
                        .vacancy-wizard-plans-plan-price {
                            color: $vacancy-standard-color;
                        }
                    }
                    .vacancy-wizard-plans-plan-bottom {
                        background: $vacancy-standard-color;
                    }

                    .vacancy-wizard-plans-plan_features {
                        .vacancy-wizard-plans-plan_feature-item {

                            .vacancy-wizard-plans-plan_feature-item-check {
                                svg {
                                    fill: $vacancy-standard-color;
                                    path {
                                        fill: $vacancy-standard-color;
                                    }
                                }
                            }
                        }
                    }

                }
                &.vacancy-wizard-plans-plan-premium {
                    .vacancy-wizard-plans-plan-header {
                        .vacancy-wizard-plans-plan-price {
                            color: $vacancy-premium-color;
                        }
                    }
                    .vacancy-wizard-plans-plan-bottom {
                        background: $vacancy-premium-color;
                    }

                    .vacancy-wizard-plans-plan_features {
                        .vacancy-wizard-plans-plan_feature-item {

                            .vacancy-wizard-plans-plan_feature-item-check {
                                svg {
                                    fill: $vacancy-premium-color;
                                    path {
                                        fill: $vacancy-premium-color;
                                    }
                                }
                            }
                        }
                    }
                }
                .vacancy-wizard-plans-plan-header {
                    text-align: center;
                    .vacancy-wizard-plans-plan-free {
                        color: $vacancy-standard-color;
                        font-weight: 500;
                        font-size: 20px;
                    }
                    .vacancy-wizard-plans-plan-name {
                        color: $vacancy-gray-light-color;
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 22px;
                        letter-spacing: 2px;
                    }
                    .vacancy-wizard-plans-plan-price {
                        margin-bottom: 14px;
                        font-family: $vacancy-wizard-price-font-family;
                        font-weight: 700;
                        &.cross {
                            text-decoration-line: line-through;
                            text-decoration-style: solid;
                            text-decoration-color: darken($vacancy-standard-color, 20%);
                        }
                        &.nok {
                            &:after {
                                margin-left: -16px;
                            }
                        }
                    }
                    .vacancy-wizard-plans-plan-description {
                        margin-bottom: 34px;
                        margin-right: 16px;
                        font-size: 20px;
                        font-weight: 400;
                        color: $vacancy-gray-500;
                    }
                }
                .vacancy-wizard-plans-plan_features {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 45px;

                    justify-content: center;
                    align-items: flex-start;

                    .vacancy-wizard-plans-plan_feature-item {
                        margin-bottom: 4px;
                        display: inline-flex;

                        .vacancy-wizard-plans-plan_feature-item-check {
                            margin-right: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .vacancy-wizard-plans-plan_feature-item-name {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: $vacancy-gray-500;
                        }
                        .vacancy-wizard-plans-plan_feature-item-image {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .vacancy-wizard-plans-plan_feature-item-description {
                            margin-left: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .vacancy-wizard-plans-plan_feature-item-description-icon {
                                @extend .mdi-24px;
                                &:before {
                                    content: mdi('help-circle-outline');
                                    @include transition($transition-base);
                                    color: $vacancy-mdi-color;
                                }
                                &:hover {
                                    cursor: pointer;
                                    &:before {
                                        content: mdi('help-circle');
                                        @include transition($transition-base);
                                        color: $vacancy-mdi-color;
                                    }
                                }
                            }
                        }
                    }
                }
                .vacancy-wizard-plans-action {
                    .vacancy-wizard-plans-action-button {
                        @extend .btn-block;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        border-radius: 5px;
                        line-height: 0.71;
                        color: #fff;
                        letter-spacing: normal;
                        font-weight: 600;
                        height: 50px;
                        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.05);
                    }
                }
                .vacancy-wizard-plans-plan-bottom {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 2px;
                    width: 100%;
                    opacity: 0;
                    @include transition($transition-base);

                }
            }
        }

        .vacancy-wizard-contact {
            text-align: center;
            .vacancy-wizard-contact-title {
                //font-family: normal;
                margin-bottom: 40px;
                font-size: 28px;
                font-weight: 300;
                line-height: 1.7;
            }
            .vacancy-wizard-contact-button {
                background-color: transparent;;
                border: $vacancy-mdi-color 2px solid;
                color: #3d4858;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 80%;
                line-height: 1.6;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
            }
        }
    }
}

.btn-standard {
    background-color: $vacancy-standard-color;
}

.btn-premium {
    background-color: $vacancy-premium-color;
}

.nok {
    &:after {
        content: ',-';
        //margin-left: -16px;
    }
}
