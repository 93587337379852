.__order-list__order-shift-hours__b0980 {
	.items{

		@include media-breakpoint-up(lg) {
			display: block;
			//-webkit-box-pack: justify;
			//-webkit-justify-content: space-between;
			//-ms-flex-pack: justify;
			//justify-content: space-between;
			.item{
				display: block;
			}
		}

		@include media-breakpoint-down(md) {
			display: block;
			//-webkit-box-pack: justify;
			//-webkit-justify-content: space-between;
			//-ms-flex-pack: justify;
			//justify-content: space-between;
			.item{
				display: block;
				//-webkit-box-align: center;
				//-webkit-align-items: center;
				//-ms-flex-align: center;
				//align-items: center;
			}
		}

	}

}