.__contractor__upload__user-avatar__7a79a {
    .avatar-settings {
        border: 2px dashed #eaeaea;
        width: 100%;
        height: 320px;
    }

    .avatar-container {
        height: 320px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}