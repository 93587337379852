.__contractee__settings__main-category__6a90d {
  //opacity: 0;
  &.transition-panel {

    animation: showElement 1000ms 0s ease-in;
    @keyframes showElement {
      0% {
        opacity: 0;
        //transform: translateX(-100px);
      }

      100% {
        opacity: 1;
        //transform: translateX(0);
      }
    }
  }
}