.app-sidenav {
	color: $white;

	i{
		color: $sidenav-icon-color;
	}
	.card {
		background-color: transparent;
		border: none;
		border-left: 0 solid $white;
        position: relative;

		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;

        &:before {
            content: '';
            width: 0;
            background: #ffffff;
            transition: width .3s;
            display: list-item;
            position: absolute;
            height: 100%;
        }

        &:hover::before, &.active::before {
            width: 4px;
        }

        &:after {
            position: absolute;
            top: 20px;
            right: 30px;
        }

	}
	.card-header {
		background-color: transparent;
		border: none;
		border-radius: 0;
		padding: 0;
		a{
			color: #fff;
			h5{
				font-size: 1rem;
				font-weight: 500;
				color: #fff;
			}
			&:after{
				right: 30px;
				top: 21px;
				border-top: 5px solid #fff;
			}
		}
		@include hover-focus {
			text-decoration: none;
			> * {
				text-decoration: none;
			}

			a{
				text-decoration: none;
			}

            background-color: $brand-secondary;
		}

	}
	.card-block{
		padding: 0;
		a{
			padding-left: 3.5rem;
			font-weight: 400;
			@include hover-focus {
				text-decoration: none;
				color: $sidenav-icon-color;
			}
		}
	}
	a[aria-expanded="true"] {
		background-color: $brand-secondary;
	}
	&.false{
		a{
			padding: 0.35rem 24px;
			width: 80px;
		}
	}
	a {
		color: $white;
		display: flex;
		padding: 13px 28px;
		height: 47px;
		font-weight: 500;
		@include hover-focus {
			text-decoration: none;
			color: $sidenav-icon-color;
		}
	}

}
