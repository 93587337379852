.__order-list__container-item__e1bb5 {
	margin-bottom: 1rem;
	border-bottom: 1px solid $table-border-color;
	&:last-child {
		border-bottom: none;
		margin-bottom: 0;
	}
	&:after {
		@extend .clearfix;
	}
}