.subnav-content {
	height: calc(100% - 160px);
	overflow-y: auto;
}

.subnav-injected-content {
	ul {
		//height: 372px;
		overflow-y: auto;
		padding: 20px;
		li {
			width: 100%;
			padding: 10px 0;
			a {
				color: $app-brand-gray-dark;
				cursor: pointer;
				padding: 5px 10px;
				text-decoration: none;
				width: 100%;
				font-weight: 500;
				font-size: 1rem;
				&.active {
					background-color: $app-brand-yellow-lighter;
					color: $app-brand-gray-dark;
					text-decoration: none;
				}
			}
		}
	}
}

.app-btn-block {
	display: block;
	width: 100%;
}

.app-btn-primary {
	background-color: $app-brand-primary;
	border-color: $app-brand-primary;
	color: $app-brand-white;
	&:hover {
		background-color: rgba($app-brand-primary, .7);
		color: $app-brand-white;
	}
}

.app-btn {
	border: 1px solid transparent;
	border-radius: .25rem;
	display: inline-block;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.25;
	padding: .5rem 1rem;
	text-align: center;
	transition: all .2s ease-in-out;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	&:focus,
	&:hover,
	&:active {
		outline-width: 0;
		text-decoration: none;
		cursor: pointer;
	}
}

a {
	&:focus,
	&:hover,
	&:active {
		outline-width: 0;
		text-decoration: none;
	}
}
