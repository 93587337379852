.__contractor__card__display-skills-inline__e1739 {
    &.minimal {
        @extend .mt-2;
        float: left;
        &.profession {
            float: right;
            @include media-breakpoint-down(lg) {
                float: left;
            }
        }
    }
    .skill-item {
        display: flex;
        align-items: center;
    }
}
