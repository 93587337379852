.font-weight-light{
    font-weight: 300;
}

.text-normal {
    text-transform: none !important;
}

.font-weight-normal {
    font-weight: 400;
}
.font-weight-medium {
    font-weight: 500;
}

.font-weight-bold {
    font-weight: 500;
}

h1, h2, h3, h4, h5, h6{
    color: $gray-dark;
}

.break-word{
    word-break: break-all;
    line-height: 21px;
}

.text-sm {
    font-size: $font-size-sm;
}

$sizes: 18 24 36 48;
@each $size in $sizes {
    .#{$mdi-css-prefix}-#{$size}px {
        &.#{$mdi-css-prefix}-set,
        &.#{$mdi-css-prefix}:before {
            line-height: $size * 1px;
        }
    }
}
hr{
    width: 100%;
}
