.pagination-wrapper{
	.pagination{
		@extend .pagination;
		margin-bottom: 0;
		li{
			@extend .page-item;
			a{
				@extend .page-link;
			}
		}
	}
}