.__admin__assignment__assignment-details__0ae5f {
	.divider{
		width: 100%;
		height: 1px;
		background: #EBECEE;
	}

	.mdi{
		margin-right: 0.5rem;
		color: #343333;
	}



}