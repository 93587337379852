.app-power-select{
	outline: none !important;

	.ember-power-select-trigger {
		outline: none !important;
		width:100%;
	}

	.ember-power-select-trigger-multiple-input{
		outline: none !important;
		padding: 4px 0 4px 5px;
		line-height: 33px !important;
	}

	.ember-power-select-multiple-remove-btn, .ember-power-select-clear-btn{
		color: #F7A733;
		font-size: 26px;
		float: right;
		line-height: 31px;
		margin-left: 5px;
	}
	.ember-power-select-clear-btn{
		line-height: 38px;
	}

	&.with-search-icon{
		.ember-power-select-multiple-trigger{
			padding-left: 36px !important;
		}


		.ember-power-select-selected-item, .ember-power-select-placeholder {
			margin-left: 11px;
			margin-top: 3px;
			position: absolute;
		}

		.ember-power-select-multiple-options{
			&:before{
				position: absolute;
				top: 7px;
				left: 7px;
				font: normal normal normal 24px/1 "Material Design Icons";
				content: mdi('magnify');
				color: $app-icon-color;
			}
		}
	}

	&.app-form-filters{

		.ember-power-select-trigger{
			border: 0;
			background-color: #F6F8FA;
		}

		.ember-power-select-multiple-option{
			margin: 4px 1px 0 4px;
		}

	}
}
.ember-power-select-multiple-option{
	margin:  4px 0 0 4px !important;
}
