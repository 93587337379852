.select-icon {
    width: 100px;
    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
    }
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-track {
        background: $gray-light;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(148, 160, 176, 0.5);
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(148, 160, 176, 0.5);
    }

    button {
        &:after {
            display: none !important;
        }
    }


    .add-icon {
        width: 193px;
        max-height: 186px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
        margin: 0;
        top: 46px;
        right: -30px;
        will-change: transform;
        left: auto;

        -webkit-box-shadow: -8px 7px 20px 6px rgba(52, 51, 51, 0.05);
        -moz-box-shadow: -8px 7px 20px 6px rgba(52, 51, 51, 0.05);
        box-shadow: -8px 7px 20px 6px rgba(52, 51, 51, 0.05);

        .flex-container {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            -webkit-flex-flow: row wrap;
            background: $gray-light;
            margin: -1px;

            .flex-item {
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;
                width: 60px;
                height: 60px;
                margin: 1px;
                cursor: pointer;
                color: tomato;
                font-weight: bold;
                text-align: center;

                &:hover {
                    background-color: #f9f9f9;
                }
            }
        }
    }
    .upload-button {
        width: 100%;
        height: 80px;
        border: 1px dashed $gray-light;
        background-color: #f5f9fb;
        transition: all ease-in-out 150ms;
        cursor: pointer;
        &:focus {
            outline: none;
        }
        i {
            transition: all ease-in-out 150ms;
            float: right;
            &:before{
                font-size: 34px;
                line-height: 44px;
            }
        }
        &:hover {
            border: 1px dashed $pink;
            i {
                color: $pink;
                transition: all ease-in-out 150ms;
            }
        }
    }
}
