.__contractor__profile__content-sidebar-public__abfeb {
	.profile-essentials {
		padding: 60px 30px;

		h4 {
			a {
				color: $black;
				&:hover {
					color: $green;
				}

				.profile-avatar {
					margin: 0 auto;
					margin-bottom: 20px;
				}
			}

			.verified {
				color: $green;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.bio {
			font-size: 1.25rem
		}
	}

	.profile-extras {
		p {
			margin: 0;
		}
		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				padding: 5px 0;
			}
		}
		.nc-icon{
			color: $green;
		}
		h6 {
			display: inline-block;
			font-weight: normal;
			margin-left: 15px;
		}
		.user-rate, .guards, .approvals, .godkjennelser, .vakttyper, .p-map {
			padding: 15px 30px;
			border-top: 1px solid #eee;
		}

		.user-rate {
			.rating-panel {
				i {
					color: $orange;
				}
			}
		}
		.guards {
			.badge {
				font-size: 16px;
				font-weight: normal;
				border-radius: 8px;
			}
			.badge-default {
				background: rgba(170, 168, 168, 0.7);
			}
		}

		.p-map {
			iframe {
				width: 100%;
			}
		}
	}
}
