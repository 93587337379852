.testimonials {
  position: relative;

  .card-deck {

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    
    .card {
      flex: 1;
      border: none;
      -webkit-box-shadow: 0 0 50px 2px rgba(52, 51, 51, 0.05);
      -moz-box-shadow: 0 0 50px 2px rgba(52, 51, 51, 0.05);
      box-shadow: 0 0 50px 2px rgba(52, 51, 51, 0.05);
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        width: 100%;
        display: none;
        &:first-child{
          display: block;

        }
      }
      &:hover{
        -webkit-box-shadow: 0 6px 28px 7px rgba(53, 52, 52, 0.14);
        -moz-box-shadow: 0 6px 28px 7px rgba(53, 52, 52, 0.14);
        box-shadow: 0 6px 28px 7px rgba(53, 52, 52, 0.14);
      }

      .card-body {
        padding: 30px;

        .card-avatar {
          padding-bottom: 38px;
          img {
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            width: 100%;
            height: auto;
            max-width: 100px;
          }
        }
        .card-caption {
          .card-title {
            font-weight: 500;
            margin-bottom: 0.5rem;

          }
          .card-text {
            font-weight: 400;
            color: $gray-dark;
          }
        }
      }
    }
  }
}