.__contractee__vacancy__view__display-vacancy-toolbar__d8190 {
    .btn {
        display: flex;
        align-items: center;
        @include transition($transition-base);
        i{
            color: #0d8466;
            @include transition($transition-base);
        }
    }
    &:hover {
        cursor: pointer;
        .vacancy-footer-wrapper {
            padding-top: 20px;
            padding-bottom: 20px;
            @include transition($transition-base);
        }
        .btn {
            @extend .btn-primary;
            @include transition($transition-base);
            i{
                color: #fff;
                @include transition($transition-base);
            }
        }
    }
}
