$app-brand-primary-darker: #109171;
$app-brand-primary: #15bf95;
$app-brand-primary-lighter: #deebff;
$app-brand-primary-light: #14ca9c;

$app-brand-yellow: #f2b134;
$app-brand-yellow-lighter: rgba(242, 177, 52, .1);

$app-brand-purple: #8a3b58;
$app-brand-red: #ea3d56;

$app-brand-white: #fff;
$app-border-color: #ebecee;

$app-brand-gray-light: #999;
$app-brand-gray-dark: #343333;
$app-brand-black: #1d1e1f;

$app-nav-item: #42BF93;
$app-nav-item-hover-transition: box-shadow 300ms;

$app-overlay-bg: rgba(46, 46, 46, .7);
$app-overlay-transition: all .5s cubic-bezier(0, 0, .4, .9);

$app-main-menu-width: 80px;
$app-sidenav-width: 310px;
$app-sidenav-width-large: 480px;

$app-navigation-shadow: rgba(0, 0, 0, 0.05);
$app-navigation-header-border-color: $app-border-color;
$app-navigation-drop-down-item: rgba(242, 177, 52, .15);

$app-nav-collapse-dropdown-color: $app-brand-yellow;
$app-nav-collapse-dropdown-color-inactive: rgba(128, 128, 128, .6);
$app-nav-collapse-icon: $app-brand-primary;
$app-nav-collapse-text: $app-brand-gray-dark;
$app-nav-collapse-text-hover: $app-brand-yellow-lighter;

$app-subnav-meun-transition-left: left 200ms ease-in-out;
$app-subnav-meun-transition-right: right 200ms ease-in-out;
$app-subnav-header-border: 1px solid $app-border-color;
$app-subnav-paragraph: rgba(52, 51, 51, .7);
$app-subnav-unread-button: $app-brand-red;
$app-subnav-separator: $app-subnav-header-border;

$app-subnav-item-bg: $app-brand-white;
$app-subnav-item-bg-hover: rgba($app-brand-gray-light, .2);

$app-subnav-item-text: $app-brand-gray-dark;
$app-subnav-item-text-hover: $app-brand-gray-dark;

$app-subnav-item-unread-bg: #f7f7f9;

$app-layout-padding-left-tablet: 20px 20px 20px $app-main-menu-width + 20;
$app-layout-padding-left-jumbo: 20px 20px 20px $app-sidenav-width + 100;

$app-layout-padding-right-tablet: 20px $app-main-menu-width + 20 20px 20px;
$app-layout-padding-right-jumbo: 20px $app-sidenav-width + 100 20px 20px;

$app-break-point: 992px;

$app-layout-hidden: 20px 20px 20px 20px;