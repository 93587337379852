// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.

$mdi-font-path:        "fonts/mdi/" !default;

// Start with assigning color names to specific hex values.
$white:  #fff !default;
$black:  #343333 !default;
$red:    #ED553B !default;
$orange: #FEB632 !default;
$yellow: #ffd500 !default;
$green:  #14CA9C !default;
$blue:   #B3BBCF !default;
$teal:   #5bc0de !default;
$pink:   #ff5b77 !default;
$purple: #613d7c !default;

$yellow-light: #FFFCF7;

$green-light: #E5FAF5;

// Create grayscale
$gray-dark:                 #343333 !default;
$gray:                      #AAA8A8 !default;
$mute:                      #bfbdbd !default;
$gray-light:                #EBECEE !default;
$gray-lighter:              #949393 !default;
$gray-lightest:             #f7f7f9 !default;

// Reassign color vars to semantic color scheme
$brand-primary:             $green !default;
$brand-secondary:           #15BF95 !default;

$brand-success:             $green !default;
$brand-info:                $teal !default;
$brand-warning:             $orange !default;
$brand-danger:              $red !default;
$brand-inverse:             $gray-dark !default;

$app-icon-color: #D1D3D4 !default;
$app-heading-color: $gray-dark !default;

//Hover CSS
$primaryColor: $green !default;
$secondaryColor: #15BF95  !default;
$highlightColor: #ccc !default;
$activeColor: #15BF95 !default;
$shadowColor: rgba(0, 0, 0, .6) !default;
//END - Hover CSS

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true !default;
$enable-shadows:            true !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  true !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Body
//
// Settings for the `<body>` element.

$body-bg:       #F6F8FA !default;
$body-color:    #989696 !default;
$inverse-bg:    $gray-dark !default;
$inverse-color: $gray-lighter !default;


// Links
//
// Style anchor elements.

$link-color:            $brand-primary !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: none !default;

//Font Family
$font-family-sans-serif: 'Fira Sans', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif:      'Fira Sans', Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

//Font Size
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem !default;
$font-size-sm:   .875rem !default;
$font-size-xs:   .75rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: 500 !default;

//Headings

$font-size-h1: 2.625rem !default;
$font-size-h2: 2rem !default;
$font-size-h3: 1.75rem !default;
$font-size-h4: 1.5rem !default;
$font-size-h5: 1.25rem !default;
$font-size-h6: 1rem !default;

//Border Color
$table-border-color:  $gray-light !default;

//Flat no radius
$border-radius:          0 !default;
$border-radius-lg:       0 !default;
$border-radius-sm:       0 !default;

$card-border-width:        0px !default;
$card-border-color:        $table-border-color !default;
$card-shadow:  0px 0px 50px 5px rgba(0,0,0,0.05);
$card-spacer-y: 1.5rem;

//Sideav - Custom
$sidenav-icon-color: #A1EAD7;
$sidenav-width: 280px;

//Button
$btn-focus-box-shadow: none;
$btn-font-weight: 500;

$btn-box-shadow:                 none !default;
$btn-active-box-shadow:          none !default;

$btn-border-radius:              0 !default;
$btn-border-radius-lg:           0 !default;
$btn-border-radius-sm:           0 !default;


// Breadcrumbs
$breadcrumb-padding-y:          0 12px !default;
$breadcrumb-padding-x:          24px/2 !default;
$breadcrumb-item-padding:       .5rem !default;

$breadcrumb-bg:                 transparent !default;
$breadcrumb-divider-color:      $table-border-color !default;
$breadcrumb-active-color:       $brand-primary !default;
$breadcrumb-inactive-color:     $gray-dark !default;
$breadcrumb-divider:            "/" !default;

// Form

$input-color:                   $gray-dark;
$custom-select-disabled-bg:     $mute !default;
$input-bg-disabled: #d0cece !default;

$input-color-placeholder: $gray-lighter;

$input-box-shadow: none;

$input-border-radius:            3px !default;
$custom-select-border-radius:            3px !default;
$custom-file-border-radius:            3px !default;
$input-border-radius-lg:         $border-radius-lg !default;
$input-border-radius-sm:         $border-radius-sm !default;

$custom-control-indicator-size: 0.875rem;
$custom-control-checked-indicator-color:      $white !default;
$custom-control-checked-indicator-bg:         $brand-primary !default;
$custom-control-checked-indicator-box-shadow: 0px 0px 0px 2px $brand-primary, -2px 2px 1px -1px $brand-primary !default;

$custom-checkbox-indeterminate-bg: $brand-primary !default;
$custom-checkbox-indeterminate-indicator-color: $white !default;
$custom-control-indicator-box-shadow: 0px 0px 0px 2px #D1D3D4, -2px 2px 1px -1px #D1D3D4 !default;
$custom-checkbox-radius: 3px;
$custom-control-indicator-bg: transparent;
$custom-control-disabled-description-color: $gray-lighter;

$custom-control-focus-indicator-box-shadow: $table-border-color !default;

$custom-file-button-bg: $brand-primary;
$custom-file-border-width: 1px;
$custom-file-border-color: $brand-primary;
$custom-file-button-color: $white;

//Popover

$popover-bg:  #EBECEE !default;

//Datepicker

$accent-color: #14CA9C !default;
$disabled-textfield-grey: #9e9e9e !default;
$divider-color: #c2c2c2 !default;
$datepicker-background-color: #fff !default;
$text-on-accent-color: #fff !default;
$invalid-red: #f44336 !default;
$month-selector-color: #757575 !default;
$datepicker-main-text-color: #383838 !default;
$datepicker-disabled-text-color: #CCCCCC !default;
$box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !default;
$font: Roboto, sans-serif !default;
$font-form: 14px !default;
$font-small: 12px !default;


// Input Group Addon

$input-group-addon-bg: $white;


// Custom Checkbox
$custom-checkbox-indeterminate-bg: #fff;
$custom-checkbox-indeterminate-icon: '';


//DROPDown
$dropdown-link-active-color: $gray-dark;
$dropdown-link-active-bg: $gray-lightest;


$dropdown-border-width: 0px;
$dropdown-border-color: transparent;
$dropdown-box-shadow: 0px 0px 50px 5px rgba(0, 0, 0, 0.05);

//Grid

$grid-gutter-width-base:     24px !default;

//Tooltip

$tooltip-color:               $white!default;
$tooltip-bg:                  $black !default;
$tooltip-opacity:             1 !default;

//Hyperlink

$link-color:            $brand-primary !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: none !default;

$small-font-size: 90% !default;

/*
 border: $ember-power-select-multiple-option-border;
  border-radius: $ember-power-select-multiple-option-border-radius;
  color: $ember-power-select-multiple-selection-color;
  background-color: $ember-power-select-multiple-selection-background-color;
  padding: $ember-power-select-multiple-option-padding;
  display: inline-block;
  line-height: $ember-power-select-multiple-option-line-height;
  float: left;
  margin: 2px 0 2px 3px;

 */

//$ember-power-select-trigger-border-top: 0;
//$ember-power-select-trigger-border-bottom: 0;
//$ember-power-select-trigger-border-right: 0;
//$ember-power-select-trigger-border-left: 0;
$ember-power-select-trigger-default-border-radius: 3px;

$ember-power-select-line-height: 37px !default;
$ember-power-select-border-color: #ccc !default;
$ember-power-select-trigger-icon-color: #999 !default;
$ember-power-select-focus-outline: 0 !default;
$ember-power-select-opened-border-radius: 4px !default;
$ember-power-select-search-input-border-radius: 3px !default;
$ember-power-select-dropdown-margin: 3px !default;
$ember-power-select-dropdown-box-shadow: rgba(black, 0.172549) 0px 6px 12px 0px !default;
$ember-power-select-highlighted-color: $gray-dark !default;
$ember-power-select-highlighted-background: #f5f5f5 !default;
$ember-power-select-selected-background: #f5f5f5 !default;
$ember-power-select-focus-border-color: #66afe9 !default;
$ember-power-select-focus-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6) !default;
$ember-power-select-dropdown-border: 1px solid $ember-power-select-border-color !default;
$ember-power-select-dropdown-contiguous-border: $ember-power-select-dropdown-border !default;

$ember-power-select-multiple-option-line-height: 2rem;

//Full Calendar
$fc-background-content: #A8F7E0;
$fc-background-text: #14CA9C;

$fc-background-content-long: #FBD9D9;
$fc-background-text-long: #ED553B;

$fc-background-border: #F6F8FA;

//Map Find Contractor

$map-height-contractors: 800px !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
    0: (
        x: 0,
        y: 0
    ),
    1: (
        x: ($spacer-x * .25),
        y: ($spacer-y * .25)
    ),
    2: (
        x: ($spacer-x * .5),
        y: ($spacer-y * .5)
    ),
    3: (
        x: $spacer-x,
        y: $spacer-y
    ),
    4: (
        x: ($spacer-x * 1.5),
        y: ($spacer-y * 1.5)
    ),
    5: (
        x: ($spacer-x * 3),
        y: ($spacer-y * 3)
    ),
    6: (
        x: ($spacer-x * 4),
        y: ($spacer-y * 4)
    )
) !default;
$border-width: 1px !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%
) !default;


// Badges

$badge-color:                 $white !default;
$badge-font-size:             75% !default;
$badge-font-weight:           $font-weight-bold !default;
$badge-padding-y:             .25em !default;
$badge-padding-x:             .4em !default;
$badge-border-radius:               $border-radius !default;
$badge-pill-padding-x:        .6em !default;
