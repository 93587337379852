.vacancy-link {
    &:after {
        -moz-transition: box-shadow 300ms;
        -o-transition: box-shadow 300ms;
        -webkit-transition: box-shadow 300ms;
        transition: box-shadow 300ms;
        box-shadow: 0 0 0 0 rgba(242, 177, 52, 0.2);
        height: 33px;
        width: 33px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 2;
    }
    &:before {
        content: ' ';
        background-color: #b334f2;
        border-radius: 50%;
        height: 33px;
        width: 33px;
        transform: translate(-49%, -51%);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
    }
    i {
        z-index: 3;
    }
}

.post-job-wrapper {
    padding: 80px 20px;
    .form-section {
        a {
            @include media-breakpoint-down(md) {
                display: block;
                width: 100%;
            }
        }
    }
}

.vacancy-sidebar-wrapper {
    border-right: 1px solid #eaeaea;
    height: 100%;

    .vacancy-essentials {
        padding: 60px 30px;

        h4 {
            a {
                color: $black;
                &:hover {
                    color: $green;
                }

                .profile-avatar {
                    margin: 0 auto;
                    margin-bottom: 20px;
                }
            }

            .verified {
                color: $green;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .bio {
            font-size: 1.25rem
        }
    }
    .vacancy-sidebar-extras {
        .job-plan {
            padding: 15px 30px;
            border-top: 1px solid #eee;
            h6 {
                font-weight: normal;
            }
        }
        .location, .job-type, .deadline, .share {
            padding: 15px 30px;
            border-top: 1px solid #eee;

            p {
                margin: 0;
                line-height: 1rem;
                display: inline-block;
            }
            h6 {
                display: inline-block;
                font-weight: normal;
                margin: 0;
            }
            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    padding-left: 10px;

                    i {
                        font-size: 22px;
                    }
                    a {
                        display: block;
                    }

                    .facebook {
                        color: #2F61AE;
                    }
                    .twitter {
                        color: #0589FF;
                    }
                    .messenger {
                        color: #04B5EF;
                    }
                    .linkedin {
                        color: #0388CA;
                    }
                }
            }
        }
    }
}

.vacancy-content-wrapper {
    padding: 80px 80px 80px 40px;
    @include media-breakpoint-down(md) {
        padding: 20px 20px 20px 20px;
    }

    .vacancy-content-header {
        h4 {
            margin: 0;
            line-height: 32px;
        }
    }
    .vacancy-content-info {
        ul {
            list-style: none;
            padding: 0 10px;
            background-color: $body-bg;

            li {
                margin-right: 24px;

                i {
                    font-size: 20px;
                    padding-right: 10px;
                }
            }
        }
    }
    .vacancy-content-body {
        p {
            color: $black;
            line-height: 26px;
        }
        ul {
            li {
                color: $black;
                margin-bottom: 14px;
            }
        }
    }
    .edit-btn {
        i {
            color: $orange;
        }
        &:hover {
            border: 1px solid $orange;
        }
    }

    .meld-interesse-alert {
        padding: 24px;
        background-color: #EBFBF8;
        .alert-content {
            padding-left: 24px;

            p {
                font-size: 15px;
                color: rgba(52, 51, 51, 0.70);
                margin: 0;
            }
        }
    }
}

.vacancy-footer-wrapper {
    padding: 14px 30px;
    @include transition($transition-base);
    &.open {
        left: 410px;
        @include transition($transition-base);
    }

    @include media-breakpoint-down(lg) {
        left: 20px;
        right: 20px;
    }
    .vacancy-footer-content {
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
        h5 {
            font-weight: normal;
            margin: 0;
        }
        a {
            @include media-breakpoint-down(md) {
                margin-top: 14px;
                display: block;
                width: 100%;
            }
        }
        .superscript {
            color: $red;
            font-size: 14px;
        }
    }
}

.vacancy-overlay {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);

    position: fixed;
    right: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
}

.following-job {
    padding: 20px;
    background-color: #EBEDEF;

    .following-job-content {
        margin-left: 20px;

        .following-job-label {
            text-transform: uppercase;
            font-size: 14px;
            color: #AAA8A8;
        }
    }
}

.search-applicants {
    .input-group {
        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }
    .app-power-select {
        background-color: #fff;
        .ember-power-select-trigger {
            border: 1px solid #D1D3D4;
            background-color: #fff;
            border-radius: 3px;
        }
    }

    .panel-footer {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        .custom-control-indicator {
            background-color: #fff;
        }
        .custom-control-input:checked ~ .custom-control-indicator {
            background-color: #14CA9C;
        }
    }

}

.user-card {
    //overflow: hidden;
    flex-direction: row;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
    &.checked {
        border: 1px solid #A3ECDD;
    }
    .user-card-img {
        width: 208px;
        height: 208px;
        position: relative;

        @include media-breakpoint-down(md) {
            width: 100%;
            height: 400px;
        }
        img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }
        .user-play-button {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0 8px;
            margin: 0;
            height: 40px;

            span {
                max-width: 0;
                display: inline-block;
                vertical-align: top;
                white-space: nowrap;
                overflow: hidden;
                @include transition($transition-base);
                font-family: "Fira Sans";
            }
            &:hover span {
                max-width: 7rem;
            }
        }
    }

    .user-card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px 24px 0 24px;
        width: 100%;

        @include media-breakpoint-down(md) {
            padding-bottom: 24px;
        }

        .user-link {
            a {
                color: $gray-dark;
                &:hover {
                    color: $brand-primary;
                }
            }
        }
        .user-hired {
            position: absolute;
            right: 0;
            top: 24px;
            z-index: 1;
            .flag-vacancy {
                width: 60px;
                height: 24px;
                padding-top: 6px;
                line-height: 12px;
                position: relative;
                background: $brand-primary;
                color: white;
                font-size: 11px;
                text-align: center;
                text-transform: uppercase;
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-left: 4px solid #fff;
                    border-bottom: 12px solid transparent;
                    border-top: 12px solid transparent;
                }
            }

        }
        .user-hire {
            position: absolute;
            right: 12px;
            top: 24px;
            z-index: 999;
        }
        .user-select {
            position: absolute;
            right: 31px;
            top: 46px;
            z-index: 999;
        }
        .user-skills {
            display: inline-flex;
            .user-skill-items {
                position: relative;
                margin: 0;
                padding: 0;
                list-style: none;

                .skill-item {
                    margin-right: 8px;
                    border-radius: 5px;
                    background-color: #EBFBF8;
                    color: $black;
                    padding: 3px 10px;
                    font-size: 15px;
                }
                .dropdown-menu {
                    margin: 0;
                    padding: 0;
                    top: 40px;
                    max-height: 250px;
                    overflow-y: auto;
                    .dropdown-item {
                        font-size: 15px;
                        padding: 6px 1.5rem;
                        cursor: pointer;
                    }
                }
                .dropdown-toggle {
                    cursor: pointer;
                    @include transition($transition-base);

                    &:hover {
                        color: $green;
                    }
                    &:after {
                        display: none;
                    }
                }
            }
        }
        .user-stats {
            flex-direction: row;
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
            p, h6 {
                margin: 0;
                font-weight: normal;
            }
            .user-card-rate {
                margin-top: -5px;
            }
        }

        .user-contact-buttons {
            .phone-btn {
                color: $gray-dark;
                i {
                    color: $green;
                }
                &:hover {
                    border: 1px solid $green;
                }
            }
            .message-btn {
                color: $gray-dark;
                i {
                    color: $orange;
                }
                &:hover {
                    border: 1px solid $orange;
                }
            }
            .cancel-btn {
                color: $gray-dark;
                i {
                    color: $brand-danger;
                }
                &:hover {
                    border: 1px solid $brand-danger;
                }
            }
        }
    }
}

//Worker flow

.job-card {
    padding: 20px 30px;
    flex-direction: row;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
    .job-card-logo {
        float: left;
        @include media-breakpoint-down(md) {
            float:none;
            margin: auto auto;
        }
        .logo {
            @include media-breakpoint-down(md) {
                width: 60px;
                margin-bottom: 20px;
            }
        }
    }
    .card-block {
        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .job-description {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: column;
            }
            .job-title a {
                color: $black;
                @include transition($transition-base);

                &:hover {
                    color: $green;
                }
            }
            .card-subtitle {
                @include media-breakpoint-down(md) {
                    padding-top: 6px;
                    padding-bottom: 20px;
                }
                a {
                    color: $gray;
                    font-size: 1.125rem;
                    @include transition($transition-base);

                    &:hover {
                        color: $black;
                    }
                }
            }

            .time-posted {
                @include media-breakpoint-down(md) {
                    justify-content: center;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .job-details {
        flex-direction: row;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: center;
            span {
                margin-bottom: 10px;
            }

        }
    }
    span {
        color: $black;
        i {
            color: $green;
            margin-right: 6px;
        }
    }
}

.vacancy-bottom-space {
    margin-bottom: 150px;
}

.vacancy-occupation-container {
    margin: 1rem 0;
}

.vacancy-tag-container {
    margin-top: 2rem;
    .badge-outline {
        margin-bottom: 0.5rem;
    }
}
