.__custom__edit-form__fbbb5{
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	background-color: $brand-primary;
	padding: 0;
	margin: 0;
	i {
		font-size: 18px;
		margin: 1px auto;
		display: block;
		text-align: center;
	}
	&.red-button{
		background-color: $brand-danger;
	}
	&.btn-edit-top-right {
		position: absolute;
		top: -10px;
		right: -10px;
	}
}