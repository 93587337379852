/*------------------------
	font icons
-------------------------*/

.nc-icon.nc-award-48-2:before {
  content: "\ea01";
}

.nc-icon.nc-award-48-3:before {
  content: "\ea02";
}

.nc-icon.nc-award-48-4:before {
  content: "\ea03";
}

.nc-icon.nc-award-48:before {
  content: "\ea04";
}

.nc-icon.nc-award-49-2:before {
  content: "\ea05";
}

.nc-icon.nc-award-49-3:before {
  content: "\ea06";
}

.nc-icon.nc-award-49-4:before {
  content: "\ea07";
}

.nc-icon.nc-award-49:before {
  content: "\ea08";
}

.nc-icon.nc-badge-13-2:before {
  content: "\ea09";
}

.nc-icon.nc-badge-13-3:before {
  content: "\ea0a";
}

.nc-icon.nc-badge-13-4:before {
  content: "\ea0b";
}

.nc-icon.nc-badge-13-5:before {
  content: "\ea0c";
}

.nc-icon.nc-badge-13:before {
  content: "\ea0d";
}

.nc-icon.nc-brightness-46-2:before {
  content: "\ea0e";
}

.nc-icon.nc-brightness-46-3:before {
  content: "\ea0f";
}

.nc-icon.nc-brightness-46-4:before {
  content: "\ea10";
}

.nc-icon.nc-brightness-46:before {
  content: "\ea11";
}

.nc-icon.nc-car-simple-2:before {
  content: "\ea12";
}

.nc-icon.nc-car-simple-3:before {
  content: "\ea13";
}

.nc-icon.nc-car-simple-4:before {
  content: "\ea14";
}

.nc-icon.nc-car-simple:before {
  content: "\ea15";
}

.nc-icon.nc-favourite-31-2:before {
  content: "\ea16";
}

.nc-icon.nc-favourite-31-3:before {
  content: "\ea17";
}

.nc-icon.nc-favourite-31-4:before {
  content: "\ea18";
}

.nc-icon.nc-favourite-31-5:before {
  content: "\ea19";
}

.nc-icon.nc-favourite-31:before {
  content: "\ea1a";
}

.nc-icon.nc-night-2:before {
  content: "\ea1b";
}

.nc-icon.nc-night-3:before {
  content: "\ea1c";
}

.nc-icon.nc-night-4:before {
  content: "\ea1d";
}

.nc-icon.nc-night:before {
  content: "\ea1e";
}

.nc-icon.nc-police-2:before {
  content: "\ea1f";
}

.nc-icon.nc-police-3:before {
  content: "\ea20";
}

.nc-icon.nc-police-4:before {
  content: "\ea21";
}

.nc-icon.nc-police-5:before {
  content: "\ea22";
}

.nc-icon.nc-police:before {
  content: "\ea23";
}

.nc-icon.nc-progress-2:before {
  content: "\ea24";
}

.nc-icon.nc-progress-3:before {
  content: "\ea25";
}

.nc-icon.nc-progress-4:before {
  content: "\ea26";
}

.nc-icon.nc-progress:before {
  content: "\ea28";
}

.nc-icon.nc-sun-fog-29-2:before {
  content: "\ea27";
}

.nc-icon.nc-sun-fog-29-3:before {
  content: "\ea29";
}

.nc-icon.nc-sun-fog-29-4:before {
  content: "\ea2a";
}

.nc-icon.nc-sun-fog-29:before {
  content: "\ea2b";
}

.nc-icon.nc-world-2-2:before {
  content: "\ea2c";
}

.nc-icon.nc-world-2-3:before {
  content: "\ea2d";
}

.nc-icon.nc-world-2:before {
  content: "\ea2e";
}

.nc-icon.nc-world:before {
  content: "\ea2f";
}

