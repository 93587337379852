$popover-border-color-custom: $white;
$popover-arrow-outer-color-custom: $white;
$popover-arrow-color-custom: $white;
$popover-inner-padding-custom: 0.5rem 1rem;
.popover-calendar {
	position: absolute;
	top: 0;
	left: 0;
	z-index: $zindex-popover;
	display: block;
	max-width: none;
	padding: $popover-inner-padding-custom;
	// Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
	// So reset our font and text properties to avoid inheriting weird values.
	@include reset-text();
	font-size: $font-size-sm;
	// Allow breaking very long words so they don't overflow the popover's bounds
	word-wrap: break-word;
	background-color: $white;
	background-clip: padding-box;
	border: $popover-border-width solid $popover-border-color-custom;
	@include border-radius($border-radius-lg);
	-webkit-box-shadow: 1px 1px 13px 4px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 1px 1px 13px 4px rgba(0, 0, 0, 0.2);
	box-shadow: 1px 1px 13px 4px rgba(0, 0, 0, 0.2);

	// Popover directions

	&.popover-top,
	&.bs-tether-element-attached-bottom {
		margin-top: -$popover-arrow-width;

		&::before,
		&::after {
			left: 50%;
			border-bottom-width: 0;
		}

		&::before {
			bottom: -$popover-arrow-outer-width;
			margin-left: -$popover-arrow-outer-width;
			border-top-color: $popover-arrow-outer-color-custom;
		}

		&::after {
			bottom: -($popover-arrow-outer-width - 1);
			margin-left: -$popover-arrow-width;
			border-top-color: $popover-arrow-color-custom;
		}
	}

	&.popover-right,
	&.bs-tether-element-attached-left {
		margin-left: $popover-arrow-width;

		&::before,
		&::after {
			top: 50%;
			border-left-width: 0;
		}

		&::before {
			left: -$popover-arrow-outer-width;
			margin-top: -$popover-arrow-outer-width;
			border-right-color: $popover-arrow-outer-color-custom;
		}

		&::after {
			left: -($popover-arrow-outer-width - 1);
			margin-top: -($popover-arrow-outer-width - 1);
			border-right-color: $popover-arrow-color-custom;
		}
	}

	&.popover-bottom,
	&.bs-tether-element-attached-top {
		margin-top: $popover-arrow-width;

		&::before,
		&::after {
			left: 50%;
			border-top-width: 0;
		}

		&::before {
			top: -$popover-arrow-outer-width;
			margin-left: -$popover-arrow-outer-width;
			border-bottom-color: $popover-arrow-outer-color-custom;
		}

		&::after {
			top: -($popover-arrow-outer-width - 1);
			margin-left: -$popover-arrow-width;
			border-bottom-color: $popover-title-bg;
		}

		// This will remove the popover-title's border just below the arrow
		.popover-title::before {
			position: absolute;
			top: 0;
			left: 50%;
			display: block;
			width: 20px;
			margin-left: -10px;
			content: "";
			border-bottom: 1px solid $popover-title-bg;
		}
	}

	&.popover-left,
	&.bs-tether-element-attached-right {
		margin-left: -$popover-arrow-width;

		&::before,
		&::after {
			top: 50%;
			border-right-width: 0;
		}

		&::before {
			right: -$popover-arrow-outer-width;
			margin-top: -$popover-arrow-outer-width;
			border-left-color: $popover-arrow-outer-color-custom;
		}

		&::after {
			right: -($popover-arrow-outer-width - 1);
			margin-top: -($popover-arrow-outer-width - 1);
			border-left-color: $popover-arrow-color-custom;
		}
	}

	.popover-title, .popover-content {
		border-color: $white;
		background-color: $popover-border-color-custom;
		padding: 0 0 0 50px ;
		height: 20px;
		line-height: 20px;
		display: block;
	}
	.popover-title{
		font-size: 14px;
	}
	.popover-img {

			float: left;
			@include size(40px);
			@extend .rounded-circle;

	}
}