.requests-list {
  .item {
    margin-bottom: 1rem;
    border-bottom: 1px solid #EBECEE;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
    &:after {
      @extend .clearfix;
    }
  }
}