.__contractor__card__main-vacancy-selected__2e5ad{
    //overflow: hidden;
    flex-direction: row;
    position: relative;
    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
    &.checked {
        border: 1px solid #A3ECDD;
    }
    .user-card-img {
        width: 108px;
        height: 108px;
        position: relative;

        @include media-breakpoint-down(md) {
            display: block;
            width: 208px;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            padding: 40px 40px 20px;
        }
        img {
            width: 100%;
            height: 108px;
            -o-object-fit: cover;
            object-fit: cover;
            @include media-breakpoint-down(md) {
                width: 108px;
                border-radius: 50%;
                margin: auto auto;
                display: block;
            }
        }
        .user-play-button {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0 8px;
            margin: 0;
            height: 40px;

            span {
                font-family: "Fira Sans";
                max-width: 0;
                display: inline-block;
                vertical-align: top;
                white-space: nowrap;
                overflow: hidden;
                @include transition($transition-base);
            }
            &:hover span {
                max-width: 7rem;
            }
        }
    }

    .user-card-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px;
        width: 100%;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }

        .user-link {
            a {
                color: $gray-dark;
                &:hover {
                    color: $brand-primary;
                }
            }
        }

        .user-options{
            margin-left: auto;
            margin-right: 10px;
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-left: 0px;
                margin-right: 0px;
                margin-top: 30px;
            }
        }
        .user-additional-options{
            @include media-breakpoint-down(md) {
                position: absolute;
                top: 20px;
                right: 10px;
            }
        }

        .user-skills {
            display: inline-flex;
            .user-skill-items {
                position: relative;
                margin: 0;
                padding: 0;
                list-style: none;

                .skill-item {
                    margin-right: 8px;
                    border-radius: 5px;
                    background-color: #EBFBF8;
                    color: $black;
                    padding: 3px 10px;
                    font-size: 15px;
                }
                .dropdown-menu {
                    margin: 0;
                    padding: 0;
                    top: 40px;
                    max-height: 250px;
                    overflow-y: auto;
                    .dropdown-item {
                        font-size: 15px;
                        padding: 6px 1.5rem;
                        cursor: pointer;
                    }
                }
                .dropdown-toggle {
                    cursor: pointer;
                    @include transition($transition-base);

                    &:hover {
                        color: $green;
                    }
                    &:after {
                        display: none;
                    }
                }
            }
        }
        .user-stats {
            flex-direction: column;
            text-align: right;

            p, h6 {
                margin: 0;
                font-weight: normal;
            }
            .user-card-rate {
                margin-top: -5px;
            }
        }

        .user-contact-buttons {
            .phone-btn {
                color: $gray-dark;
                i {
                    color: $green;
                }
                &:hover {
                    border: 1px solid $green;
                }
            }
            .message-btn {
                color: $gray-dark;
                i {
                    color: $orange;
                }
                &:hover {
                    border: 1px solid $orange;
                }
            }
            .cancel-btn {
                color: $gray-dark;
                i {
                    color: $brand-danger;
                }
                &:hover {
                    border: 1px solid $brand-danger;
                }
            }
        }
    }
}
