.admin-menu {
	overflow: auto;
	height: 100vh;
	padding-bottom: 80px !important;
}

.nav-app-version {
	padding: 5px;
	text-align: center;
	background-color: #14ca9c;
	font-size: 12px;
}

.app-sidenav-new-message {
	padding: 30px;
}

.app-nav-header-conversation{
	padding: 15px 30px;
}
.nav-create-items {
	position: relative;
	i {
		position: absolute;
		top: 50%;
		right: 10px;
		margin-top: -20px;
		@include transition($transition-base);
	}
	&:hover {
		i {
			right: 5px;
			@include transition($transition-base);
		}
	}

}

.h-100{
	height: calc(100% - 66px);
}