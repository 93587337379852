.__contractee__assignment__contractor-info__78e9c {
    @include media-breakpoint-up(lg) {
        .button-custom-width{
            width: 130px;
        }
        .col-custom-4 {
            width: 300px;
        }
    }
}
