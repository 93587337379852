/* To bar notifications */
.ns-box.ns-bar {
	top: 0;
	left: 0;
	width: 100%;
}

.ns-bar .ns-close {
	background: transparent;
	top: 50%;
	right: 20px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.ns-bar .ns-close::before,
.ns-bar .ns-close::after {
	background: #b7b5b3;
}

[class^="ns-effect-"].ns-bar.ns-hide,
[class*=" ns-effect-"].ns-bar.ns-hide {
	-webkit-animation-direction: reverse;
	animation-direction: reverse;
}

/* Individual Effects */

/* Slide on top */
.ns-effect-slidetop {
	box-shadow: inset 4.5em 0 rgba(0,0,0,0.6);
}

.ns-effect-slidetop .icon {
	position: absolute;
	display: block;
	font-size: 109%;
	top: 50%;
	left: 1em;
	-webkit-transform: translate3d(0,-50%,0);
	transform: translate3d(0,-50%,0);
}

.ns-effect-slidetop p {
	padding: 0 3.2em;
	font-size: 1.2em;
	display: inline-block;
}

.ns-effect-slidetop .ns-close::before,
.ns-effect-slidetop .ns-close::after {
	width: 2px;
	background: #fff;
}

.ns-effect-slidetop .ns-close:hover::before,
.ns-effect-slidetop .ns-close:hover::after {
	background: #fff;
}

.ns-effect-slidetop.ns-show .icon,
.ns-effect-slidetop.ns-show p {
	-webkit-animation-name: animScaleUp;
	animation-name: animScaleUp;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.ns-effect-slidetop.ns-show p {
	-webkit-animation-name: animFade;
	animation-name: animFade;
}

@-webkit-keyframes animScaleUp {
	0% { opacity: 0; -webkit-transform: translate3d(0,-50%,0) scale3d(0,0,1); }
	100% { opacity: 1; -webkit-transform: translate3d(0,-50%,0) scale3d(1,1,1); }
}

@keyframes animScaleUp {
	0% { opacity: 0; -webkit-transform: translate3d(0,-50%,0) scale3d(0,0,1); transform: translate3d(0,-50%,0) scale3d(0,0,1); }
	100% { opacity: 1; -webkit-transform: translate3d(0,-50%,0) scale3d(1,1,1); transform: translate3d(0,-50%,0) scale3d(1,1,1); }
}

.ns-effect-slidetop.ns-show,
.ns-effect-slidetop.ns-hide {
	-webkit-animation-name: animSlideTop;
	animation-name: animSlideTop;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
}

@-webkit-keyframes animSlideTop {
	0% { -webkit-transform: translate3d(0,-100%,0); }
	100% { -webkit-transform: translate3d(0,0,0); }
}

@keyframes animSlideTop {
	0% { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
	100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

/* Expanding Loader */
.ns-effect-exploader {
	background: #fff;
	color: #7a7264;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}

.ns-effect-exploader .icon {
	position: absolute;
	left: 0.25em;
	top: 50%;
	-webkit-transform: translate3d(0,-50%,0);
	transform: translate3d(0,-50%,0);
}

.ns-effect-exploader .ns-box-inner {
	position: relative;
}

.ns-effect-exploader p {
	padding: 0.25em 2em 0.25em 3em;
}

.ns-effect-exploader .ns-close:hover::before,
.ns-effect-exploader .ns-close:hover::after {
	background: #98815a;
}

.ns-effect-exploader.ns-show {
	-webkit-animation-name: animLoad;
	animation-name: animLoad;
	-webkit-animation-duration: 2.5s;
	animation-duration: 2.5s;
}

@-webkit-keyframes animLoad {
	0% { opacity: 1; -webkit-transform: scale3d(0,0.3,1); }
	33% { opacity: 1; -webkit-transform: scale3d(0.5,0.3,1); }
	50% { opacity: 1; -webkit-transform: scale3d(0.6,0.3,1); }
	80%, 85% { opacity: 1; -webkit-transform: scale3d(1,0.3,1); -webkit-animation-timing-function: cubic-bezier(0.7,0,0.3,1); }
	100% { opacity: 1; -webkit-transform: scale3d(1,1,1); }
}

@keyframes animLoad {
	0% { opacity: 1; -webkit-transform: scale3d(0,0.3,1); transform: scale3d(0,0.3,1); }
	33% { opacity: 1; -webkit-transform: scale3d(0.5,0.3,1); transform: scale3d(0.5,0.3,1); }
	50% { opacity: 1; -webkit-transform: scale3d(0.6,0.3,1); transform: scale3d(0.6,0.3,1); }
	80%, 85% { opacity: 1; -webkit-transform: scale3d(1,0.3,1); transform: scale3d(1,0.3,1); -webkit-animation-timing-function: cubic-bezier(0.7,0,0.3,1); animation-timing-function: cubic-bezier(0.7,0,0.3,1); }
	100% { opacity: 1; -webkit-transform: scale3d(1,1,1); transform: scale3d(1,1,1); }
}

.ns-effect-exploader.ns-hide {
	-webkit-animation-name: animFade;
	animation-name: animFade;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
}

.ns-effect-exploader.ns-show .ns-box-inner,
.ns-effect-exploader.ns-show .ns-close {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-delay: 2.4s;
	animation-delay: 2.4s;
}

.ns-effect-exploader.ns-show .ns-close {
	-webkit-animation-name: animFade;
	animation-name: animFade;	
}

.ns-effect-exploader.ns-show .ns-box-inner {
	-webkit-animation-name: animFadeMove;
	animation-name: animFadeMove;
	-webkit-animation-timing-function: ease-out; 
	animation-timing-function: ease-out;
}

@-webkit-keyframes animFadeMove {
	0% { opacity: 0; -webkit-transform: translate3d(0,10px,0); }
	100% { opacity: 1; -webkit-transform: translate3d(0,0,0); }
}

@keyframes animFadeMove {
	0% { opacity: 0; -webkit-transform: translate3d(0,10px,0); transform: translate3d(0,10px,0); }
	100% { opacity: 1; -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

@-webkit-keyframes animFade {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes animFade {
	0% { opacity: 0; }
	100% { opacity: 1; }
}
