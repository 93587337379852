.__notifications__notification-item__385d6 {
    //border-top: 1px solid $table-border-color;
    //border-left: 1px solid $table-border-color;
    //border-right: 1px solid $table-border-color;
    a {

    }

    .item {
        align-items: center;
        padding: 1rem 1.5rem;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }

        &:hover {
            background-color: $gray-lightest;
            padding: 1rem 1.6rem;
        }

        h6 {
            font-weight: 400;
            line-height: 22px;
            margin: 0;
            &:hover {
                text-decoration: none;
            }
            i {
                @include media-breakpoint-only(xs) {
                    float: left;
                }
            }
        }
        p {
            margin: 0;
            color: $gray-lighter;
            cursor: default;
            text-indent: 24px;

            @include media-breakpoint-only(xs) {
                margin-top: 14px;
            }
        }

    }

}
