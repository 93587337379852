.__contractor__card__main-vacancy__f6b15 {
	position: relative;
    &.verify{
        .user-card-content{
            justify-content: space-between !important;
        }

    }

    .user-card-content{
        justify-content: start !important;
    }
}
