.__contractee__dashboard__order-list__dcff4 {
    .order{
        margin-bottom: 1rem;
        border-bottom: 1px solid $table-border-color;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
        &:after {
            @extend .clearfix;
        }
    }
}
