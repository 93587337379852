.__contractor__settings__shift-travel__display-main__61c29 {
  //opacity: 0;
  &.transition-panel {

    animation: showElement 1500ms 0s ease-in;
    @keyframes showElement {
      0% {
        opacity: 0;
        //transform: translateX(-100px);
      }

      100% {
        opacity: 1;
        //transform: translateX(0);
      }
    }
  }
}