.bs-menu-container {
	background-color: $app-brand-primary;
	color: $app-brand-primary-lighter;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	min-height: 100%;
	position: relative;
	z-index: 4;

	@media (max-width: $app-break-point) {
		flex-direction: row;
		min-height: 87px;
		width: 100%;
	}

	.nav-sidebar-header {
		background-color: $app-brand-primary-light;

		.nav-brand {
			padding: 10px 11px;

			a {
				cursor: pointer;
			}
		}
	}
	.nav-sidebar-content {
		-webkit-flex-grow: 1;
		flex-grow: 1;
		padding: 23px 0;

		@media (max-width: $app-break-point) {
			padding: 0;
		}

		.app-badge {
			position: absolute;
			right: -2px;
			top: -2px;
		}

		ul {
			list-style: none;
			padding: 0;
			text-align: center;

			@media (max-width: $app-break-point) {
				display: flex;
				justify-content: space-around;
				margin: 0;
				padding: 21px 0;
			}
		}

		.nav-sidebar-item {
			margin: 12px 0;
			//padding: 12px 0;

			@media (max-width: $app-break-point) {
				margin: 0;
				padding: 0;
			}

			.menu-button {
				@include transition(background 800ms);

				position: relative;
				z-index: 3;
				&.plus-link {
					.nav-sidebar-icon{
						z-index: 3;
						position: relative;
					}
					&:before{
						content: ' ';
						background-color: $app-brand-yellow;
						border-radius: 50%;
						height: 33px;
						width: 33px;
						transform: translate(-49%, -51%);
						position: absolute;
						top: 50%;
						left: 50%;
						z-index: 2;
					}
					&:after {
						@include transition($app-nav-item-hover-transition);
						box-shadow: 0 0 0 0px rgba($app-brand-yellow, .2);
						height: 33px;
						width: 33px;
						transform: translate(-50%, -50%);
						top: 50%;
						left: 50%;
						z-index: 2;
					}
					&:hover {
						&:after {
							@include transition($app-nav-item-hover-transition);
							-webkit-box-shadow: 0 0 0 6px rgba($app-brand-purple, .2);
							-moz-box-shadow: 0 0 0 6px rgba($app-brand-purple, .2);
							box-shadow: 0 0 0 6px rgba($app-brand-purple, .2);
						}

					}
					&.active {
						&:after {
							-webkit-box-shadow: 0 0 0 6px rgba($app-brand-purple, .2);
							-moz-box-shadow: 0 0 0 6px rgba($app-brand-purple, .2);
							box-shadow: 0 0 0 6px rgba($app-brand-purple, .2);
						}
					}

				}
				&:after {
					border-radius: 50%;
					content: ' ';
					height: 5px;
					left: 50%;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					width: 5px;
					z-index: -2;
				}
				&:hover {
					&:after {
						-webkit-box-shadow: 0 0 0 19px rgba(#8A3B58, .2);
						-moz-box-shadow: 0 0 0 19px rgba(#8A3B58, .2);
						box-shadow: 0 0 0 19px rgba(#8A3B58, .2);

						-moz-transition: box-shadow 200ms cubic-bezier(.2, 0, .7, 1), transform 100ms cubic-bezier(.2, 0, .7, 1);
						-o-transition: box-shadow 200ms cubic-bezier(.2, 0, .7, 1), transform 100ms cubic-bezier(.2, 0, .7, 1);
						-webkit-transition: box-shadow 200ms cubic-bezier(.2, 0, .7, 1), transform 100ms cubic-bezier(.2, 0, .7, 1);
						transition: box-shadow 200ms cubic-bezier(.2, 0, .7, 1), transform 100ms cubic-bezier(.2, 0, .7, 1);
					}
				}
				&.active {
					&:after {
						-webkit-box-shadow: 0 0 0 18px rgba(#8A3B58, .2);
						-moz-box-shadow: 0 0 0 18px rgba(#8A3B58, .2);
						box-shadow: 0 0 0 18px rgba(#8A3B58, .2);
					}
				}

				.nav-sidebar-icon {
					color: $app-brand-white;
					font-size: 22px;
				}

				small {
					font-size: 90%;
					font-weight: bold;
				}

			}

		}
	}
	.nav-sidebar-account {
		margin: 0 auto;

		.nav-account-content {
			padding: 24px 23px;

			@media (min-width: $app-break-point) {
				//background: $app-brand-primary-light;
				.dropdown-menu {
					bottom: 100%;
					left: -10px;
					margin-bottom: 0;
					top: auto;
				}
			}
			.nav-avatar-item{
				background-color: $app-brand-white;
				border-radius: 50%;
				width: 33px;
				height: 33px;
				display: block;

			}
			.nav-avatar {
				-moz-transition: box-shadow 200ms cubic-bezier(.2, 0, .7, 1), transform 100ms cubic-bezier(.2, 0, .7, 1);
				-o-transition: box-shadow 200ms cubic-bezier(.2, 0, .7, 1), transform 100ms cubic-bezier(.2, 0, .7, 1);
				-webkit-transition: box-shadow 200ms cubic-bezier(.2, 0, .7, 1), transform 100ms cubic-bezier(.2, 0, .7, 1);
				transition: box-shadow 200ms cubic-bezier(.2, 0, .7, 1), transform 100ms cubic-bezier(.2, 0, .7, 1);

				border-radius: 50%;
				margin: 0 auto;
				width: 33px;
				height: 33px;

				&:hover {
					-webkit-box-shadow: 0 0 0 6px rgba($app-brand-purple, .2);
					-moz-box-shadow: 0 0 0 6px rgba($app-brand-purple, .2);
					box-shadow: 0 0 0 6px rgba($app-brand-purple, .2);
				}
			}
			.dropdown-menu {
				margin: 14px 0;

				a {
					font-weight: 500;
					i {
						color: $app-brand-primary;
						font-size: 21px;
					}
				}
			}
		}
	}
}