.app-order-list{

    background-color: #ffffff;

    .applications-steps {
        border-top: solid 1px $table-border-color;
        border-bottom: solid 1px $table-border-color;
        background-color: #FFFCF7;
    }

    .user-icon {
        width:75px;
    }


    .btn-user-applicants {
        font-size: 14pt;
        color: #ffffff;
    }

    .description {
        font-size: 20pt;
        color: $black;
    }

    .description-text {
        font-size: 16px;
        color: $black;
    }

    .order-timesheet {
        border-bottom: solid 1px $table-border-color;
    }

    .order-timesheet-row {
        border-bottom: solid 1px $table-border-color;
        color: $black;
    }

    .startdato {
        width: 185px;
    }

    .description-div-border {
        border-bottom: solid 1px $table-border-color;
    }

    .order-address {
        border: 1px solid $table-border-color;
        height: 200px;
        color: $black;
        background-color: #FFFCF7;
    }

    .order-address span {
        display: block;
    }

    .btn-user-applicants .mdi-phone-in-talk, .btn-user-applicants .mdi-email {
        color: #ffffff;
    }

    .table td, .table th {
        border-top: 0;
        border-bottom: 1px solid $table-border-color;
        padding: 0.75rem;
        font-weight: normal;
    }



}

.description-div {
    width: 100%;
}




