.tbl-shift-item {
  tr {
    td {
      font-size: 1.2rem;
    }

    &.is-editing {
      background: #fff6e6;
    }

    .form-control {
      &:disabled,
      &[readonly] {
        background-color: #fff;
        color: #000;
        opacity: 1;
      }

      &:disabled {
        cursor: auto;
      }
    }
  }
}


tr{
  position: relative;
  &.is-absence {
    //position: relative;
    td{
      position: relative;
    }
    td:before {
      content: " ";
      position: absolute;
      top: 50%;
      left: 0;
      border-bottom: 1px solid #111;
      width: 100%;
    }
  }
}

