.__app__card-item__81b98{
    position: relative;
	h5{
		//padding: 20px;
		//font-weight: 400;
		//border-bottom: 1px solid $table-border-color;
		//width: 100%;
		//margin-bottom: 0;
		//@include media-breakpoint-down(md) {
		//	margin-top: 2rem;
		//}
	}
	.content{
		position: relative;
		padding: 30px;
	}
}
