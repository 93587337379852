.app-status {
    -webkit-box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.05);
    .row {
        .col-12 {
            .item {
                padding: 20px;
                @include media-breakpoint-up(xs) { border-bottom: 1px solid $table-border-color; }
                @include media-breakpoint-up(md) { border-bottom: none; border-right: 1px solid $table-border-color; }
                //@include media-breakpoint-up(lg) { ... }
                //@include media-breakpoint-up(xl) { ... }
            }
            &:last-child {
                .item {
                    border: none;
                }
            }
        }
    }


}
