.__bs__accordion-card-header__43a3b{
	a{
		position: relative;
		width: 100%;
		display: flex;
		&:after{
			content: ' ';
			position: absolute;
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid #000000;
			background: transparent;
			right: 0;
			top: 7px;
		}
	}

}
