.__bs__main-container__62cb6 {
	position: relative;
	min-height: 100%;
	min-height: calc(100vh - 80px);
	width: 100%;
	margin-top: 80px;
	padding-left: 80px;
	//width: calc(100vw - 20px);
	&.is-xs {
		padding-left: 0;
	}
	&.is-xl {
		padding-left: $sidenav-width;
		-moz-transition: all .3s ease-in;
		-o-transition: all .3s ease-in;
		-webkit-transition: all .3s ease-in;
		transition: all .3s ease-in;
	}
	&.isWizard{
		padding-left: 0;
	}
}
