.bs-layout {
	@include transition($app-overlay-transition);
	display: flex;
	flex-direction: column;
	min-height: 100%;
	min-height: 100vh;
	position: relative;

	@media (min-width: $app-break-point) {
		@include transition($app-overlay-transition);
		padding: $app-layout-padding-left-tablet;

	}

	@media (min-width: 1200px) {
		&.open{
			@include transition($app-overlay-transition);
			padding: $app-layout-padding-left-jumbo;
		}
	}

	&.right {
		@media (min-width: $app-break-point) {
			@include transition($app-overlay-transition);
			padding: $app-layout-padding-right-tablet;
		}

		@media (max-width: 1200px) {
			@include transition($app-overlay-transition);
			&.open{
				padding: $app-layout-padding-right-jumbo;
			}
		}
	}
	&.hidden{
		padding: $app-layout-hidden !important;
	}
	@media (max-width: $app-break-point) {
		padding: 20px;
		margin-top: 155px;
	}
}