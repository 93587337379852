.emails-container{
    @extend .bg-white;
    .emails-items{
        .email-body{
            width: 100%;
            height: 100%;
        }
        .emails-items-item{
            padding: 20px;
            @include hover-focus {
                cursor: pointer;
            }
        }
    }
}
