.__admin__assignment__display-shifts__5ac5c{
    width: 100%;
    display: table;
    th,td{
        padding: 10px 5px;
        font-weight: normal;
    }

    tr,td{
        position: relative;
    }
    .table-timer{
        vertical-align: middle;
    }

    .plus {
        color: #14CA9C !important;
    }

    .minus {
        color: #ED553B !important;
    }

    .minus, .plus {
        line-height: 18px;
        font-size: 30px;
    }

    .time {
        line-height: 18px;
        font-size: 16px;
        margin: 0 5px;
    }

    .plus:hover, .minus:hover {
        cursor: pointer;
    }
}
