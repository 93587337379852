.__bs__bs-badge__5e7a6 {
	&.badge{
		font-weight: 400;
		font-size: 1rem;
		margin: 5px;
	}

	&.badge-default{
		background: #f1f3f3;

		&.status-1{
			@extend .badge-primary;
			@extend .text-white;
		}
	}

    &.badge-with-action{
        vertical-align: middle !important;
        line-height: 1.5;

        .close{
            font-size: 1.5rem;
            float: none;
        }
    }
}

