
$blue-badge:    #36B5E4 !default;
$blue-light-badge:    #D9F0F9 !default;

$purple-badge:  #9272B2 !default;
$purple-light-badge:  #EAE4F0 !default;

$orange-badge:  #F69D38 !default;
$orange-light-badge:  #FCECD8 !default;

$green-badge:   #1CB68D !default;
$green-light-badge:  #D3F1E9 !default;


$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #f7931e !default;
$yellow:  #f2b134 !default;
$green:   #15BF95 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: (
    blue: $blue,
    indigo: $indigo,
    purple: $purple,
    pink: $pink,
    red: $red,
    orange: $orange,
    yellow: $yellow,
    green: $green,
    teal: $teal,
    cyan: $cyan
) !default;


.badge-outline {
    display: inline-block;

    width: auto;
    height: auto;

    padding: $badge-padding-y $badge-padding-x;
    margin-bottom: 5px;
    font-weight: $font-weight-base;
    line-height: 22px;
    text-align: center;

    vertical-align: baseline;
    border: 1px solid transparent;
    font-size: $font-size-base;
    @include border-radius($badge-border-radius);

    @include media-breakpoint-down(md) {
        word-break: break-all;
        -ms-word-break: break-all;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        white-space: normal;
    }

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

// Quick fix for badges in buttons
.btn .badge-outline {
    position: relative;
    top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-outline-pill {
    padding-right: $badge-pill-padding-x;
    padding-left: $badge-pill-padding-x;
    @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $colors {
    .badge-outline-#{$color} {
        @include badge-outline($value);
    }
}

.badge-inline {
    display: inline-block;
    padding: $badge-padding-y $badge-padding-x;
    font-weight: normal;
    font-size: $font-size-base;
    line-height: 22px;
    text-align: center;
    vertical-align: baseline;
    border: 1px solid transparent;
    color: #343333;
    margin-bottom: 10px;
    width: auto;
    height: auto;
    @include border-radius($badge-border-radius);

    @include media-breakpoint-down(md) {
        word-break: break-all;
        -ms-word-break: break-all;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        white-space: normal;
    }

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

// Quick fix for badges in buttons
.btn .badge-inline {
    position: relative;
    top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-inline-pill {
    padding-right: $badge-pill-padding-x;
    padding-left: $badge-pill-padding-x;
    @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

.badge-inline-blue {
    @include badge-inline($blue-badge, $blue-light-badge);
}

.badge-inline-purple {
    @include badge-inline($purple-badge, $purple-light-badge);
}

.badge-inline-orange {
    @include badge-inline($orange-badge, $orange-light-badge);
}

.badge-inline-green {
    @include badge-inline($green-badge, $green-light-badge);
}


