.card-user-link {
	@include hover-focus {
		text-decoration: none;
		> * {
			text-decoration: none;
		}
	}
	&.card {
		cursor: pointer;
		&:hover {
			.right-icon-div {
				background: #14CA9C;
				color: #fff;
				transition: background-color 1000ms ease-out;
			}
			.mdi-chevron-right {
				color: #ffffff;
			}
			.card-title {
				color: #14CA9C;
			}
			.right-icon-div {
				width: 60px;
			}
		}
	}
	.right-icon-div {
		height: 100%;
		width: 60px;
		background-color: #ffffff;

	}

	.card-title {
		font-size: 20px;
		color: #000;
		font-weight: 400;
	}

	.card-text {
		color: #989696;
	}

	.card:hover .right-icon-div {
		margin-right: 1px;
	}

}

.card-header {
	padding: 20px;
	border-bottom: 1px solid $table-border-color;
	a {
		position: relative;
		width: 100%;
		display: flex;
		color: $gray-dark;
		h5 {
			font-weight: 400;
		}
		@include hover-focus {
			text-decoration: none;
		}
	}
	background-color: #ffffff;
}

.contractor-item-card {

	.card-header{
		border-bottom: none;
	}
	.custom-card-title {
		border-bottom: none;
		display: inline;
	}
}

$transition-panel-open: all .4s ease-in-out !default;
$transition-panel-close: all .1s ease-in-out !default;

.showPanel {
	height: 0;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
	@include transition($transition-panel-close);
	&.active {
		height: auto;
		opacity: 1;
		visibility: visible;
		overflow: visible;
		@include transition($transition-panel-open);
	}
}
.closePanel{
	position: absolute;
	right: -24px;
	top: -24px;
	opacity: 0;
	visibility: hidden;
	&.active {
		opacity: 1;
		visibility: visible;
		@include transition($transition-panel-open);
	}
}

.app-order-card{
	border-bottom: solid 1px $table-border-color;
}
