.login-panel {
	min-height: 100vh;
	display: flex;
	height: 100%;
    padding: 20px;
	.login-form {
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
		@include media-breakpoint-up(md) {
			width: 320px;
		}
	}
}

.social_button{
    //@extend .my-4;
    //@extend .my-4;
    //@extend .align-self-stretch;
    //@extend .align-center;
    //@extend .align-items-center;

    color: $white;
    border-radius: 3px;
    font-weight: 400;
    line-height: 21px;
}

.facebook_button{
    border-color: #3b5998;
    background-color: #3b5998;
    i{
        &:before{
            color: $white;
        }
    }
    @include hover-focus {
        background-color: lighten(#3b5998, 10%);
        border-color: transparent;
        outline: none;
        box-shadow: none;
        i{
            &:before{
                color: $white;
            }
        }
    }
}

.linked_button{

    border-color: #0079AF;
    background-color: #0079AF;
    i{
        &:before{
            color: $white;
        }
    }
    @include hover-focus {
        background-color: lighten(#0079AF, 10%);
        border-color: transparent;
        box-shadow: none;
        outline: none;
        i{
            &:before{
                color: $white;
            }
        }
    }
}

.google-plus_button{
    color: $gray-dark;
    border-color: #EDEDED;
    background-color: #EDEDED;
    @include hover-focus {
        background-color: darken(#EDEDED, 10%);
        border-color: transparent;
        outline: none;
        box-shadow: none;
    }
}

.loggin-sub-headers {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    @extend .my-4;
    font-weight: 300;
    //&:before,&:after{
    //    content: '';
    //    border-top: 2px solid;
    //    margin: 0 20px 0 0;
    //    flex: 1 0 20px;
    //}
    //&:after{
    //    margin: 0 0 0 20px;
    //}
}

.auth-form{
    *, > input {
        &::placeholder {
            color: #999999;
            // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
            opacity: 1;
        }
    }
}
