.__contractor__settings__video-player__143d8{
	.btn-play-custom{
		border-radius: 50%;
		.btn-play-icon{
			&:before{
				font-size: 32px;
				line-height: 32px;
			}
		}
	}

	.play-btn {
		position: absolute;
		left: 20px;
		bottom: 20px;
		width: 40px;
		height: 40px;
		color: #FFFFFF;
		cursor: pointer;
		//-webkit-box-shadow: 0 0 6px 6px rgba(0,0,0,0.1);
		//-moz-box-shadow: 0 0 6px 6px rgba(0,0,0,0.1);
		//box-shadow: 0 0 6px 6px rgba(0,0,0,0.1);
		background: url('/assets/images/icons/play.svg');
	}


	@media only screen and (max-width: 500px) {

		.play-btn::after{
			content: 'Play video';
			position: relative;
			bottom: -8px;
			left: 5px;
			width: 130px;
			height: 20px;
			text-align: center;
			display: inline-block;
			font-size: 16px;
			font-family: "Fira Sans";
			font-style: normal;
			//-webkit-text-shadow: 0 0 6px 6px rgba(0,0,0,0.1);
			//-moz-text-shadow: 1px 1px #333;
			text-shadow: 1px 1px rgba(0,0,0,1);
			z-index: 10;
		}
	}

	.play-btn:hover::after{
		content: 'Play video';
		position: relative;
		bottom: -8px;
		left: 5px;
		width: 130px;
		height: 20px;
		text-align: center;
		display: inline-block;
		font-size: 16px;
		font-family: "Fira Sans";
		font-style: normal;
		//-webkit-text-shadow: 0 0 6px 6px rgba(0,0,0,0.1);
		//-moz-text-shadow: 1px 1px #333;
		text-shadow: 1px 1px rgba(0,0,0,0.1);
		z-index: 10;
	}
}
