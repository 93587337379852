.breadcrumb {
	margin-bottom: 12px;
	@include media-breakpoint-down(sm) {
		display: none;
	}
	@include media-breakpoint-down(xs) {
		display: none;
	}
}

.breadcrumb-item {
	@include media-breakpoint-down(sm) {
		display: none;
	}
	@include media-breakpoint-down(xs) {
		display: none;
	}
	@include media-breakpoint-up(md) {
		//font-size: 1.375rem;
		font-size: 20px;
	}
	a {
		color: $breadcrumb-inactive-color;
		&:hover {
			text-decoration: none;
			color: #AAA8A8;

		}
		font-size: 20px;
	}

	&:last-child {
		color: $breadcrumb-active-color;
		@include media-breakpoint-down(sm) {
			display: block;
			&:before {
				content: '';
				padding-left: 0
			}
		}

	}
}