@charset "UTF-8";

@font-face {
  font-family: "ornio-icons";
  src: url("fonts/ornio/ornio-icons.eot");
  src: url("fonts/ornio/ornio-icons.eot?#iefix") format("embedded-opentype"),
  url("fonts/ornio/ornio-icons.woff") format("woff"),
  url("fonts/ornio/ornio-icons.ttf") format("truetype"),
  url("fonts/ornio/ornio-icons.svg#ornio-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "ornio-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ornio-icons-"]:before,
[class*=" ornio-icons-"]:before {
  font-family: "ornio-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ornio-icons-tracker:before {
  content: "\e000";
}

.ornio-icons-done:before {
  content: "\e001";
}

.ornio-icons-picture:before {
  content: "\e002";
}

.ornio-icons-drager:before {
  content: "\e003";
}

.ornio-icons-snapshot:before {
  content: "\e004";
}

.ornio-icons-play:before {
  content: "\e005";
}

.ornio-icons-fullscreen:before {
  content: "\e006";
}

.ornio-icons-pause:before {
  content: "\e007";
}

.ornio-icons-bookmark:before {
  content: "\e008";
}

.ornio-icons-edit:before {
  content: "\e009";
}

.ornio-icons-fullscreen-exit-icon:before {
  content: "\e00a";
}

.ornio-icons-volume-high-icon:before {
  content: "\e00b";
}

.ornio-icons-volume-low-icon:before {
  content: "\e00c";
}

.ornio-icons-volume-muted-icon:before {
  content: "\e00d";
}

.ornio-icons-volume-mid-icon:before {
  content: "\e00e";
}

.ornio-icons-basket:before {
  content: "\e00f";
}

.ornio-icons-pdf-icon:before {
  content: "\e010";
}
