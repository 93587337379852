.menu {
	background-color: $app-brand-white;
	bottom: 0;
	position: fixed;
	top: 0;
	width: $app-sidenav-width-large;
	-webkit-box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.05);
	&.small {
		width: $app-sidenav-width;
	}
	z-index: 2;
	@include transition($app-subnav-meun-transition-left);
	left: -$app-sidenav-width-large;
	right: auto;
	&.small {
		left: -$app-sidenav-width;
		right: auto;
	}

	&.right {
		@include transition($app-subnav-meun-transition-right);
		right: -$app-sidenav-width-large;
		left: auto;
		&.small {
			right: -$app-sidenav-width;
			left: auto;
		}
	}

	&.active {

		@include transition($app-subnav-meun-transition-left);
		left: $app-main-menu-width;
		right: auto;
		@media (max-width: $app-break-point) {
			left: 0;
			right: auto;
		}

		&.right {
			@include transition($app-subnav-meun-transition-right);
			right: $app-main-menu-width;
			left: auto;
			@media (max-width: $app-break-point) {
				right: 0;
				left: auto;
			}
		}
	}

	@media (max-width: $app-break-point) {

		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;

		top: 87px;
		width: 100%;

		left: -100%;
		right: auto;

		&.right {
			right: -100%;
			left: auto;
		}

	}
}
