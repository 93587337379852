.home-vacancies {
    margin-bottom: 30px;

    .nav-tabs {
        border-bottom: none;
        position: relative;
        z-index: 1;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            background-color: #fff;
            .nav-item {

                .nav-link {
                    border-bottom: 1px solid #e2e2e2;
                }
            }
        }

        .nav-link {
            border: none;
            padding: 1em 1em;
            font-size: $font-size-h5;
        }
        .nav-link.active,
        .nav-item.show .nav-link {
            color: $black;
            background-color: #fff;
        }
    }

    .vacancy-item {
        border-bottom: 1px solid #EBECEE;
        transition: all 0.2s ease-in-out;

        .vacancy-header {
            img {
                width: 50px;
                height: 50px;
                display: block;
            }
            .vacancy-title {
                font-size: 18px;
                line-height: 24px;
                transition: all 200ms ease-in-out;
            }
        }
        &:hover .vacancy-title {
            transition: all 200ms ease-in-out;
            color: $green;
        }
    }
}






