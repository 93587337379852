.assignment_action_modal {
	//margin: 0;

	//.modal-content {
	//	width: 652px;
	//}

	.contractor-item {
		background-color: #F6F8FA;
	}

	.modal-header, .user-reference {
		border-bottom: 1px solid $table-border-color;
	}
}

.user-selection-modal {

	&:after {
		content: ' ';
		background: rgba(0, 0, 0, 0.32);
		z-index: 1;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.modal-dialog {
		z-index: 2;
	}
	//.modal-content {
	//	width: 652px;
	//}
}

.timesheet-modal{
	// Scale up the modal
	&:after {
		content: ' ';
		background: rgba(0, 0, 0, 0.32);
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.modal-dialog {
		z-index: 2;
		max-width: 1024px;
	}
}
