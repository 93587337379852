.__admin__vacancy__plan__list-item__adcc6{
    .plan-remove-features{
        h6{
            padding-bottom: 20px;
            border-bottom: 2px solid #e2e2e2;

        }
        .plan-remove-features-item{
            border-bottom: 1px solid #e2e2e2;
        }
    }
    .plan-edit_feature {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid #e2e2e2;
        padding: 15px 0;
        &:last-child{
            border-bottom: 0px solid transparent;
        }
        &:hover {
            i {
                @extend .text-warning;
            }
        }
    }

    border-bottom: 3px solid #e2e2e2;
    margin-bottom: 1.5rem;

    &:last-child{
        border-bottom: 0 solid transparent;
        margin-bottom: 0;
    }
}
