.__assignment__select-worker-item__c18a6 {
	$transition-panel-open: all .1s ease-in-out !default;
	$transition-panel-close: all .4s ease-in-out !default;

	background: #fff;
	@include transition($transition-panel-open);
	&.active {
		border: 1px solid #A3ECDD;
		@include transition($transition-panel-close);
	}
}

