.avatar {
	&.small {
		@include media-breakpoint-up(xs) {
			@include size(36px);
		}
		@include media-breakpoint-up(sm) {
			@include size(36px);
		}
		@include media-breakpoint-up(md) {
			@include size(36px);
		}
		@include media-breakpoint-up(lg) {
			@include size(48px);
		}
		@include media-breakpoint-up(xl) {
			@include size(52px);
		}
	}
	&.medium {
		@include size(102px);
	}
	&.large {
		@include size(360px);
	}
	@extend .img-fluid;
}

.circle {
	border-radius: 100%;
	color: $gray;
	border: 1px solid $gray;
	width: 37px;
	height: 37px;
	float: left;
	position: relative;
	&.active {
		background-color: #14CA9C;
		color: #ffffff;
	}
}

.img-circle {
	border: 2px solid orange;
	padding: 5px;
}

.not-img-circle {
	background-color: $gray;
	color: #ffffff;
}

//.custom-description-skills {
//	word-break: break-all;
//
//	@include media-breakpoint-up(md) {
//		max-width: 260px;
//	}
//
//	@include media-breakpoint-up(lg) {
//		max-width: 260px;
//	}
//
//	@include media-breakpoint-up(xl) {
//		max-width: 270px;
//	}
//
//}

.user-setting-panel {
	@include media-breakpoint-up(lg) {
		padding-left: 30px;
		padding-right: 30px;;
	}
}

.categories-list {
	.category-item {
		border-bottom: 1px solid $table-border-color;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
	}
}

.user-setting-container {

	@include media-breakpoint-down(lg) {
		.card-block{
			padding: 0;
		}
	}
	@include media-breakpoint-up(lg) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.panel-title {
		border: none;
	}
}

.reset-panel {
	.panel-title {
		padding: 20px 0;
	}
}

.frame {
	border:1px solid $brand-primary;
}