.subnav-content-item {
	background-color: $app-subnav-item-bg;
	border: 1px solid $app-border-color;
	border-radius: 0;

	display: block;
	margin-top: -1px;
	padding: 24px 30px;
	width: 100%;
	&.active{
		background-color: $app-subnav-item-unread-bg;
		a{
			text-decoration: none;
			color: $app-subnav-item-text-hover;
		}
	}
	a{
		color: $app-subnav-item-text;
		opacity: 0.7;
	}
	&:hover {
		@include transition(all .5s cubic-bezier(0, 0, .4, .9));
		background-color: $app-subnav-item-unread-bg;
		cursor: pointer;
		a{
			text-decoration: none;
			color: $app-subnav-item-text-hover;
		}
	}
	&.unread {
		@include transition(all .5s cubic-bezier(0, 0, .4, .9));
		background-color: $app-subnav-item-unread-bg;
		.item-header {
			.timestamp::after {

				color: $app-subnav-unread-button;
				content: ' ⦿';
			}
		}
		&:hover {
			@include transition(all .5s cubic-bezier(0, 0, .4, .9));
			background-color: $app-subnav-item-bg-hover;
			color: $app-subnav-item-text-hover;
			text-decoration: none;
		}
	}

	.timestamp {
		color: $app-brand-gray-light;
	}

	p {
		opacity: 0.7;
	}
}