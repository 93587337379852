.app-orderlist {

    .custom-size{
        font-size: 14px;
    }

}

.app-ol{
    list-style-type: none;

    li {
        counter-increment: count-me;

        .title{
            font-weight: 500!important;
            font-size: 1.1rem;
        }

        p{
            margin-top: 15px;
        }
    }

    li::before {
        content: counter(count-me) ". ";
        display: block;
        position: relative;
        max-width: 0;
        max-height: 0;
        left: -2.3em;
        top: .05em;
        color: #14CA9C;
        font-weight: 600;
    }
}
