.__custom__user-statistic__2197a {
    .divider {
        height: 1px;
        margin-top: 27px;
        margin-bottom: 23px;
        width:30px;
    }

    .description {
        margin-top: 8px;
        font-size: 20px;
        color: #333;
    }
}

