.__order-list__contractor-order-item__11b6d {
	margin-bottom: 1rem;
	border-bottom: 1px solid $table-border-color;

	&:last-child {
		border-bottom: none;
		margin-bottom: 0;
	}
	&:after {

		@extend .clearfix;
	}
}