.md-datepicker-group {
	height: 39px !important;
	border: 1px solid $app-icon-color;
}
.md-datepicker-group input {
	@extend .form-control;
	border: none;
	height: 36px;
	width: 100% !important;
}

.md-datepicker-group {
	margin: 0;
}

//.input-group-addon {
//	height: 40px;
//}
