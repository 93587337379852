.__bs__city-by-zipcode__a9148{
    .ember-power-select-trigger {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        width: 100%;
    }

    .text-form-city { margin-top: 7px;margin-bottom: 0}


}
