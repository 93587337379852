@mixin badge-outline($color) {
    border-color: $color;
    background-color: transparent;

    &[href] {
        @include hover-focus {
            color: color-yiq($color);
            text-decoration: none;
            background-color: darken($color, 10%);
        }
    }
}


@mixin badge-inline($color, $border) {
    color: $color !important;
    border-color: $border !important;
}
