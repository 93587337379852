.__admin__assignments__new__order-shift-turn-items__d4cb2{
  width: 100%;
  display: table;
  th,td{
    padding: 10px 5px;
    font-weight: normal;
  }
  .first-letter-camelcase{
    text-transform:capitalize;
  }

  tr,td{
    position: relative;
  }
  .table-timer{
    vertical-align: middle;
  }

  .plus {
    color: #14CA9C !important;
  }

  .minus {
    color: #ED553B !important;
  }

  .minus, .plus {
    line-height: 18px;
    font-size: 30px;
  }

  .time {
    line-height: 18px;
    font-size: 16px;
    margin: 0 5px;
    width: 37px;
    display: inline-block;
  }

  .plus:hover, .minus:hover {
    cursor: pointer;
  }
}
