// Only necessary for window height sized blocks.
html {
    //font-family: sans-serif; /* 1 */
    -ms-text-size-adjust: 100%; /* 2 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    //@include media-breakpoint-down(md) {
    //  font-size: $font-size-sm;
    //  line-height: $font-size-sm + 0.625rem;
    //}
    //@include media-breakpoint-down(sm) {
    //  font-size: $font-size-xs;
    //  line-height: $font-size-xs + 0.625rem;
    //}
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    //font-family: sans-serif; /* 1 */
    //-ms-text-size-adjust: 100%; /* 2 */
    //-webkit-text-size-adjust: 100%; /* 2 */
    //-webkit-font-smoothing: subpixel-antialiased;
    //-moz-osx-font-smoothing: grayscale;
}

main {
    flex: 1 0 auto;
}

b, strong {
    font-weight: 500;
}

.mdi {
    -ms-font-feature-settings: "liga" 1;
    -webkit-font-smoothing: antialiased;
    color: $app-icon-color;
}



//MDI icon 22px
.#{$mdi-css-prefix}-set.#{$mdi-css-prefix + '-22px'},
.#{$mdi-css-prefix}.#{$mdi-css-prefix + '-22px'}:before {
    font-size: 22px;
    line-height: 24px;
}

//MDI icon 20px
.#{$mdi-css-prefix}-set.#{$mdi-css-prefix + '-20px'},
.#{$mdi-css-prefix}.#{$mdi-css-prefix + '-20px'}:before {
    font-size: 20px;
    line-height: 20px;
}

@include media-breakpoint-down(xs) {
    .mdi-sm-12px,
    .mdi-sm-12px:before {
        font-size: 12px !important;
        line-height: 14px !important;
    }
}

a {
    @include transition($transition-base);
    @include hover-focus {
        @include transition($transition-base);
    }
}

*:focus {
    outline: none;
}

.modal-header, .modal-footer {
    border: 0;
}

.w-auto {
    width: auto;
}

.w-100 {
    width: 100%;
    display: block;
}

.w-50 {
    width: 50%;
    display: block;
}

ol {
    -webkit-padding-start: 20px;
}

.container-fluid {
    transform: none;
    overflow: visible;
    display: flex;
    min-height: 100%;
    min-height: calc(100vh - 100px);
    flex-direction: column;

}

.img-dolar {
    width: 14px;
}

$custom-file-text: (
    placeholder: (
        en: "Choose file...",
        es: "Seleccionar archivo..."
    ),
    button-label: (
        en: "Browse",
        es: "Navegar"
    )
);

.content-active {
    @include hover-focus {
        cursor: url(images/icons/close.png), url(images/icons/close.cur), auto;
    }
}

.font-size-12 {
    font-size: 12px !important;
}

.panel-title {
    padding: 20px 30px;
    font-weight: 400;
    border-bottom: 1px solid #EBECEE;
    width: 100%;
    margin-bottom: 0;
}

.panel-footer {
    padding: 20px 30px;
    font-weight: 400;
    border-top: 1px solid #EBECEE;
    width: 100%;
    margin-bottom: 0;
}

.section-label {
    font-weight: normal;
}

.form-control {
    font-family: "Fira Sans";
}

.custom-control-description {
    color: $black;
}

.btn-rounded {
    border: 1px solid #eaeaea;
    padding: 2px 14px;
    border-radius: 25px;
    @include transition($transition-base);

    a, button {
        color: $black;
        &:hover {
            color: $black;
        }
    }

    i {
        font-size: 20px;
        margin-right: 6px;
    }
}

.btn-hired {
    border: 1px solid #eaeaea;
    padding: 6px 14px;
    border-radius: 25px;
    @include transition($transition-base);

    a {
        color: $black;
    }

    i {
        font-size: 20px;
        margin-right: 6px;
    }
}

.details {
    .summary {
        color: $black;
        i {
            color: $green;
        }
    }
}

button, input, optgroup, select, textarea {
    font-family: $font-family-sans-serif;
}
