.timesheet-header{

    background-color: #FFFCF7;
    border-bottom: 1px solid $table-border-color;

    .start-date-input {
        flex-direction: row;
    }


    .icon-border {
		border: 1px solid $table-border-color;
	    padding: 10px;
    }

    .icon-border:hover {
        cursor: pointer;
    }

    .icon-border:hover  .mdi-cloud-download, .icon-border:hover  .mdi-printer {
        color: #14CA9C;

    }


    .md-datepicker-group {
        margin: 0;
    }


    select.form-control:not([size]):not([multiple]) {
        height:inherit;
    }

    .input-group-addon, .input-group-btn, .input-group .form-control{
        flex-direction: row;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
    }

    .input-icon{
        background-color: #ffffff;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .select-tag {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-position: right 50%;

    }


}

.timesheet-body {
    background-color: #ffffff;
    min-height: 600px;
}

.timesheet_action_modal .modal-content {
    width: 1024px;
}

.timesheet_action_modal .modal-dialog {
    padding-top:30px;
    max-width: 1024px;
    margin: 0 auto;
    overflow-x: scroll;
    /* width: 100%; */
    /* height: 100px; */
}

.modal-dialog::-webkit-scrollbar {
    display: none;
}

