$image-path: 'images/logo' !default;
$fallback-extension: 'png' !default;
$retina2-suffix: '@2x';
$retina3-suffix: '@3x';

@mixin background-image($name, $size:false) {
	background-image: url(#{$image-path}/#{$name}.svg);
	@if ($size) {
		background-size: $size;
	}

	.no-svg & {
		/* @2x Images (Pixel Ratio of 1.25+) */
		@media only screen and (-o-min-device-pixel-ratio: 5/4),
		only screen and (-webkit-min-device-pixel-ratio: 1.25),
		only screen and (min--moz-device-pixel-ratio: 1.25),
		only screen and (min-device-pixel-ratio: 1.25),
		only screen and (min-resolution: 1.25dppx) {
			background-image: url(#{$image-path}/#{$name}#{$retina2-suffix}.#{$fallback-extension});
			background-size: $size;
		}
		@media only screen and (-o-min-device-pixel-ratio: 9/4),
		only screen and (-webkit-min-device-pixel-ratio: 2.25),
		only screen and (min--moz-device-pixel-ratio: 2.25),
		only screen and (min-device-pixel-ratio: 2.25),
		only screen and (min-resolution: 2.25dppx) {
			background-image: url(#{$image-path}/#{$name}#{$retina3-suffix}.#{$fallback-extension});
			background-size: $size;
		}
		background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

	}

	background-repeat: no-repeat;
}

@function mdi($name) {
	@return char(nth($hexes, index($names, $name)));
}