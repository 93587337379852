.bs-container {
	display: flex;
	height: 100%;
	position: fixed;
	z-index: 3;
	left: 0;
	right: auto;
	&.right {
		left: auto;
		right: 0;
	}

	@media (max-width: $app-break-point) {
		flex-direction: column;
		position: fixed;
		width: 100%;
		left: auto;
		right: auto;
		min-height: auto;
		height: auto;
	}
	.bs-main-nav {
		height: 100%;
		left: 0;
		top: 0;
		width: $app-sidenav-width;
	}
	.bs-container-overlay {
		@include transition($app-overlay-transition);
		background-color: $app-overlay-bg;
		height: 100%;
		left: 0;
		opacity: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		@media (max-width: $app-break-point) {
			display: none;
		}

	}
}