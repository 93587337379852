.payments{
    .payments-item{
        margin-bottom: 10px;
        border-bottom: 1px solid $table-border-color;
        padding: 20px 0;
    }
}

.payment-new {

}
