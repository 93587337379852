.__admin__order__edit__order-shift__430ef{
    width: 100%;
    display: table;
    //background-color: $white;
    th,td{
        padding: 10px 5px;
        font-weight: normal;
        border: none;
    }

    tr, td{
        position: relative;
    }
    .table-timer{
        vertical-align: middle;
    }

    .plus {
        color: #14CA9C !important;
    }

    .minus {
        color: #ED553B !important;
    }

    .minus, .plus {
        line-height: 18px;
        font-size: 30px;
    }

    .time {
        line-height: 18px;
        font-size: 16px;
        margin: 0 5px;
    }
    .border{
        border-bottom: 1px solid $table-border-color;
    }
    .plus:hover, .minus:hover {
        cursor: pointer;
    }
}
