.contractee-list .card-title {
  font-weight: normal;
  border-bottom: 1px solid #EBECEE;
}


.contractee-list a, .contractee-list a:hover {
  color: #000;
  font-weight: normal;
}


.more {
  //border-bottom: 1px solid #EBECEE;
  //border-top: 1px solid #EBECEE;
}

.bgwhite {
  background-color: #FFFFFF;
}