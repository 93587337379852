//.logo-container {
//	background-color: $white;
//	height: 60px;
//	display: flex;
//	.logo {
//		display: block;
//		width: 27px;
//		height: 27px;
//		@include background-image(logo, 100%);
//		-moz-transition: width .3s ease-in;
//		-o-transition: width .3s ease-in;
//		-webkit-transition: width .3s ease-in;
//		transition: width .3s ease-in;
//		&.name{
//			width: 120px;
//			height: 18px;
//			@include background-image(logo-name, 100%);
//		}
//	}
//
//}

.logo-container{
    img{
        margin: 0 auto;
        display: block;
    }
}
.main-header{
	padding: 19px 33px !important;
}
.logotype{
	img{
		width: 150px;
		height: 30px;
		display: block;
	}
}
