.verify-title {
    color: #989696;
}

.verify_container {
    width: 600px !important;
}

.verify-list {

    .list {
        margin-bottom: 1rem;
        margin-top: 1rem;
        text-align: left;
        .count {
            float: left;
            text-align: center;
            font-size: 1.2rem;
            background-color: $brand-secondary;
            color: $white;
            border-radius: 50%;
            @include size(35px);
            line-height: 35px;
            margin: 0 10px auto  1rem;
            transform: scale(1);
        }
        .description{
            height: 100%;
            line-height: 30px;
        }

    }
}
