.__contractor__profile__header-content__dad4a {
    .state {
        max-width: 500px;
        width: 500px;
        @include media-breakpoint-up(xs) {
            width: 300px;
        }
        @include media-breakpoint-up(md) {
            width: 400px;
        }
        @include media-breakpoint-up(lg) {
            width: 500px;
        }
        .item {
            @include media-breakpoint-up(md) {
                border-right: 1px solid $table-border-color;
                &:last-child {
                    border-right: none;
                }
            }
        }
    }


    .custom-hover-btn {
        i {
            color: $app-icon-color;
        }
        @include hover-focus {
            i {
                @extend .text-warning;
            }
        }
    }
}
