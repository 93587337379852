.table-responsive-timesheet-admin {
	width:100%;
	.responsive{
		min-width: 1108px;
		.contractor{
			width: 100px;
		}
	}
}

.table-responsive-ordershift-admin {
	width:100%;
	.responsive{
		min-width: 800px;

	}
}

.table-responsive-timesheet-modal {
	//width:100%;
	//.responsive{
	//	min-width: 790px;
	//}
}

.table-responsive-timesheet {
	width:100%;
	.responsive{
		min-width: 1053px;
	}

	tbody{
		tr{
			&:hover{
				cursor: pointer;
			}
		}
	}
}

.table {
	thead th {
		border-bottom: none;
		border-top: none;
		font-weight: normal;
	}
	tbody th, tbody td{
		vertical-align: middle;
	}

}

.table-items{
    thead th {
        padding: 20px 0 20px 0;
        background: #f4f5f6;
        font-weight: bold;

        &:last-child{
            padding-right: 20px;
        }
    }

    tbody th, tbody td{
        &:last-child{
            padding-right: 20px;
        }
    }
}

.controller {
	height: 100%;
	@include media-breakpoint-down(sm) {
		margin-top: 1rem;
	}
}

.table-vacancy{
	thead th{
		padding: 20px;
	}
}

.attachments table tr td{
	cursor: pointer;
}
.attachments table tr td,
.attachments table tr th
{
	padding-left: 0;
	padding-right: 0;
}

.attachments table tr td {
	border-top: 0;
	border-bottom: 1px solid $table-border-color;
}

.attachments table tr {
	&:last-child{
		td{
			border-bottom: 0;
		}
	}
}