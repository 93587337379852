.dropdown-toggle {
	&.dropdown-no-arrow {
		&:after {
			display: none;
			width: 0;
			height: 0;
			margin-left: 0;
			vertical-align: middle;
			content: "";
			border: none;
		}
	}
}