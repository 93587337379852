.__contractor__request__user-avatar__63c46 {

  .model-id {
    background: $brand-primary;
    color: #fff;
    width: 80px;

    @include media-breakpoint-up(lg) {
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
      margin-left: -20px
    }

    @include media-breakpoint-down(md) {
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      margin-right: -20px
    }
  }

  .model-id a {
    color: #fff;
  }

  @include media-breakpoint-up(md) {
    .model-id {
      text-align: left;
      //margin-left: -20px;
      padding-left: 5px;
    }
  }

  @include media-breakpoint-down(md) {
    .model-id {
      text-align: right;
      //margin-right: -20px;
      padding-right: 5px;
    }
  }

}