.__contractor__card__card-header__4db1e{
    .verified{
        color: $brand-primary;
        &:hover{
            cursor: pointer;
        }
    }

    .profession{
        margin-top: 10px;
        display: inline-block;
    }
}
