.notifications-card {

    .notification-item {
        border-bottom: 1px solid $table-border-color;
        display: block;

        &:last-child {
            border-bottom: none;
        }

    }
}