.navigation {

	@include app-box-shadow(0, 0, 50px, 5px, $app-navigation-shadow);
	@include transition(all .3s ease-in-out);
	background-color: $app-brand-white;

	min-height: 100%;
	position: fixed;
	top: 0;
	width: $app-sidenav-width;
	z-index: 2;
	a[aria-expanded=true] .mdi-chevron-down {
		display: none;
	}

	a[aria-expanded=false] .mdi-chevron-up {
		display: none;
	}

	left: -$app-sidenav-width;
	right: auto;

	&.right {
		right: -$app-sidenav-width;
		left: auto;
	}
	@media (max-width: $app-break-point) {
		flex-direction: column;
		height: auto;
		left: auto;
		position: relative;
		right: auto;
		width: 100%;
		min-height: auto;
	}

	&.active {
		@include transition(all .3s ease-in-out);
		left: $app-main-menu-width;
		right: auto;
		&.right {
			left: auto;
			right: $app-main-menu-width;
		}

		@media (max-width: $app-break-point) {
			flex-direction: row;
			overflow: hidden;
			left: auto;
			right: auto;
			width: 100%;
			top: auto;
			min-height: auto;
		}
	}

	//&.open {
	//	@include transition(all .3s ease-in-out);
	//	left: $app-main-menu-width;
	//	right: auto;
	//	&.right {
	//		right: $app-main-menu-width;
	//		left: auto;
	//	}
	//
	//	@media (max-width: $app-break-point) {
	//		flex-direction: column;
	//		height: auto;
	//		left: auto;
	//		position: relative;
	//		right: auto;
	//		width: 100%;
	//	}
	//}

	.navigation-header {
		border-bottom: 1px solid $app-navigation-header-border-color;
		padding: 17px 30px;
		position: relative;
		.logotype {
			float: left;
		}
		.app-mobile-button {
			display: none;

			@media (max-width: $app-break-point) {
				display: block;
			}
		}
	}

	.navigation-content {
		.app-nav-collapse {
			@media (min-width: $app-break-point) {
				display: block;
				&.show {
					opacity: 1;
				}
			}
			ul {
				list-style: none;
				padding: 30px;

				.navigation-main-link {
					padding: 10px 0;
					width: 100%;

					.navigation-link-text {
						font-weight: 500;
						margin: 0;
					}

					.app-nav-item {
						color: $app-nav-collapse-text;
						cursor: pointer;
						text-decoration: none;
						width: 100%;
						font-weight: 500;
					}

					span {
						.mdi {
							color: $app-nav-collapse-dropdown-color-inactive;
							font-size: 18px;
						}
						.mdi-chevron-up {
							color: $app-nav-collapse-dropdown-color;
						}
					}

					.navigation-icon {
						@include transition(all 200ms ease-in-out);
						color: $app-nav-collapse-icon;
						font-size: 22px;
						padding-right: 10px;
					}

					&:hover, .active {
						.navigation-icon {
							color: $app-brand-yellow;
						}
					}

				}
				.card {
					border: 0;
					border-radius: 0;
				}
				.dropdown-item {
					padding: 10px 2rem !important;
					margin-bottom: 5px;
					font-weight: normal;
					&:focus,
					&:hover,
					.active {
						background-color: $app-nav-collapse-text-hover;
						border-radius: 3px;
						color: $app-brand-black;
						cursor: pointer;
						text-decoration: none;
					}

				}
				//.active {
				//	background-color: $app-navigation-drop-down-item;
				//	color: $app-brand-gray-dark;
				//	text-decoration: none;
				//}
			}
		}

	}
}