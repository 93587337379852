.app-form-filters{
	&.bg-yellow{
		background-color: #FFFCF7;
	}
	.input-query{
		background-color: #F6F8FA;
		border: none;

		&::placeholder {
			color: #999999;
			// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
			opacity: 1;
		}
	}

	.divider{
		width: 100%;
		height: 1px;
		background: #EBECEE;
	}

	.input-icon{
		height: 38px;
		.mdi{
			color: #989696;
		}

	}

	.btn{
		&.btn-secondary{
			.mdi{
				margin-right: 0.5rem;
				color: #343333;
			}
		}
	}
}