.contractor-switch-toggle {
	text-align: right;

	@include media-breakpoint-down(lg) {
		text-align: center;
	}

	.switch {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 30px;
	}
	label{
		margin-bottom: 0;
		vertical-align: middle;
	}
	span{
		line-height: 20px;
	}
	.switch input {
		display: none;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $red;
		-webkit-transition: .4s;
		transition: .4s;
		height: 26px;
	}

	.slider:before {
		position: absolute;
		content: "";
		height: 18px;
		width: 18px;
		left: 8px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}

	input:checked + .slider {
		background-color: #14CA9C;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #14CA9C;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}

}
