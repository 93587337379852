.__contractor__upload__user-video__ff178 {
    .video-settings {
        border: 2px dashed #eaeaea;
        width: 100%;
        height: 320px;
    }

    .btn-delete{
        position: absolute;
        z-index: 10;
        right: 10px;
        top: 10px;
        border-radius: 50%;
        line-height: 1;
        padding: 10px 11px;
    }
}

