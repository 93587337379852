.__admin__assignment__all-requests__a6899 {
	.badge{
		font-weight: 400;
		font-size: 1rem;
		margin: 5px;
	}

	.badge-default{
		background: #f1f3f3;

		&.status-1{
			@extend .badge-primary;
			@extend .text-white;
		}

		&.status-7{
			@extend .badge-warning;
			@extend .text-white;
		}
	}
}

